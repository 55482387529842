import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../shared/common.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-frontent-home-master',
  templateUrl: './frontent-home-master.component.html',
  styleUrls: ['./frontent-home-master.component.css']
})
export class FrontentHomeMasterComponent implements OnInit {

  constructor(public service: CommonService, private _router: Router) { }
  UserId = 0; role = ""; profileshowhide = false;
  name="";
  ngOnInit() {
    debugger;
    
    let checkCookies =   localStorage.getItem('cookiesOk');
    if(checkCookies === "1")
    {
      this.cookieShowHide=false;
      this.service.pageDisable = true;      
    }
    else{
      this.cookieShowHide=true;
      this.service.pageDisable = false;
    }
    this.UserId = +localStorage.getItem('userId');
    this.role = localStorage.getItem('role');
    if (this.role === "User") {
      this.profileshowhide = true;
      this.GetUserDetails(this.UserId);
    }
    else{
      this.profileshowhide=false;
    }

  }
  GetUserDetails(id)
  {
    this.service.GetuserById(id).subscribe(
      (res: any) => {
       
       this.name=res.firstName;
      },
      err => {

        if (err.status == 400)
          alert('error');
        else
          console.log(err);
      }
    );
  }
  rediresctContent(type) {
    if (type === "Privacy") {
      this._router.navigateByUrl('/privacy');
    }
    else if(type === "Terms"){
      this._router.navigateByUrl('/term-condtion');
    }
    else if(type === "aboutus")
    {
      this._router.navigateByUrl('/aboutus');
    }
    else if(type === "impressum")
    {
      this._router.navigateByUrl('/impressum');
    }
  }
  FnLogout()
  {
   localStorage.removeItem("userId") ;
   localStorage.removeItem("role") ;
   this.profileshowhide = false;
   this._router.navigateByUrl('/home');
   window.location.reload();
  }
  fnProfile()
  {
    this._router.navigateByUrl('/userprofile');
  }
  chnagePassword() {
    this._router.navigateByUrl('/user/change-password');
  }
  UserRequest() {
    localStorage.removeItem("url");
    this._router.navigateByUrl('/user/request');
  }
  cookieShowHide =true;
  cookiesfn()
  {
    this.cookieShowHide = false;
    this.service.pageDisable =  true;
    localStorage.setItem('cookiesOk',"1");
  }
  fnPrivacyPolicy()
  {
    this._router.navigateByUrl('/privacy');
  }
}
