import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MessageService } from '../../admin/inner/message/message.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-product-request-details',
  templateUrl: './product-request-details.component.html',
  styleUrls: ['./product-request-details.component.css']
})
export class ProductRequestDetailsComponent implements OnInit {
  id = 0; productName = ""; Description = ""; companyName = ""; Status = "";
  AddForm: FormGroup; diplayLogo = false; Name = ""; Email = ""; MobileNo = ""; Address = "";
  CountryName = ""; ProductRequestEmail =""; RequestDescription = "";
  constructor(private service: MessageService,
    private formBuilder: FormBuilder,
    private _router: Router,
    private _activatroute: ActivatedRoute) { }

  ngOnInit() {
    this.id = +this._activatroute.snapshot.params['id'];
    this.GetProductList();
    this.AddForm = this.formBuilder.group({

      status: ['', Validators.required],

    });
  }
  GetProductList() {
    this.service.GetProductById(this.id).subscribe(
      (res: any) => {
        debugger;
        this.productName = res.productName;
        this.Description = res.description;
        this.companyName = res.companyName;
        this.Status = res.status;
        this.Name = res.name;
        this.Email = res.email;
        this.MobileNo = res.mobileNo;
        this.Address = res.address;
        this.CountryName=res.countryName;
        this.ProductRequestEmail=res.productRequestEmail;
        this.RequestDescription=res.requestDescription;
        this.AddForm.patchValue(res);
      },
      err => {

        if (err.status == 400)
          //this.toastr.error('Incorrect username or password.', 'Authentication failed.');
          alert('error');
        else
          console.log(err);

        //this.reset();
      }
    );
  }
  onSubmit()
  {
    
  }
  goback() {
    /// window.location.href="/author/list";
    this._router.navigateByUrl('/user/request');
  }
}
