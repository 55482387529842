import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { UserService } from '../../admin/auth/auth/auth.service';

@Component({
  selector: 'app-userlogin',
  templateUrl: './userlogin.component.html',
  styleUrls: ['./userlogin.component.css']
})
export class UserloginComponent implements OnInit {

  loginForm: FormGroup;
  returnUrl: string;
  fb: FormBuilder;
  invalidlogin = false;
  message: string;
  constructor(public service: UserService, private formBuilder: FormBuilder, private _router: Router, private _activatroute: ActivatedRoute) { }

  ngOnInit() {
    debugger;
    this.invalidlogin = false;
    this.service.menuType = 'otherMenu';
    let id = this._activatroute.snapshot.paramMap.get('id');
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required]

    });
    //this._router.navigate(['/auth']);
    console.log('login hit');
  }

  login(): void {


    this._router.navigate(['/dashboard']);
  }


  fogotpassword(): void {
    this._router.navigate(['user/forgotpassword']);

  }

  MsgShow = false;
  onSubmit() {
    debugger;
    if (this.loginForm.value.email === "" || this.loginForm.value.email === undefined || this.loginForm.value.email === null) {
      alert("Please enter email id");
      return false;
    }
    if (this.loginForm.value.password === "" || this.loginForm.value.password === undefined || this.loginForm.value.password === null) {
      alert("Please enter password");
      return false;
    }
    var body = {

      Email: this.loginForm.value.email,
      Password: this.loginForm.value.password
    };

    this.service.userlogin(body).subscribe(
      (res: any) => {

        debugger;
        if (res.status == true) {
          if(res.role  === "User")
          {
          this.MsgShow = false;
          this.service.validUser = true;
          let url = localStorage.getItem("url");
          
          localStorage.setItem('token', res.token);
          localStorage.setItem('userId', res.userId);
          localStorage.setItem('Email', res.email);
          localStorage.setItem('username', res.name);
          localStorage.setItem("role", res.role);
          if(url === null){
          this._router.navigateByUrl('/home');
          }
          else{
            this._router.navigateByUrl(url);
          }
        }
        else{
          this.MsgShow = true;
          this.invalidlogin = true;
          this.service.validUser = false;
          this.message = "You are not a valid customer."
        }
        }
        else {
          this.MsgShow = true;
          this.invalidlogin = true;
          this.message = res.message;
          this.service.validUser = false;
        }
      },
      err => {
        if (err.status == 400)

          //this.toastr.error('Incorrect username or password.', 'Authentication failed.');
          alert('error');
        else
          console.log(err);
      }
    );


  }



}










