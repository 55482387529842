import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../shared/common.service';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.css']
})
export class PrivacyComponent implements OnInit {
  privacy="";
  constructor(private service: CommonService,private formBuilder: FormBuilder,private _router : Router) { }
  ngOnInit() {
    this.service.GetContent().subscribe(
      (res: any) => {

        this.privacy = res.privacy;

      },
      err => {

        if (err.status == 400)
          //this.toastr.error('Incorrect username or password.', 'Authentication failed.');
          alert('error');
        else
          console.log(err);

        //this.reset();
      }
    );
  }
}
