
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

// import { MustMatch } from '../../admin/inner/change-password/change-password.component';
import { UserService } from '../../admin/auth/auth/auth.service';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { CommonService } from '../../shared/common.service';
import * as abcJS from '../../../assets/HomePageCSS/js/custom_script.js';
import { NgxSpinnerService } from 'ngx-spinner';
declare var currentSlide: any;
@Component({
  selector: 'app-userregister',
  templateUrl: './userregister.component.html',
  styleUrls: ['./userregister.component.css']
})

export class UserregisterComponent implements OnInit {
  AddForm: FormGroup;
  returnUrl: string; termscondtion = "";
  fb: FormBuilder;
  msgcolor: string;
  message: string;
  id = 0;
  datePickerConfig: Partial<BsDatepickerConfig>;
  displayButton1 = false;
  display1 = false;
  display2 = false;
  countinueButton = false;
  thankyou = false;
  contactdetails = true;
  constructor(private service: CommonService, private formBuilder: FormBuilder, private _router: Router, private spinner: NgxSpinnerService) {

    this.datePickerConfig = Object.assign({}, {
      containerClass: 'theme-red',
      minDate: new Date(1900, 0, 1),
      maxDate: new Date(2030, 11, 31),
      dateInputFormat: 'DD-MM-YYYY'



    });
  }

  ngOnInit() {
    debugger;
    this.service.GetContent().subscribe(
      (res: any) => {

        this.termscondtion = res.termsandCondition;

      },
      err => {

        if (err.status == 400)
          //this.toastr.error('Incorrect username or password.', 'Authentication failed.');
          alert('error');
        else
          console.log(err);

        //this.reset();
      }
    );
    //this.CreateButton=true;
    this.countinueButton = false;
    this.display1 = true;
    this.display2 = true;
    this.displayButton1 = false;
    this.service.menuType = false;
    localStorage.setItem('header', 'register');
    this.CountryList();
    this.AddForm = this.formBuilder.group({
      firstName: ['', Validators.required],

      lastName: ['', Validators.required],
      gender: ['', Validators.required],
      dob: ['', Validators.required],
      professionalCurrentStatus: ['', Validators.required],
      professionalJobTitle: ['', Validators.required],
      professionalInChargeStaff: ['', Validators.required],
      graduateInstitutionName: ['', Validators.required],
      graduateCourseOfStudy: ['', Validators.required],
      graduateCountryId: ['', Validators.required],
      contactCountryId: ['', Validators.required],
      contactStateId: ['', Validators.required],
      contactCityId: ['', Validators.required],
      contactStreet: ['', Validators.required],
      contactPostalCode: ['', Validators.required],
      contactMobileNo: ['', Validators.required],

      contactcountryCode: ['', Validators.required],
      companyName: ['', Validators.required],
      companyTypeOfIndustry: ['', Validators.required],
      companyCountryId: ['', Validators.required],
      companyStateId: ['', Validators.required],
      companyCityId: ['', Validators.required],
      companyStreet: ['', Validators.required],
      companyPostalcode: ['', Validators.required],
      companyMobileNumber: ['', Validators.required],
      companyCountryCode: ['', Validators.required],
      companyEmail: ['', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]],
      companyRepeatedEmail: ['', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]],

      staffResponsibility: ['', Validators.required],
      password: ['', Validators.required],
      contactEmail: ['', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]],
      contactRepeateEmail: ['', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]],

    },

      {
        validator: [MustMatch('contactEmail', 'contactRepeateEmail'), MustMatch('companyEmail', 'companyRepeatedEmail')]


      });


  }
  countryList = [];
  stateList = [];
  cityList = [];
  CountryList() {
    this.service.GetAllCountry().subscribe(
      (res: any) => {
        debugger
        this.countryList = res[0].cList;
        // this.stateList = res[0].sList;
        // this.cityList = res[0].cityList;


      },
      err => {

        if (err.status == 400)
          //this.toastr.error('Incorrect username or password.', 'Authentication failed.');
          alert('error');
        else
          console.log(err);

        //this.reset();
      }
    );
  }
  filterCountry(e) {
    
    this.jobStateList(e);
  }
  jobStateList(id) {
    this.service.GetStateById(id).subscribe(
      (res: any) => {
        debugger;
        this.stateList = res.stateList;
      },
      err => {
        if (err.status == 400)

          //this.toastr.error('Incorrect username or password.', 'Authentication failed.');
          alert('error');
        else
          console.log(err);
      }
    );

  }
  jobCityList(id) {
    
    this.service.GetCityById(id).subscribe(
      (res: any) => {
        debugger;
        this.cityList = res.cityList;
      },
      err => {
        if (err.status == 400)

          //this.toastr.error('Incorrect username or password.', 'Authentication failed.');
          alert('error');
        else
          console.log(err);
      }
    );

  }
  filterContactState = []
  filterContactCity = [];
  ContactState = "";
  contactState(Id) {
    this.filterContactState = [];
    var stateList = this.stateList.filter(x => x.countryId == Id);
    this.filterContactState = stateList;
  }
  contactCity(Id) {
    var cityList = this.cityList.filter(x => x.stateId == Id);
    this.filterContactCity = cityList;
  }
  filterState = [];
  cityFilter = [];
  changeState(Id) {
    debugger;
    var stateList = this.stateList.filter(x => x.countryId == Id);
    this.filterState = stateList;

  }
  changeCity(Id) {
    debugger;
    var cityList = this.cityList.filter(x => x.stateId == Id);
    this.cityFilter = cityList;

  }
  PasswordUrl: string;
  onSubmit() {
    debugger;
   this.spinner.show();
  if(this.AddForm.value.contactCountryId == "0" )
  {
    this.AddForm.value.contactCountryId = 0;
    this.AddForm.value.contactStateId = 0;
    this.AddForm.value.contactCityId = 0;
  }
  if(this.AddForm.value.contactStateId == "0")
  {
    this.AddForm.value.contactStateId = 0;
    this.AddForm.value.contactCountryId = 0;
    this.AddForm.value.contactCityId = 0;
  }
  if(this.AddForm.value.contactCityId == "0")
  {
    this.AddForm.value.contactCityId = 0;
    this.AddForm.value.contactStateId = 0;
    this.AddForm.value.contactCountryId = 0;
  }
    if (this.AddForm.value.contactEmail === "" || this.AddForm.value.contactEmail === undefined) {
      this.spinner.hide();
      alert("Please enter contact email");
      return false;
    }
    if (this.AddForm.value.contactRepeateEmail === "" || this.AddForm.value.contactRepeateEmail === undefined) {
      this.spinner.hide();
      alert("Please enter contact Repeat email");
      return false;
    }
    if (!this.TermsCondtions) {
      this.spinner.hide();
      alert("Please check terms and conditions");
      return false;
    }
    if (this.contactEmailMessage) {
      this.spinner.hide();
      alert("Please enter another email Id");
      return false;
    }

    var body = {

      Id: this.id,
      Gender: this.AddForm.value.gender,
      FirstName: this.AddForm.value.firstName,
      LastName: this.AddForm.value.lastName,
      DOB: new Date(this.AddForm.value.dob).toDateString(),
      ProfessionalCurrentStatus: this.AddForm.value.professionalCurrentStatus,
      ProfessionalJobTitle: this.AddForm.value.professionalJobTitle,

      ProfessionalInChargeStaff: this.AddForm.value.staffResponsibility,
      GraduateInstitutionName: this.AddForm.value.graduateInstitutionName,
      GraduateCourseOfStudy: this.AddForm.value.graduateCourseOfStudy,
      GraduateCountryId: this.AddForm.value.graduateCountryId,
      ContactCountryId: this.AddForm.value.contactCountryId,
      ContactStateId: this.AddForm.value.contactStateId,
      ContactCityId: this.AddForm.value.contactCityId,
      ContactStreet: this.AddForm.value.contactStreet,
      ContactPostalCode: this.AddForm.value.contactPostalCode,
      ContactMobileNo: this.AddForm.value.contactcountryCode + '' + this.AddForm.value.contactMobileNo,
      CompanyName: this.AddForm.value.companyName,
      CompanyTypeOfIndustry: this.AddForm.value.companyTypeOfIndustry,
      CompanyCountryId: this.AddForm.value.companyCountryId,
      CompanyStateId: this.AddForm.value.companyStateId,
      CompanyCityId: this.AddForm.value.companyCityId,
      CompanyStreet: this.AddForm.value.companyStreet,
      CompanyPostalcode: this.AddForm.value.companyPostalcode,
      CompanyMobileNumber: this.AddForm.value.companyCountryCode + '' + this.AddForm.value.companyMobileNumber,
      CompanyEmail: this.AddForm.value.companyEmail,
      CompanyRepeatedEmail: this.AddForm.value.companyRepeatedEmail,
      ContactEmail: this.AddForm.value.contactEmail,
      ContactRepeateEmail: this.AddForm.value.contactRepeateEmail,

    };


    if (this.id == 0) {
      this.service.Add(body).subscribe(
        (res: any) => {
          debugger;
          let userId = res.id;
          this.thankyou = true;
          this.contactdetails = false;
          var userIddetails = {
            Id: res.id,
          };
          this.service.SenddMailOfUserPassword(userIddetails).subscribe(
            (res1: any) => {
              this.spinner.hide();
              this._router.navigate(['/thank-you-register']);


            },
            err => {

            }
          );

        },
        err => {
          debugger
          if (err.status == 400)

            //this.toastr.error('Incorrect username or password.', 'Authentication failed.');
            alert('error');
          else
            console.log(err);
        }
      );
    }
    else {

      this.service.Edit(body).subscribe(
        (res: any) => {



          // localStorage.setItem('token', res.token);
          //window.location.href="/author/list";
          this._router.navigateByUrl('/admin/user/list');
          alert("User is updated successfully");

        },
        err => {
          debugger
          if (err.status == 400)

            //this.toastr.error('Incorrect username or password.', 'Authentication failed.');
            alert('error');
          else
            console.log(err);
        }
      );
    }
  }
  dobDate: string;
  firstButton() {
    debugger;
    if (this.AddForm.value.gender === "" || this.AddForm.value.firstName === "" || this.AddForm.value.lastName === "") {
      this.displayButton1 = false;
    }
    else {
      this.displayButton1 = true;
    }
  }

  fnBack1() {
    this.display1 = true;
    this.display2 = false;
  }
  TermsCondtions = false;
  fnprivate(check) {
    if (check) {
      this.TermsCondtions = true;
    }
    else {
      this.TermsCondtions = false;
    }
  }
  showtermsandCondtion: boolean;
  openModalTerms() {
    this.showtermsandCondtion = true;
  }

  closeModalTerms() {
    this.showtermsandCondtion = false;

  }
  contactEmailMessage = false;
  CompnayState = []; CompanyCity = [];
  checkEmail(email) {
    debugger;
    this.service.CheckContactEmail(email).subscribe(
      (res: any) => {

        debugger;
        if (res.contactEmail === null) {
          this.contactEmailMessage = false;
        }
        else {
          this.contactEmailMessage = true;

        }

      },
      err => {

        if (err.status == 400)
          //this.toastr.error('Incorrect username or password.', 'Authentication failed.');
          alert('error');
        else
          console.log(err);

        //this.reset();
      }
    );
  }
  filterCountryCompany(e) {
    
    this.jobStateListCompany(e);
  }
  jobStateListCompany(id) {
    this.service.GetStateById(id).subscribe(
      (res: any) => {
        debugger;
        this.CompnayState = res.stateList;
      },
      err => {
        if (err.status == 400)

          //this.toastr.error('Incorrect username or password.', 'Authentication failed.');
          alert('error');
        else
          console.log(err);
      }
    );

  }
  jobCityListCompany(id) {
    
    this.service.GetCityById(id).subscribe(
      (res: any) => {
        debugger;
        this.CompanyCity = res.cityList;
      },
      err => {
        if (err.status == 400)

          //this.toastr.error('Incorrect username or password.', 'Authentication failed.');
          alert('error');
        else
          console.log(err);
      }
    );

  }
}



export function MustMatch(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    if (matchingControl.errors && !matchingControl.errors.mustMatch) {
      // return if another validator has already found an error on the matchingControl
      return;
    }

    // set error on matchingControl if validation fails
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ mustMatch: true });
    } else {
      matchingControl.setErrors(null);
    }
  }
}

