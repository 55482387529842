import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http"
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
//import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
//import { AuthGuard } from './admin/auth/auth/auth.guard'
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

import { AppComponent } from './app.component';

// Import containers
//import { DefaultLayoutComponent } from './containers/default-layout/default-layout.component';

import { LandingComponent } from './admin/inner/landing/landing.component';
import { UserService } from './admin/auth/auth/auth.service';


import { P404Component } from './error/404.component';
import { P500Component } from './error/500.component';
import { DeleteconfirmationComponent } from './shared/deleteconfirmation/deleteconfirmation.component';

// import { LoginComponent } from './views/login/login.component';
// import { RegisterComponent } from './views/register/register.component';

// const APP_CONTAINERS = [
//   DefaultLayoutComponent
// ];

import {
  AppAsideModule,
  AppBreadcrumbModule,
  AppHeaderModule,
  AppFooterModule,
  AppSidebarModule

} from '@coreui/angular';

//import datatable modules
import { DataTablesModule } from 'angular-datatables'


// Import routing module
import { AppRoutingModule } from './app.routing';
import { DashboardModule } from './admin/inner/dashboard/dashboard.module';
//import { BlogModule} from './admin/inner/blog/blog.module';
// Import 3rd party components
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ChartsModule } from 'ng2-charts';
import { LoginComponent } from './admin/auth/login/login.component';
import { ForgotpasswordComponent } from './admin/auth/forgotpassword/forgotpassword.component';
import { AuthComponent } from './admin/auth/auth/auth.component';
import { ChangePasswordComponent } from './admin/inner/change-password/change-password.component';

import { UserregisterComponent } from './frontend/userregister/userregister.component';
import { UserloginComponent } from './frontend/userlogin/userlogin.component';
import { UserforgotpasswordComponent } from './frontend/userforgotpassword/userforgotpassword.component';
import { UserprofileComponent } from './frontend/userprofile/userprofile.component';

import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { from } from 'rxjs';

import { AuthGuard } from './auth.guard';



import { MatFormFieldModule } from '@angular/material/form-field';

import {
  MatAutocompleteModule,
  MatInputModule
} from '@angular/material';

import { HomeComponent } from './frontend/home/home.component';
import { FrontendMasterComponent } from './frontend/frontend-master/frontend-master.component';
import { AccountselectionregistrationComponent } from './frontend/accountselectionregistration/accountselectionregistration.component';
import { FrontentHomeMasterComponent } from './frontend/frontent-home-master/frontent-home-master.component';
import { HomeListMasterComponent } from './frontend/home-list-master/home-list-master.component';
import { ProductListingComponent } from './frontend/product-listing/product-listing.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxSpinnerModule } from "ngx-spinner";
import { InfoCenterHomeMasterComponent } from './frontend/info-center-home-master/info-center-home-master.component';
import { HomeInfoCenterListComponent } from './frontend/home-info-center-list/home-info-center-list.component';
import { JournalListComponent } from './frontend/journal-list/journal-list.component';
import { VendorRegistrationComponent } from './frontend/vendor-registration/vendor-registration.component';
import { ThankyouComponent } from './frontend/thankyou/thankyou.component';
import { AccountselectionregComponent } from './frontend/accountselectionregnew/accountselectionreg.component';
import { PaymentPageComponent } from './frontend/payment-page/payment-page.component';
import { AccountSelectionVendorRgistrationComponent } from './frontend/account-selection-vendor-rgistration/account-selection-vendor-rgistration.component';
import { ThankyouUserregisterComponent } from './frontend/thankyou-userregister/thankyou-userregister.component';
import { SearchAllComponent } from './frontend/search-all/search-all.component';
import { SearchAllMasterComponent } from './frontend/search-all-master/search-all-master.component';
import { JobListComponent } from './frontend/job-list/job-list.component';
import { JobListMasterComponent } from './frontend/job-list-master/job-list-master.component';
import { JobDescriptionComponent } from './frontend/job-description/job-description.component';
import { ProductlistingdetailsComponent } from './frontend/productlistingdetails/productlistingdetails.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { JournalDescriptionComponent } from './frontend/journal-description/journal-description.component';
import { VideoDescriptionComponent } from './frontend/video-description/video-description.component';
import { VideoCategoryComponent } from './frontend/video-category/video-category.component';
import { DocumentViewerComponent } from './frontend/document-viewer/document-viewer.component';
import { InfoCnterMasterDescriptionComponent } from './frontend/info-cnter-master-description/info-cnter-master-description.component';
import { TermsCondtionComponent } from './frontend/terms-condtion/terms-condtion.component';
import { PrivacyComponent } from './frontend/privacy/privacy.component';
import { SubscriptionThankyouComponent } from './frontend/subscription-thankyou/subscription-thankyou.component';
import { DeleterequestComponent } from './shared/deleterequest/deleterequest.component';
import { UserChangePasswordComponent } from './frontend/user-change-password/user-change-password.component';
import { UserRequestComponent } from './frontend/user-request/user-request.component';
import { OdcumentRequestDetailsComponent } from './frontend/odcument-request-details/odcument-request-details.component';
import { VideoRequestDetailsComponent } from './frontend/video-request-details/video-request-details.component';
import { ProductRequestDetailsComponent } from './frontend/product-request-details/product-request-details.component';
import { JobRequestDetailsComponent } from './job-request-details/job-request-details.component';
import { AboutUsComponent } from './frontend/about-us/about-us.component';
import { ImpressumComponent } from './frontend/impressum/impressum.component';
@NgModule({
  imports: [
    BrowserModule,

    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    AppAsideModule,
    AppBreadcrumbModule.forRoot(),
    AppFooterModule,
    AppHeaderModule,
    AppSidebarModule,
    PerfectScrollbarModule,
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    ChartsModule,
    DashboardModule,
    DataTablesModule,
    ReactiveFormsModule,
    FormsModule,
    MatButtonModule,
    MatDialogModule,

    BsDatepickerModule.forRoot(),

    MatAutocompleteModule, MatInputModule,
    InfiniteScrollModule,
    NgxSpinnerModule,
    CarouselModule
  ],
  exports: [MatAutocompleteModule, MatInputModule],
  declarations: [
    AppComponent,

    P404Component,
    P500Component,
    LandingComponent,
    LoginComponent,
    ForgotpasswordComponent,
    AuthComponent,
    DeleteconfirmationComponent,
    ChangePasswordComponent,

    UserregisterComponent,
    UserloginComponent,
    UserforgotpasswordComponent,
    UserprofileComponent,
    HomeComponent,
    FrontendMasterComponent,
    AccountselectionregistrationComponent,
    FrontentHomeMasterComponent,
    HomeListMasterComponent,
    ProductListingComponent,
    InfoCenterHomeMasterComponent,
    HomeInfoCenterListComponent,
    JournalListComponent,
    VendorRegistrationComponent,
    ThankyouComponent,
    AccountselectionregComponent,
    PaymentPageComponent,
    AccountSelectionVendorRgistrationComponent,
    ThankyouUserregisterComponent,
    SearchAllComponent,
    SearchAllMasterComponent,
    JobListComponent,
    JobListMasterComponent,
    JobDescriptionComponent,
    ProductlistingdetailsComponent,
    JournalDescriptionComponent,
    VideoDescriptionComponent,
    VideoCategoryComponent,
    DocumentViewerComponent,
    InfoCnterMasterDescriptionComponent,
    TermsCondtionComponent,
    PrivacyComponent,
    SubscriptionThankyouComponent,
    DeleterequestComponent,
    UserChangePasswordComponent,
    UserRequestComponent,
    OdcumentRequestDetailsComponent,
    VideoRequestDetailsComponent,
    ProductRequestDetailsComponent,
    JobRequestDetailsComponent,
    AboutUsComponent,
    ImpressumComponent
  ],
  providers: [
    AuthGuard

  ],
  bootstrap: [AppComponent],
  entryComponents: [DeleteconfirmationComponent, DeleterequestComponent]
})
export class AppModule { }
