import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from '../../shared/common.service';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-info-cnter-master-description',
  templateUrl: './info-cnter-master-description.component.html',
  styleUrls: ['./info-cnter-master-description.component.css']
})
export class InfoCnterMasterDescriptionComponent implements OnInit {
  productName: string; ActiveClass = 'All'; UserId = 0; role = ""; profileshowhide = false;
  name = "";
  constructor(private service: CommonService, private _router: Router, private spinner: NgxSpinnerService) { }

  ngOnInit() {
    let checkCookies = localStorage.getItem('cookiesOk');
    if (checkCookies === "1") {
      this.cookieShowHide = false;
      this.service.pageDisable = true;
    }
    else {
      this.cookieShowHide = true;
      this.service.pageDisable = false;
    }
    this.UserId = +localStorage.getItem('userId');
    this.role = localStorage.getItem('role');
    if (this.role === "User") {
      this.profileshowhide = true;
      this.GetUserDetails(this.UserId);
    }
    else {
      this.profileshowhide = false;
    }
    this.jobCategoryList();
    this.ActiveClass = localStorage.getItem('ActiveClass');
    if (this.ActiveClass === 'null' || this.ActiveClass === '' || this.ActiveClass === undefined) {
      this.ActiveClass = 'All';
    }

    this.productName = localStorage.getItem('productName');
    if (this.productName === undefined || this.productName === "undefined" || this.productName === "null") {
      this.productName = "";
      localStorage.setItem("productName", '');
    }
  }
  GetProductList() {
    this.service.productList = [];
    localStorage.removeItem("url");
    localStorage.setItem('ProductSearchType', '2');
    localStorage.removeItem('CategoryType');
    localStorage.setItem('productName', this.productName);
    this._router.navigate(['/productlist']);

  }
  GetInfoCenterList() {
    this.service.infoCenterList = [];
    localStorage.removeItem("url");
    localStorage.removeItem('CategoryType');
    localStorage.setItem('ActiveClass', 'All');

    localStorage.setItem('SearchType', 'All');
    localStorage.setItem('InfoCentersearchText', this.productName);
    localStorage.setItem('productName', this.productName);
    this._router.navigate(['/infocenter']);

  }
  getJournalList() {
    this.service.infoCenterList = [];
    localStorage.removeItem("url");
    localStorage.removeItem('CategoryType');
    localStorage.setItem('ActiveClass', 'journal');

    localStorage.setItem('SearchType', 'Journal');
    localStorage.setItem('InfoCentersearchText', this.productName);
    localStorage.setItem('productName', this.productName);
    this._router.navigate(['/infocenter']);
    window.location.reload();
  }
  showreview: boolean;

  jobtitlemodel: string;
  openModalshowreview() {
    this.jobTitle = this.productName;
    this.service.firstlanguage = 0
    this.service.secondlanguage = 0;
    this.service.thirdlanguage = 0;
    this.service.country = 0;
    this.service.state = 0;
    this.service.city = 0;
    this.showreview = true;
  }
  closeModalshowreview() {
    this.showreview = false;
  }
  jobTitle = ""; compnayName = ""; PageNo = 1; RecordperPage = 20;
  searchjob() {
    localStorage.removeItem("url");
    this.service.jobTitle = this.jobTitle;
    this.service.companyName = this.compnayName;
    this._router.navigateByUrl('/joblist');
  }

  filterjobType(e) {
    this.service.jobType = e;
  }
  filterLanguage1(e) {
    this.service.firstlanguage = e;
  }
  filterLanguage2(e) {
    this.service.secondlanguage = e;
  }
  filterLanguage3(e) {
    this.service.thirdlanguage = e;
  }
  filterIndustries(e) {
    this.service.industries = e;
  }
  filterCountry(e) {
    this.service.country = e;
  }
  filterCity(e) {
    this.service.city = e;
  }
  jobList = [];
  jobTypeList = [];
  languageList = [];
  countryList = [];
  cityList = [];
  jobCategoryList() {
    this.service.GeJobCategoryList().subscribe(
      (res: any) => {

        this.jobList = res.jobList;
        this.jobTypeList = res.jobTypeList;
        this.languageList = res.languageList;
        this.countryList = res.countryList;
        // this.cityList = res.cityList;
      },
      err => {
        if (err.status == 400)

          //this.toastr.error('Incorrect username or password.', 'Authentication failed.');
          alert('error');
        else
          console.log(err);
      }
    );

  }
  rediresctContent(type) {
    if (type === "Privacy") {
      this._router.navigateByUrl('/privacy');
    }
    else if (type === "Terms") {
      this._router.navigateByUrl('/term-condtion');
    }
    else if (type === "aboutus") {
      this._router.navigateByUrl('/aboutus');
    }
    else if (type === "impressum") {
      this._router.navigateByUrl('/impressum');
    }
  }
  GetSearchList() {
    localStorage.removeItem("url");
    localStorage.removeItem('CategoryType');
    localStorage.setItem('ActiveClass', 'All');

    localStorage.setItem('SearchType', 'All');
    localStorage.setItem('InfoCentersearchText', this.productName);
    localStorage.setItem('productName', this.productName);
    this._router.navigate(['/infocenter']);
    // window.location.reload();
  }
  FnLogout() {
    localStorage.removeItem("url");
    localStorage.removeItem("userId");
    localStorage.removeItem("role");
    this.profileshowhide = false;
    this._router.navigateByUrl('/home');

  }
  fnProfile() {
    this._router.navigateByUrl('/userprofile');
  }
  GetUserDetails(id) {
    this.service.GetuserById(id).subscribe(
      (res: any) => {
        debugger;
        this.name = res.firstName;
      },
      err => {

        if (err.status == 400)
          alert('error');
        else
          console.log(err);
      }
    );
  }
  chnagePassword() {
    this._router.navigateByUrl('/user/change-password');
  }
  UserRequest() {
    localStorage.removeItem("url");
    this._router.navigateByUrl('/user/request');
  }
  cookieShowHide = true;
  cookiesfn() {
    this.cookieShowHide = false;
    localStorage.setItem('cookiesOk', "1");
  }

  stateList = [];
  jobStateList(id) {
    this.service.GetStateById(id).subscribe(
      (res: any) => {
        this.stateList = res.stateList;
      },
      err => {
        if (err.status == 400)
          alert('error');
        else
          console.log(err);
      }
    );

  }
  jobCityList(id) {
    this.service.GetCityById(id).subscribe(
      (res: any) => {
        this.service.state = id;
        this.cityList = res.cityList;
      },
      err => {
        if (err.status == 400)

          //this.toastr.error('Incorrect username or password.', 'Authentication failed.');
          alert('error');
        else
          console.log(err);
      }
    );
  }
  fnPrivacyPolicy()
  {
    this._router.navigateByUrl('/privacy');
  }
}
