import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../shared/common.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor(public service: CommonService, private _router: Router) { }
  searchText: string;
  showreview: boolean;
  jobtitlemodel: string;
  checkCookies = "";

  ngOnInit() {
    debugger;
    this.checkCookies = localStorage.getItem('cookiesOk');
    if (this.checkCookies === "1") {
      this.service.pageDisable = true;

    }
    else {
      this.service.pageDisable = false;

    }
    this.jobCategoryList();
    localStorage.removeItem("url");
    this.service.menuType = true;
    localStorage.removeItem('CategoryType')
    localStorage.setItem('ActiveClass', null);
    localStorage.setItem('header', 'Home');
  }
  GetProductList() {
    localStorage.removeItem("url");
    localStorage.setItem('ProductSearchType', '2');
    localStorage.setItem('productName', this.searchText);
    this._router.navigate(['/productlist']);
  }
  GetInfoCenterList() {
    localStorage.removeItem("url");
    localStorage.setItem('productName', this.searchText);
    localStorage.setItem('SearchType', 'All')
    this._router.navigate(['/infocenter']);
  }
  GetSearchList() {
    localStorage.removeItem("url");
    localStorage.removeItem("url");
    localStorage.setItem('productName', this.searchText);
    this._router.navigate(['/searchall']);
  }

  openModalshowreview() {
    this.jobTitle = this.searchText;
    this.service.firstlanguage = 0
    this.service.secondlanguage = 0;
    this.service.thirdlanguage = 0;

    this.showreview = true;
  }
  closeModalshowreview() {
    this.showreview = false;
  }
  jobTitle = "";
  jobList = [];
  jobTypeList = [];
  languageList = [];
  countryList = [];
  cityList = [];
  jobCategoryList() {
    this.service.GeJobCategoryList().subscribe(
      (res: any) => {
        this.jobList = res.jobList;
        this.jobTypeList = res.jobTypeList;
        this.languageList = res.languageList;
        this.countryList = res.countryList;
        //this.cityList = res.cityList;
      },
      err => {
        if (err.status == 400)

          //this.toastr.error('Incorrect username or password.', 'Authentication failed.');
          alert('error');
        else
          console.log(err);
      }
    );

  }
  compnayName = "";

  filterjobType(e) {
    this.service.jobType = e;
  }
  filterLanguage1(e) {
    this.service.firstlanguage = e;
  }
  filterLanguage2(e) {
    this.service.secondlanguage = e;
  }
  filterLanguage3(e) {
    this.service.thirdlanguage = e;
  }
  filterIndustries(e) {
    this.service.industries = e;
  }
  filterCountry(e) {
    this.service.country = e;
    this.jobStateList(e);
  }
  filterCity(e) {
    this.service.city = e;
  }
  searchjob() {
    debugger
    localStorage.removeItem("url");
    localStorage.setItem('productName', this.searchText);
    this.service.jobTitle = this.jobTitle;
    if (this.service.jobTitle === undefined) {
      this.service.jobTitle = "";
    }
    this.service.companyName = this.compnayName;
    this._router.navigate(['/joblist']);
  }
  stateList = [];
  jobStateList(id) {
    this.service.GetStateById(id).subscribe(
      (res: any) => {
        this.stateList = res.stateList;
      },
      err => {
        if (err.status == 400)

          //this.toastr.error('Incorrect username or password.', 'Authentication failed.');
          alert('error');
        else
          console.log(err);
      }
    );

  }
  jobCityList(id) {
    this.service.GetCityById(id).subscribe(
      (res: any) => {
        this.service.state = id;
        this.cityList = res.cityList;
      },
      err => {
        if (err.status == 400)

          //this.toastr.error('Incorrect username or password.', 'Authentication failed.');
          alert('error');
        else
          console.log(err);
      }
    );

  }
}