import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material'
import { deleteservice } from './../delete.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-deleteconfirmation',
  templateUrl: './deleteconfirmation.component.html',
  styleUrls: ['./deleteconfirmation.component.css']
})
export class DeleteconfirmationComponent implements OnInit {

  constructor
    (
      public dialogRef: MatDialogRef<DeleteconfirmationComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any,
      private service: deleteservice,
      private _router: Router
    ) { }

  ngOnInit() {
  }

  actionFunction() {
    //alert("You have logged out.");
    switch (this.data.type) {
      case "UserProfile":
        {
          this.service.Delete(this.data).subscribe(
            (res: any) => {
              this._router.navigateByUrl('admin/user/list');
              window.location.reload();
            },
            err => {

              if (err.status == 400)
                alert('error');
              else
                console.log(err);
            }
          );
          break;
        }
      case "VendorUser":
        {
          this.service.Delete(this.data).subscribe(
            (res: any) => {
              this._router.navigateByUrl('admin/vendor/list');
              window.location.reload();
            },
            err => {

              if (err.status == 400)
                alert('error');
              else
                console.log(err);
            }
          );
          break;
        }
      case "Product":
        {
          this.service.Delete(this.data).subscribe(
            (res: any) => {
              this._router.navigateByUrl('admin/product/list');
              window.location.reload();
            },
            err => {

              if (err.status == 400)
                alert('error');
              else
                console.log(err);
            }
          );
          break;
        }
      case "Job":
        {
          this.service.Delete(this.data).subscribe(
            (res: any) => {
              this._router.navigateByUrl('admin/job/list');
              window.location.reload();
            },
            err => {

              if (err.status == 400)
                alert('error');
              else
                console.log(err);
            }
          );
          break;
        }
      case "VendorVideo":
        {
          this.service.Delete(this.data).subscribe(
            (res: any) => {
              this._router.navigateByUrl('admin/video/list');
              window.location.reload();
            },
            err => {

              if (err.status == 400)
                alert('error');
              else
                console.log(err);
            }
          );
          break;
        }
      case "Journal":
        {
          this.service.Delete(this.data).subscribe(
            (res: any) => {
              this._router.navigateByUrl('admin/journal/list');
              window.location.reload();
            },
            err => {

              if (err.status == 400)
                alert('error');
              else
                console.log(err);
            }
          );
          break;
        }
      case "Document":
        {
          this.service.Delete(this.data).subscribe(
            (res: any) => {
              this._router.navigateByUrl('admin/document/list');
              window.location.reload();
            },
            err => {

              if (err.status == 400)
                alert('error');
              else
                console.log(err);
            }
          );
          break;
        }

        
      case "ApplyVendor":
        {
          this.service.Delete(this.data).subscribe(
            (res: any) => {
              this._router.navigateByUrl('admin/request/list');
              window.location.reload();
            },
            err => {

              if (err.status == 400)
                alert('error');
              else
                console.log(err);
            }
          );
          break;
        }
      case "Product":
        {
          this.service.Deleterequest(this.data).subscribe(
            (res: any) => {
              this._router.navigateByUrl('admin/request/list');
              window.location.reload();
            },
            err => {

              if (err.status == 400)
                alert('error');
              else
                console.log(err);
            }
          );
          break;
        }
      case "VendorVideo":
        {
          this.service.Deleterequest(this.data).subscribe(
            (res: any) => {
              this._router.navigateByUrl('admin/request/list');
              window.location.reload();
            },
            err => {

              if (err.status == 400)
                alert('error');
              else
                console.log(err);
            }
          );
          break;
        }
      case "Document":
        {
          this.service.Deleterequest(this.data).subscribe(
            (res: any) => {
              this._router.navigateByUrl('admin/request/list');
              window.location.reload();
            },
            err => {

              if (err.status == 400)
                alert('error');
              else
                console.log(err);
            }
          );
          break;
        }
      default:
        {
          break;
        }
    }

    this.closeModal();
  }

  // If the user clicks the cancel button a.k.a. the go back button, then\
  // just close the modal
  closeModal() {
    this.dialogRef.close();
  }
}
