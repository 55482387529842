import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from '../../shared/common.service';


@Component({
  selector: 'app-userforgotpassword',
  templateUrl: './userforgotpassword.component.html',
  styleUrls: ['./userforgotpassword.component.css']
})
export class UserforgotpasswordComponent implements OnInit {
  ForgotPasswordForm: FormGroup;
  constructor(private formBuilder: FormBuilder, private _router: Router, private service: CommonService) { }

  ngOnInit() {
  this.ForgotPasswordForm = this.formBuilder.group({
    email: ['', [Validators.required, Validators.email]],

  });
  }

  backToLogin(): void {
    this._router.navigate(['user/login']);

  }

  onSubmit() {

    //alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.loginForm.value))

    var body = {

      Email: this.ForgotPasswordForm.value.email,

    };

    this.service.userfogotpassword(body).subscribe(
      (res: any) => {


        if (res.status == true) {

          // localStorage.setItem('token', res.token);
          this._router.navigateByUrl('/user/login');
          alert("--Your password has been reset and sent on your email address--");
        }
        else {
          alert("User not exist ");
        }

      },
      err => {

        if (err.status == 400)

          //this.toastr.error('Incorrect username or password.', 'Authentication failed.');
          alert('error');
        else
          console.log(err);
      }
    );


  }

}






