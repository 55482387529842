import { Component, OnInit,Inject } from '@angular/core';
import { CommonService } from '../../shared/common.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Location, DOCUMENT } from '@angular/common';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
@Component({
  selector: 'app-video-category',
  templateUrl: './video-category.component.html',
  styleUrls: ['./video-category.component.css']
})
export class VideoCategoryComponent implements OnInit {
  categoryName = ""; PageNo = 1; RecordperPage = 10; VideoList = [];
  constructor(public service: CommonService,private formBuilder: FormBuilder, private spinner: NgxSpinnerService, private _router: Router, @Inject(DOCUMENT) private document: Document,location: Location) {
    this.baseUrl = this.document.location.origin;
    if (location.path() != "") {
      this.route1 = location.path();
    } else {
      this.route1 = "Home";
    }
   }
  companyLogo = ""; VendorId = 0; id = 0; videoName = ""; VideoCategoryDecription = [];
  ShowAccount = false; AddForm: FormGroup;
  showTextArea = false;userId = 0;
  baseUrl: string; route1: string;
  ngOnInit() {
    this.jobCategoryList();
    this.AddForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]],
      countryId: ['', Validators.required],
      description: ['', Validators.required],
      quotationOffer: ['', Validators.required],
      question: ['', Validators.required]

    });
    this.ShowAccount = true;
    this.id = +localStorage.getItem('VideoId');
    this.service.VideoTitle = localStorage.getItem('VideoTitle');
    this.VendorId = + localStorage.getItem('VendorId');
    this.userId = Number(localStorage.getItem('userId'));
    this.GetSearchList('BestPractice', this.PageNo, this.RecordperPage, this.VendorId );
    this.GetVideo();
  }
  GetSearchList(CategoryName, PageNo, recordPerPage,id) {
    this.VideoCategoryDecription = [];
    this.service.SearchVideoyCategory(CategoryName, this.PageNo, this.RecordperPage,id).subscribe(
      (res: any) => {
       
        if (res[0].infoCenterListView.length > 0) {
          for (let index = 0; index < res[0].infoCenterListView.length; index++) {
            this.VideoList.push({
              id: res[0].infoCenterListView[index].id,
              title: res[0].infoCenterListView[index].title,
              description: res[0].infoCenterListView[index].description,
              type: res[0].infoCenterListView[index].type,
              uploadVideo: res[0].infoCenterListView[index].uploadVideo,
              reviewVideo: res[0].infoCenterListView[index].reviewVideo,
              vendorName: res[0].infoCenterListView[index].vendorName,
              vendorLogo: res[0].infoCenterListView[index].vendorLogo,
              thumbNail: res[0].infoCenterListView[index].thumbNail + '.jpg',
              createdDate : res[0].infoCenterListView[index].createdDate 
            })
          }
          this.videoName = this.VideoList[0].uploadVideo;
          this.VideoCategoryDecription.push({ uploadVideo: 
            this.VideoList[0].uploadVideo+"#t=0.001",
             vendorName: this.VideoList[0].vendorName,
              vendorLogo: this.VideoList[0].vendorLogo , reviewVideo: this.VideoList[0].reviewVideo 
              , createdDate: this.VideoList[0].createdDate })
        }
        else {
          this.spinner.hide();
        }
      },
      err => {
        if (err.status == 400)

          //this.toastr.error('Incorrect username or password.', 'Authentication failed.');
          alert('error');
        else
          console.log(err);
      }
    );
  }
  ChangeCategory(category) {
   
    this.VideoList = [];
    this.GetSearchListByCategory(category, this.PageNo, this.RecordperPage);
  }

  GetSearchListByCategory(CategoryName, PageNo, recordPerPage) {
    this.service.SearchVideoyCategory(CategoryName, this.PageNo, this.RecordperPage,this.VendorId).subscribe(
      (res: any) => {
       
        if (res[0].infoCenterListView.length > 0) {
          for (let index = 0; index < res[0].infoCenterListView.length; index++) {
            this.VideoList.push({
              id: res[0].infoCenterListView[index].id,
              title: res[0].infoCenterListView[index].title,
              description: res[0].infoCenterListView[index].description,
              type: res[0].infoCenterListView[index].type,
              uploadVideo: res[0].infoCenterListView[index].uploadVideo,
              reviewVideo: res[0].infoCenterListView[index].reviewVideo,
              vendorName: res[0].infoCenterListView[index].vendorName,
              vendorLogo: res[0].infoCenterListView[index].vendorLogo,
              thumbNail: res[0].infoCenterListView[index].thumbNail + '.jpg'
            })
          }
        
        }
        else {
          this.spinner.hide();
        }
      },
      err => {
        if (err.status == 400)

          //this.toastr.error('Incorrect username or password.', 'Authentication failed.');
          alert('error');
        else
          console.log(err);
      }
    );
  }
  
  GetVideo() {
    this.service.GetVideoById(this.id).subscribe(
      (res: any) => {
       

        this.service.companyName = res.companyName;
        this.service.companyLogo = res.companyLogo;

      },
      err => {

        if (err.status == 400)
          //this.toastr.error('Incorrect username or password.', 'Authentication failed.');
          alert('error');
        else
          console.log(err);

        //this.reset();
      }
    );

  }
  redirectvendorProduct() {
    this.service.productList=[];
    localStorage.setItem('ProductSearchType', '1');
    this.VendorId = + localStorage.setItem('VendorId',this.VendorId.toString());
    this._router.navigate(['/productlist'])
  }
  PlayVideo(videoName, videoTitle, vendorNmme, vendorlogo,reviewVideo,createdDate) {
   
    this.VideoCategoryDecription = [];
    this.service.VideoTitle = videoTitle;
    this.VideoCategoryDecription.push({ uploadVideo: videoName+"#t=0.001", vendorName: vendorNmme, 
      vendorLogo: vendorlogo , reviewVideo: reviewVideo 
      , createdDate: createdDate})
  }
  SendRequest() {

    let url = this.route1;
    let role = localStorage.getItem("role");
    localStorage.setItem("url", url);
    if (role == "Admin") {
      this._router.navigateByUrl('/user/login');
    }
    else if (role == "Vendor") {
      this._router.navigateByUrl('/user/login');
    }
    else if (role == "User") {
      this.ShowAccount = false;
      this.showTextArea = true;
      this.userId = Number(localStorage.getItem('userId'));
    }
    else {
      this._router.navigateByUrl('/user/login');
    }
  }
  QuotationOffer = false;
  Question = false; VideoDescription = "";
  onSubmit() {
    debugger;
    this.spinner.show();
    this.userId = Number(localStorage.getItem('userId'));
    
    if (this.AddForm.value.countryId === "" || this.AddForm.value.countryId === undefined) {
      this.spinner.hide();
      alert("Please select country");      
      return false;
    }
    if (this.Question === false && this.QuotationOffer === false) {
      this.spinner.hide();
      alert("Please check Quotation Offer  or Question");
      return false;
    }
    if (this.AddForm.value.description === "" || this.AddForm.value.description === undefined) {
      this.spinner.hide();
      alert("Please enter decription");
      return false;
    }
    var body = {
      VideoTitle: this.service.VideoTitle,
      VideoId: this.id,
      VideoDescription: this.VideoDescription,
      UserId: this.userId,
      CreatedBy: this.userId,
      VendorId: this.VendorId,
      Email: this.AddForm.value.email,
      CountryId: this.AddForm.value.countryId,
      QuotationOffer: this.QuotationOffer,
      Quotation: this.Question,
      Description: this.AddForm.value.description,
    };


    this.service.VideoSendRequest(body).subscribe(
      (res: any) => {        
        setTimeout(() => {
          // alert('Notification is sent successfully')
          this.ShowAccount = true;
          this.showTextArea = false;
          this.spinner.hide();
        }, 1000);
      },
      err => {
        if (err.status == 400)

          //this.toastr.error('Incorrect username or password.', 'Authentication failed.');
          alert('error');
        else
          console.log(err);
      }
    );
  }
  fnBack() {
    this.ShowAccount = true;
    this.showTextArea = false;
  }

  fnQuotationOffer(e) {
    
    if (e) {
      this.QuotationOffer = true;
      this.Question = false;
    }
    else {
      this.QuotationOffer = false;
    }
  }
  fnQuotation(e) {
    
    if (e) {
      this.QuotationOffer = false;
      this.Question = true;
    }
    else {
      this.Question = false;
    }
  }
  countryList = [];
  cityList = [];
  jobCategoryList() {
    this.service.GeJobCategoryList().subscribe(
      (res: any) => {



        this.countryList = res.countryList;
        this.cityList = res.cityList;
      },
      err => {
        if (err.status == 400)

          //this.toastr.error('Incorrect username or password.', 'Authentication failed.');
          alert('error');
        else
          console.log(err);
      }
    );

  }
}
