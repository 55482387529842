import { Component, OnInit, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { CommonService } from '../../shared/common.service';
import { Router } from '@angular/router';
import { PagerService } from '../../shared/pager.service';

@Component({
  selector: 'app-home-list-master',
  templateUrl: './home-list-master.component.html',
  styleUrls: ['./home-list-master.component.css']
})
export class HomeListMasterComponent implements OnInit {
  productName: string;
  constructor(private service: CommonService, private _router: Router, private pagerService: PagerService) { }
  UserId = 0; role = ""; profileshowhide = false;
  name = "";

  ngOnInit() {

    let checkCookies = localStorage.getItem('cookiesOk');
    if (checkCookies === "1") {
      this.cookieShowHide = false;
    }
    else {
      this.cookieShowHide = true;
    }
    this.jobCategoryList();
    this.productName = localStorage.getItem('productName');
    this.jobTitle = this.productName;
    this.UserId = +localStorage.getItem('userId');
    this.role = localStorage.getItem('role');
    if (this.role === "User") {
      this.profileshowhide = true;
      this.GetUserDetails(this.UserId);
    }
    else {
      this.profileshowhide = false;
    }
    if (this.productName === undefined || this.productName === "undefined" || this.productName === "null") {
      this.productName = "";
      localStorage.setItem("productName", '');
    }
  }
  cookieShowHide = true;
  cookiesfn() {
    this.cookieShowHide = false;
    localStorage.setItem('cookiesOk', "1");
  }
  GetProductList() {
    localStorage.removeItem("url");
    this.service.productList = [];
    localStorage.setItem('productName', this.productName);
    localStorage.setItem('ProductSearchType', '2');
    this._router.navigate(['/productlist']);
    this.GetProductList1(this.productName, this.PageNo, this.RecordperPage);
    this.CountProductList(this.productName);
  }
  GetInfoCenterList() {
    this.service.AllPageNo = 1;
    this.service.infoCenterList = [];
    localStorage.removeItem("url");
    localStorage.setItem('productName', this.productName);
    localStorage.setItem('ActiveClass', 'All');

    localStorage.setItem('SearchType', 'All');
    this._router.navigate(['/infocenter']);
  }
  GetJobList() {
    localStorage.removeItem("url");
    localStorage.setItem('productName', this.productName);
    this._router.navigate(['/joblist']);
  }
  showreview: boolean;

  jobtitlemodel: string;
  openModalshowreview() {
    this.jobTitle = this.productName;
    this.service.firstlanguage = 0
    this.service.secondlanguage = 0;
    this.service.thirdlanguage = 0;
    this.service.country = 0;
    this.service.state = 0;
    this.service.city = 0;
    localStorage.setItem('productName', this.jobTitle)
    this.showreview = true;
  }
  closeModalshowreview() {
    this.showreview = false;
  }
  jobTitle = ""; compnayName = ""; PageNo = 1; RecordperPage = 20;
  searchjob() {
    debugger
    localStorage.removeItem("url");
    this.service.jobTitle = this.jobTitle;
    this.productName = this.jobTitle;
    this.service.companyName = this.compnayName;
    localStorage.setItem('productName', this.jobTitle)
    this._router.navigateByUrl('/joblist');
  }

  filterjobType(e) {
    this.service.jobType = e;
  }
  filterLanguage1(e) {
    this.service.firstlanguage = e;
  }
  filterLanguage2(e) {
    this.service.secondlanguage = e;
  }
  filterLanguage3(e) {
    this.service.thirdlanguage = e;
  }
  filterIndustries(e) {
    this.service.industries = e;
  }
  filterCountry(e) {
    this.service.country = e;
    this.jobStateList(e);
  }
  filterCity(e) {
    this.service.city = e;
  }
  jobList = [];
  jobTypeList = [];
  languageList = [];
  countryList = [];
  cityList = [];
  jobCategoryList() {
    this.service.GeJobCategoryList().subscribe(
      (res: any) => {

        this.jobList = res.jobList;
        this.jobTypeList = res.jobTypeList;
        this.languageList = res.languageList;
        this.countryList = res.countryList;
        this.cityList = res.cityList;
      },
      err => {
        if (err.status == 400)

          //this.toastr.error('Incorrect username or password.', 'Authentication failed.');
          alert('error');
        else
          console.log(err);
      }
    );

  }
  rediresctContent(type) {
    if (type === "Privacy") {
      this._router.navigateByUrl('/privacy');
    }
    else if (type === "Terms") {
      this._router.navigateByUrl('/term-condtion');
    }
    else if (type === "aboutus") {
      this._router.navigateByUrl('/aboutus');
    }
    else if (type === "impressum") {
      this._router.navigateByUrl('/impressum');
    }
  }
  GetSearchList() {

    this.service.productList = [];
    localStorage.removeItem("url");
    localStorage.setItem('productName', this.productName);
    // this._router.navigate(['/searchall']);
    this._router.navigate(['/productlist']);
    this.GetProductList1(this.productName, this.PageNo, this.RecordperPage);
    this.CountProductList(this.productName);

  }
  CountProductList(productName) {
    this.service.CountProductAll(this.productName).subscribe(
      (res: any) => {

        this.service.countAll = res[0].totalCount[0].totalCount;
        this.setPage(1);
      },
      err => {
        if (err.status == 400)

          //this.toastr.error('Incorrect username or password.', 'Authentication failed.');
          alert('error');
        else
          console.log(err);
      }
    );
  }
  GetProductList1(productName, PageNo, recordPerPage) {

    this.service.GetProductSearchList(this.productName, this.PageNo, this.RecordperPage).subscribe(
      (res: any) => {

        if (res[0].productList.length > 0) {

          for (let index = 0; index < res[0].productList.length; index++) {
            this.service.productList.push({
              id: res[0].productList[index].id,
              categoryName: res[0].productList[index].categoryName,
              companyName: res[0].productList[index].companyName,
              createdBy: res[0].productList[index].createdBy, createdDate: res[0].productList[index].createdDate
              , image: res[0].productList[index].image, isActive: res[0].productList[index].isActive
              , manufactruningWebSite: res[0].productList[index].manufactruningWebSite, modifiedBy: res[0].productList[index].modifiedBy
              , productAccessories: res[0].productList[index].productAccessories, productDescription: res[0].productList[index].productDescription
              , productName: res[0].productList[index].productName,
              productSpecification: res[0].productList[index].productSpecification
            })
          }
        }
        else {
          // this.spinner.hide();
        }
      },
      err => {
        if (err.status == 400)

          //this.toastr.error('Incorrect username or password.', 'Authentication failed.');
          alert('error');
        else
          console.log(err);
      }
    );
  }
  GetUserDetails(id) {
    this.service.GetuserById(id).subscribe(
      (res: any) => {

        this.name = res.firstName;
      },
      err => {

        if (err.status == 400)
          alert('error');
        else
          console.log(err);
      }
    );
  }
  FnLogout() {
    localStorage.removeItem("url");
    localStorage.removeItem("userId");
    localStorage.removeItem("role");
    this.profileshowhide = false;
    this._router.navigateByUrl('/home');
  }
  fnProfile() {
    this._router.navigateByUrl('/userprofile');
  }
  chnagePassword() {
    this._router.navigateByUrl('/user/change-password');
  }
  UserRequest() {
    localStorage.removeItem("url");
    this._router.navigateByUrl('/user/request');
  }

  // Custom Paging

  ngOnChanges(): any {

    const pageCount = this.getPageCount();
    this.service.pages = this.getArrayOfPage(pageCount);
    this.service.activePage = 1;
    this.service.onPageChange.emit(1);
  }
  private getPageCount(): number {
    let totalPage = 0;

    if (this.service.totalRecords > 0 && this.service.recordsPerPage > 0) {
      const pageCount = this.service.totalRecords / this.service.recordsPerPage;
      const roundedPageCount = Math.floor(pageCount);

      totalPage = roundedPageCount < pageCount ? roundedPageCount + 1 : roundedPageCount;
    }

    return totalPage;
  }
  private getArrayOfPage(pageCount: number): number[] {
    const pageArray = [];

    if (pageCount > 0) {
      for (let i = 1; i <= pageCount; i++) {
        pageArray.push(i);
      }
    }
    return pageArray;
  }
  onClickPage(pageNumber: number): void {
    debugger;
    if (pageNumber >= 1 && pageNumber <= this.service.pages.length) {
      this.service.activePage = pageNumber;
      this.service.onPageChange.emit(this.service.activePage);


    }
  }
  setPage(page: number) {

    this.service.pager = this.pagerService.getPager(this.service.countAll, page);


  }
  stateList = [];
  jobStateList(id) {
    this.service.GetStateById(id).subscribe(
      (res: any) => {
        this.stateList = res.stateList;
      },
      err => {
        if (err.status == 400)

          //this.toastr.error('Incorrect username or password.', 'Authentication failed.');
          alert('error');
        else
          console.log(err);
      }
    );

  }
  jobCityList(id) {
    this.service.GetCityById(id).subscribe(
      (res: any) => {
        this.service.state = id;
        this.cityList = res.cityList;
      },
      err => {
        if (err.status == 400)

          //this.toastr.error('Incorrect username or password.', 'Authentication failed.');
          alert('error');
        else
          console.log(err);
      }
    );

  }
  fnPrivacyPolicy()
  {
    this._router.navigateByUrl('/privacy');
  }
}
