import { Injectable, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppSettings } from './AppSettings';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  companyLogo = ""; companyName = ""; jobListMain = []; jobTitle = ""; jobType = ""; language = ""; firstlanguage = 0; secondlanguage = 0; thirdlanguage =0; industries = ""; country = 0; city = 0; countAll = 0;
  state = 0;
  VideoTitle = "";
  AllPageNo = 1;
  JournalPageNo = 1;
  documentPageNo = 1;
  videoPageNo = 1;
  productList = []; infoCenterList = [];
  @Input() totalRecords = 0;
  @Input() recordsPerPage = 0;

  @Output() onPageChange: EventEmitter<number> = new EventEmitter();
  pageDisable = false;
  // pager object
  pager: any = {};

  // paged items
  pagedItems: any[];



  public pages: number[] = [];
  activePage: number;
  constructor(private http: HttpClient) { }
  menuType = false; productName

  GetAllCountry(): Observable<any> {

    return this.http.get<any>(AppSettings.API_ENDPOINT + '/UserProfile/countryList');
  }
  GetuserById(id: number): Observable<any> {

    return this.http.get<any>(AppSettings.API_ENDPOINT + '/UserProfile/GetUser?id=' + id);
  }
  GetContent() {
    return this.http.get(AppSettings.API_ENDPOINT + '/Content/GetContent');
  }
  Add(formData) {

    return this.http.post(AppSettings.API_ENDPOINT + '/UserProfile/Add', formData);
  }
  Edit(formData) {

    return this.http.post(AppSettings.API_ENDPOINT + '/UserProfile/Edit', formData);
  }
  AddPassword(formData) {

    return this.http.post(AppSettings.API_ENDPOINT + '/Account/Register', formData);
  }
  GetProductSearchList(productName, pageNo, recordPerPage): Observable<any> {

    return this.http.get<any>(AppSettings.API_ENDPOINT + '/Product/ProductSearchList?ProductName=' + productName + "&PageNo=" + pageNo + "&RecordPerPage=" + recordPerPage);
  }
  GeInfocenterSearchList(searchText, pageNo, recordPerPage): Observable<any> {

    return this.http.get<any>(AppSettings.API_ENDPOINT + '/Document/searchList?Text=' + searchText + "&PageNo=" + pageNo + "&RecordPerPage=" + recordPerPage);
  }
  GeJournalearchList(searchText, pageNo, recordPerPage): Observable<any> {

    return this.http.get<any>(AppSettings.API_ENDPOINT + '/Journal/searchList?Text=' + searchText + "&PageNo=" + pageNo + "&RecordPerPage=" + recordPerPage);
  }
  GetdocumentearchList(searchText, pageNo, recordPerPage): Observable<any> {

    return this.http.get<any>(AppSettings.API_ENDPOINT + '/Document/documentsearchList?Text=' + searchText + "&PageNo=" + pageNo + "&RecordPerPage=" + recordPerPage);
  }
  GetvideoearchList(searchText, pageNo, recordPerPage): Observable<any> {

    return this.http.get<any>(AppSettings.API_ENDPOINT + '/VendorVideo/searchList?Text=' + searchText + "&PageNo=" + pageNo + "&RecordPerPage=" + recordPerPage);
  }
  AddVendorAccount(formData) {

    return this.http.post(AppSettings.API_ENDPOINT + '/VendorUser/Add', formData);
  }
  AddVideo(formData: FormData) {

    return this.http.post(AppSettings.API_ENDPOINT + '/VendorUser/FileUpload', formData, { reportProgress: true, observe: 'events' });
  }

  GetSpaceReviewByProductId(ProductId): Observable<any> {

    return this.http.get<any>(AppSettings.API_ENDPOINT + '/Product/sacereviewbyproduct?productId=' + ProductId);
  }

  AccountpasswordSelection(formData) {

    return this.http.post(AppSettings.API_ENDPOINT + '/Account/vendoraccountselection', formData);
  }
  IsPendingMail(formData) {

    return this.http.post(AppSettings.API_ENDPOINT + '/Account/PendingMail', formData);
  }
  PaymentApi(formData) {

    return this.http.post(AppSettings.API_ENDPOINT + '/Account/payment-info', formData);
  }
  AddVendorPassword(formData) {

    return this.http.post(AppSettings.API_ENDPOINT + '/Account/vendorRegister', formData);
  }

  SenddMailOfVendorPassword(formData) {

    return this.http.post(AppSettings.API_ENDPOINT + '/Account/vendorpasswordgenerate', formData);
  }
  SenddMailOfVendorPayment(formData) {

    return this.http.post(AppSettings.API_ENDPOINT + '/Account/sendpaymentemail', formData);
  }
  SenddMailOfUserPassword(formData) {

    return this.http.post(AppSettings.API_ENDPOINT + '/Account/RegisterPassword', formData);
  }
  SenddMailOfuserPending(formData) {

    return this.http.post(AppSettings.API_ENDPOINT + '/Account/PendingUserMail', formData);
  }
  subcriptionList(): Observable<any> {

    return this.http.get<any>(AppSettings.API_ENDPOINT + '/Subscriptio/subcription-list');
  }
  userfogotpassword(formData) {
    return this.http.post(AppSettings.API_ENDPOINT + '/Account/ForgotPassword', formData);
  }
  AddVendrSubscription(formData) {

    return this.http.post(AppSettings.API_ENDPOINT + '/Subscriptio/Add', formData);
  }
  GetAllSearchAllSearchList(SearchText, pageNo, recordPerPage): Observable<any> {

    return this.http.get<any>(AppSettings.API_ENDPOINT + '/Product/SearchAll?SearchText=' + SearchText + "&PageNo=" + pageNo + "&RecordPerPage=" + recordPerPage);
  }
  CountAll(SearchText): Observable<any> {

    return this.http.get<any>(AppSettings.API_ENDPOINT + '/Product/CountSearchAll?SearchText=' + SearchText);
  }

  CountProductAll(SearchText): Observable<any> {

    return this.http.get<any>(AppSettings.API_ENDPOINT + '/Product/countProducSearch?SearchText=' + SearchText);
  }

  GeJobCategoryList(): Observable<any> {

    return this.http.get<any>(AppSettings.API_ENDPOINT + '/Job/totaljobList');
  }

  JobCategorySearch(JobTitle, JobType, CompanyName, CountryNamme, state, CityName, Language1, Language2, Language3, PageNo, RecordPerPage, industries): Observable<any> {

    return this.http.get<any>(AppSettings.API_ENDPOINT + '/Job/jobbsearchList?JobTitle=' + JobTitle + "&JobType=" + JobType + "&CompanyName=" + CompanyName + "&CountryNamme=" + CountryNamme + "&state=" + state + "&CityName=" + CityName + "&Language1=" + Language1 + "&Language2=" + Language2 + "&Language3=" + Language3 + "&PageNo=" + PageNo + "&RecordPerPage=" + RecordPerPage + "&Industries=" + industries);
  }
  JobCategorySearchSelection(formData) {

    return this.http.post(AppSettings.API_ENDPOINT + '/Job/jobbsearchListselect', formData);
  }
  GetJobById(id: number): Observable<any> {

    return this.http.get<any>(AppSettings.API_ENDPOINT + '/Job/get?id=' + id);
  }
  Apply(formData) {

    return this.http.post(AppSettings.API_ENDPOINT + '/ApplyVendor/Add', formData);
  }
  FileUploadVendor(formData: FormData) {

    return this.http.post(AppSettings.API_ENDPOINT + '/ApplyVendor/FileUpload', formData, { reportProgress: true, observe: 'events' });
  }
  GetProductById(id: number): Observable<any> {

    return this.http.get<any>(AppSettings.API_ENDPOINT + '/Product/getProduct?id=' + id);
  }
  AddProductRequest(formData) {

    return this.http.post(AppSettings.API_ENDPOINT + '/Product/Add-Product-Request', formData);
  }
  GetJournalById(id: number): Observable<any> {

    return this.http.get<any>(AppSettings.API_ENDPOINT + '/Journal/get?id=' + id);
  }
  SearchByCategory(searchText, type, pageNo, recordPerPage): Observable<any> {

    return this.http.get<any>(AppSettings.API_ENDPOINT + '/Document/searchListByCategory?Text=' + searchText + "&Type=" + type + "&PageNo=" + pageNo + "&RecordPerPage=" + recordPerPage);
  }
  SearchJournalByCategory(searchText, type, pageNo, recordPerPage): Observable<any> {

    return this.http.get<any>(AppSettings.API_ENDPOINT + '/Journal/searchListByCategory?Text=' + searchText + "&Type=" + type + "&PageNo=" + pageNo + "&RecordPerPage=" + recordPerPage);
  }
  DocumentByCategory(searchText, type, pageNo, recordPerPage): Observable<any> {

    return this.http.get<any>(AppSettings.API_ENDPOINT + '/Document/searchDocumentByCategory?Text=' + searchText + "&Type=" + type + "&PageNo=" + pageNo + "&RecordPerPage=" + recordPerPage);
  }
  VideoyCategory(searchText, type, pageNo, recordPerPage): Observable<any> {

    return this.http.get<any>(AppSettings.API_ENDPOINT + '/VendorVideo/SearchVideoByCategory?Text=' + searchText + "&Type=" + type + "&PageNo=" + pageNo + "&RecordPerPage=" + recordPerPage);
  }
  GetVideoById(id: number): Observable<any> {

    return this.http.get<any>(AppSettings.API_ENDPOINT + '/VendorVideo/get?id=' + id);
  }
  ReviewVideo(id: number): Observable<any> {

    return this.http.get<any>(AppSettings.API_ENDPOINT + '/VendorVideo/update-review?id=' + id);
  }

  SearchVideoyCategory(category, pageNo, recordPerPage, vendorId): Observable<any> {

    return this.http.get<any>(AppSettings.API_ENDPOINT + '/VendorVideo/VideoByCategory?category=' + category + "&PageNo=" + pageNo + "&RecordPerPage=" + recordPerPage + "&vendorId=" + vendorId);
  }

  similarVideo(title, pageNo, recordPerPage): Observable<any> {

    return this.http.get<any>(AppSettings.API_ENDPOINT + '/VendorVideo/similar-video?title=' + title + "&PageNo=" + pageNo + "&RecordPerPage=" + recordPerPage);
  }
  VideoSendRequest(formData) {

    return this.http.post(AppSettings.API_ENDPOINT + '/VendorVideo/video-request', formData);
  }
  GetByDocumentId(id: number): Observable<any> {

    return this.http.get<any>(AppSettings.API_ENDPOINT + '/Document/get?id=' + id);
  }
  documentRquest(formData) {

    return this.http.post(AppSettings.API_ENDPOINT + '/Document/document-request', formData);
  }
  GetProductSearchListByVendor(vendorId, pageNo, recordPerPage): Observable<any> {
    return this.http.get<any>(AppSettings.API_ENDPOINT + '/Product/product-by-vendor?vendorId=' + vendorId + "&PageNo=" + pageNo + "&RecordPerPage=" + recordPerPage);
  }

  countProductByvendor(vendorId): Observable<any> {
    return this.http.get<any>(AppSettings.API_ENDPOINT + '/Product/count-vendor-wise?vendorId=' + vendorId);
  }
  countinfocenter(vendorId, type, Category): Observable<any> {
    return this.http.get<any>(AppSettings.API_ENDPOINT + '/Product/count-info-center?vendorId=' + vendorId + "&type=" + type + "&Category=" + Category);
  }
  JobCount(JobTitle, JobType, CompanyName, CountryNamme, stateName, CityName, Language1, Language2, Language3, PageNo, RecordPerPage, industries): Observable<any> {

    return this.http.get<any>(AppSettings.API_ENDPOINT + '/Job/jocount?JobTitle=' + JobTitle + "&JobType=" + JobType + "&CompanyName=" + CompanyName + "&CountryNamme=" + CountryNamme + "&stateName=" + stateName + "&CityName=" + CityName + "&Language1=" + Language1 + "&Language2=" + Language2 + "&Language3=" + Language3 + "&PageNo=" + PageNo + "&RecordPerPage=" + RecordPerPage + "&Industries=" + industries);
  }
  JobCountCategorySearchSelection(formData) {

    return this.http.post(AppSettings.API_ENDPOINT + '/Job/jobcountfilter', formData);
  }
  CheckContactEmail(email) {
    return this.http.get(AppSettings.API_ENDPOINT + '/UserProfile/checkemail?emailId=' + email);
  }
  CheckVendorEmail(email) {
    return this.http.get(AppSettings.API_ENDPOINT + '/VendorUser/checkemail?emailId=' + email);
  }
  changepassword(formData) {

    return this.http.post(AppSettings.API_ENDPOINT + '/Account/ChangePssword', formData);
  }
  checksubscription(vendorid): Observable<any> {

    return this.http.get<any>(AppSettings.API_ENDPOINT + '/Subscriptio/check-vendor-subscription?id=' + vendorid);
  }
  GetStateById(id: number): Observable<any> {

    return this.http.get<any>(AppSettings.API_ENDPOINT + '/Job/get-state-country?countryId=' + id);
  }
  GetCityById(id: number): Observable<any> {

    return this.http.get<any>(AppSettings.API_ENDPOINT + '/Job/get-city?stateId=' + id);
  }
  Decript(id: string): Observable<any> {

    return this.http.get<any>(AppSettings.API_ENDPOINT + '/Account/Decript?Id=' + id);
  }
}
