import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../shared/common.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { PagerService } from '../../shared/pager.service';
@Component({
  selector: 'app-home-info-center-list',
  templateUrl: './home-info-center-list.component.html',
  styleUrls: ['./home-info-center-list.component.css']
})
export class HomeInfoCenterListComponent implements OnInit {

  constructor(public service: CommonService, private spinner: NgxSpinnerService, private _router: Router, private pagerService: PagerService) { }
  searchText: string;
  infoCenterList = [];
  AllPageNo = 1;
  JournalPageNo = 1;
  documentPageNo = 1;
  videoPageNo = 1;
  RecordperPage = 10;
  searchType = "";
  ngOnInit() {

    this.searchText = localStorage.getItem('productName');

    if (this.searchText === undefined || this.searchText === "undefined") {
      this.searchText = "";
      localStorage.setItem('productName', '');
    }
    localStorage.setItem('SearchType', 'All');
    this.searchType = "All";

    let categoryType = localStorage.getItem('CategoryType');


    if (this.searchType === "All") {
      if (categoryType === 'BestPractice' || categoryType === 'LessionLearnd' || categoryType === 'News' || categoryType === 'Tutorial' || categoryType === 'ToolBoxTalk' || categoryType === 'Innovation' || categoryType === 'ReacherchDeveloment' || categoryType === 'Exhibition' || categoryType === 'ProductPersentation') {
        this.GetAllSearchbyCategory(this.searchText, categoryType, this.service.AllPageNo, this.RecordperPage);
        this.CountInfoCenterList(this.searchText, 5, categoryType);
      }
      else {
        this.GetSearchList(this.searchText, this.service.AllPageNo, this.RecordperPage);
        this.CountInfoCenterList(this.searchText, 1, "");
      }
    }
    else if (this.searchType === "Journal") {
      if (categoryType === 'BestPractice' || categoryType === 'LessionLearnd' || categoryType === 'News' || categoryType === 'Tutorial' || categoryType === 'ToolBoxTalk' || categoryType === 'Innovation' || categoryType === 'ReacherchDeveloment' || categoryType === 'Exhibition' || categoryType === 'ProductPersentation') {
        this.GetJournalbyCategory(this.searchText, categoryType, this.service.JournalPageNo, this.RecordperPage);
        this.CountInfoCenterList(this.searchText, 6, categoryType);
      }
      else {
        this.GetJournalSearchList(this.searchText, this.service.JournalPageNo, this.RecordperPage);
        this.CountInfoCenterList(this.searchText, 2, "");
      }
    }
    else if (this.searchType === "document") {
      if (categoryType === 'BestPractice' || categoryType === 'LessionLearnd' || categoryType === 'News' || categoryType === 'Tutorial' || categoryType === 'ToolBoxTalk' || categoryType === 'Innovation' || categoryType === 'ReacherchDeveloment' || categoryType === 'Exhibition' || categoryType === 'ProductPersentation') {
        this.GetDocumentbyCategory(this.searchText, categoryType, this.service.documentPageNo, this.RecordperPage);
        this.CountInfoCenterList(this.searchText, 7, categoryType);
      }
      else {
        this.GetdocumentearchList(this.searchText, this.service.documentPageNo, this.RecordperPage);
        this.CountInfoCenterList(this.searchText, 3, "");
      }
    }
    else if (this.searchType === "video") {
      if (categoryType === 'BestPractice' || categoryType === 'LessionLearnd' || categoryType === 'News' || categoryType === 'Tutorial' || categoryType === 'ToolBoxTalk' || categoryType === 'Innovation' || categoryType === 'ReacherchDeveloment' || categoryType === 'Exhibition' || categoryType === 'ProductPersentation') {
        this.GetVideobyCategory(this.searchText, categoryType, this.service.videoPageNo, this.RecordperPage)
        this.CountInfoCenterList(this.searchText, 8, categoryType);
      }
      else {
        this.GetvideoearchList(this.searchText, this.service.videoPageNo, this.RecordperPage);
        this.CountInfoCenterList(this.searchText, 4, "");
      }

    }
  }

  GetSearchList(productName, PageNo, recordPerPage) {

    this.service.GeInfocenterSearchList(this.searchText, PageNo, this.RecordperPage).subscribe(
      (res: any) => {
        debugger;
        this.service.infoCenterList = [];
        if (res[0].document1SearchModel.length > 0) {
          for (let index = 0; index < res[0].document1SearchModel.length; index++) {
            let desc = res[0].document1SearchModel[index].description;
            if (desc != null || desc != undefined || desc != "") {
              desc = res[0].document1SearchModel[index].description.charAt(0).toUpperCase() + res[0].document1SearchModel[index].description.slice(1);
            }
            this.service.infoCenterList.push({
              id: res[0].document1SearchModel[index].rowid,
              title: res[0].document1SearchModel[index].title,
              description: desc,
              type: res[0].document1SearchModel[index].type,
              uploadVideo: res[0].document1SearchModel[index].uploadVideo,
              createdBy: res[0].document1SearchModel[index].createdBy,
              reviewVideo: res[0].document1SearchModel[index].reviewVideo,
              createdDate: res[0].document1SearchModel[index].createdDate,
              companyName: res[0].document1SearchModel[index].companyName,
            })
          }
        }
        else {
          this.spinner.hide();
        }

      },
      err => {
        if (err.status == 400)

          //this.toastr.error('Incorrect username or password.', 'Authentication failed.');
          alert('error');
        else
          console.log(err);
      }
    );
  }
  GetJournalSearchList(productName, PageNo, recordPerPage) {

    this.service.GeJournalearchList(this.searchText, PageNo, this.RecordperPage).subscribe(
      (res: any) => {
        if (res[0].infoCenterList.length > 0) {
          this.service.infoCenterList = [];
          for (let index = 0; index < res[0].infoCenterList.length; index++) {
            debugger
            let desc = res[0].infoCenterList[index].description;
            if (desc != null || desc != undefined || desc != "") {
              desc = res[0].infoCenterList[index].description.charAt(0).toUpperCase() + res[0].infoCenterList[index].description.slice(1);
            }
            this.service.infoCenterList.push({
              id: res[0].infoCenterList[index].id,
              title: res[0].infoCenterList[index].title,
              description: desc,
              type: res[0].infoCenterList[index].type,
              uploadVideo: res[0].infoCenterList[index].uploadVideo,
              createdBy: res[0].infoCenterList[index].createdBy,
              companyName: res[0].infoCenterList[index].companyName
            })
          }
        }
        else {
          this.spinner.hide();
        }
      },
      err => {
        if (err.status == 400)

          //this.toastr.error('Incorrect username or password.', 'Authentication failed.');
          alert('error');
        else
          console.log(err);
      }
    );
  }
  GetdocumentearchList(productName, PageNo, recordPerPage) {

    this.service.GetdocumentearchList(this.searchText, PageNo, this.RecordperPage).subscribe(
      (res: any) => {
        if (res[0].infoCenterList.length > 0) {
          this.service.infoCenterList = [];
          for (let index = 0; index < res[0].infoCenterList.length; index++) {
            let desc = res[0].infoCenterList[index].description;
            if (desc != null || desc != undefined || desc != "") {
              desc = res[0].infoCenterList[index].description.charAt(0).toUpperCase() + res[0].infoCenterList[index].description.slice(1);
            }
            this.service.infoCenterList.push({
              id: res[0].infoCenterList[index].id,
              title: res[0].infoCenterList[index].title,
              description: desc,
              type: res[0].infoCenterList[index].type,
              uploadVideo: res[0].infoCenterList[index].uploadVideo,
              createdBy: res[0].infoCenterList[index].createdBy,
              companyName: res[0].infoCenterList[index].companyName,
            })
          }
        }
        else {
          this.spinner.hide();
        }
      },
      err => {
        if (err.status == 400)

          //this.toastr.error('Incorrect username or password.', 'Authentication failed.');
          alert('error');
        else
          console.log(err);
      }
    );
  }
  GetvideoearchList(productName, PageNo, recordPerPage) {

    this.service.GetvideoearchList(this.searchText, PageNo, this.RecordperPage).subscribe(
      (res: any) => {

        if (res[0].infoCenterList.length > 0) {
          this.service.infoCenterList = [];
          for (let index = 0; index < res[0].infoCenterList.length; index++) {
            let desc = res[0].infoCenterList[index].description;
            if (desc != null || desc != undefined || desc != "") {
              desc = res[0].infoCenterList[index].description.charAt(0).toUpperCase() + res[0].infoCenterList[index].description.slice(1);
            }
            this.service.infoCenterList.push({
              id: res[0].infoCenterList[index].id,
              title: res[0].infoCenterList[index].title,
              description: desc,
              uploadVideo: res[0].infoCenterList[index].uploadVideo,
              type: res[0].infoCenterList[index].type,
              createdBy: res[0].infoCenterList[index].createdBy,
              reviewVideo: res[0].infoCenterList[index].reviewVideo,
              createdDate: res[0].infoCenterList[index].createdDate,
              companyName: res[0].infoCenterList[index].companyName,
            })
          }
        }
        else {
          this.spinner.hide();
        }
      },
      err => {
        if (err.status == 400)

          //this.toastr.error('Incorrect username or password.', 'Authentication failed.');
          alert('error');
        else
          console.log(err);
      }
    );
  }
  RedirectDexctionPage(type, id, vendorId) {

    if (type == 'Journal') {
      localStorage.setItem('journalId', id);
      localStorage.setItem('VendorId', vendorId);
      this._router.navigateByUrl('/journal-description');
    }
    else if (type == 'Video') {
      localStorage.setItem('VideoId', id);
      localStorage.setItem('VendorId', vendorId);
      this._router.navigateByUrl('/video-description');
    }
    else {
      localStorage.setItem('VideoId', id);
      localStorage.setItem('VendorId', vendorId);
      this._router.navigateByUrl('/video-description');
    }
  }


  GetAllSearchbyCategory(productName, categoryType, PageNo, recordPerPage) {

    this.service.SearchByCategory(this.searchText, categoryType, this.service.AllPageNo, this.RecordperPage).subscribe(
      (res: any) => {
        if (res[0].infoCenterList.length > 0) {
          this.service.infoCenterList = [];
          for (let index = 0; index < res[0].infoCenterList.length; index++) {
            let desc = res[0].infoCenterList[index].description.charAt(0).toUpperCase() + res[0].infoCenterList[index].description.slice(1);
            this.service.infoCenterList.push({
              id: res[0].infoCenterList[index].id,
              title: res[0].infoCenterList[index].title,
              description: desc,
              type: res[0].infoCenterList[index].type,
              uploadVideo: res[0].infoCenterList[index].uploadVideo,
              createdBy: res[0].infoCenterList[index].createdBy,
              reviewVideo: res[0].infoCenterList[index].reviewVideo,
              createdDate: res[0].infoCenterList[index].createdDate,
              companyName: res[0].infoCenterList[index].companyName
            })
          }
        }
        else {
          this.spinner.hide();
        }
      },
      err => {
        if (err.status == 400)

          //this.toastr.error('Incorrect username or password.', 'Authentication failed.');
          alert('error');
        else
          console.log(err);
      }
    );
  }
  GetJournalbyCategory(productName, categoryType, PageNo, recordPerPage) {

    this.service.SearchJournalByCategory(this.searchText, categoryType, PageNo, recordPerPage).subscribe(
      (res: any) => {
        if (res[0].infoCenterList.length > 0) {
          this.service.infoCenterList = [];
          for (let index = 0; index < res[0].infoCenterList.length; index++) {
            let desc = res[0].infoCenterList[index].description;
            if (desc != null || desc != undefined || desc != "") {
              desc = res[0].infoCenterList[index].description.charAt(0).toUpperCase() + res[0].infoCenterList[index].description.slice(1);
            }
            this.service.infoCenterList.push({
              id: res[0].infoCenterList[index].id,
              title: res[0].infoCenterList[index].title,
              description: desc,
              type: res[0].infoCenterList[index].type,
              uploadVideo: res[0].infoCenterList[index].uploadVideo,
              createdBy: res[0].infoCenterList[index].createdBy,
              reviewVideo: 0,
              createdDate: ""
            })
          }
        }
        else {
          this.spinner.hide();
        }
      },
      err => {
        if (err.status == 400)

          //this.toastr.error('Incorrect username or password.', 'Authentication failed.');
          alert('error');
        else
          console.log(err);
      }
    );
  }

  GetDocumentbyCategory(productName, categoryType, PageNo, recordPerPage) {

    this.service.DocumentByCategory(this.searchText, categoryType, PageNo, recordPerPage).subscribe(
      (res: any) => {
        if (res[0].infoCenterList.length > 0) {
          this.service.infoCenterList = [];
          for (let index = 0; index < res[0].infoCenterList.length; index++) {
            let desc = res[0].infoCenterList[index].description;
            if (desc != null || desc != undefined || desc != "") {
              desc = res[0].infoCenterList[index].description.charAt(0).toUpperCase() + res[0].infoCenterList[index].description.slice(1);
            }
            this.service.infoCenterList.push({
              id: res[0].infoCenterList[index].id,
              title: res[0].infoCenterList[index].title,
              description: desc,
              type: res[0].infoCenterList[index].type,
              uploadVideo: res[0].infoCenterList[index].uploadVideo,
              createdBy: res[0].infoCenterList[index].createdBy,
              reviewVideo: 0,
              createdDate: "",
              companyName: res[0].infoCenterList[index].companyName
            })
          }
        }
        else {
          this.spinner.hide();
        }
      },
      err => {
        if (err.status == 400)

          //this.toastr.error('Incorrect username or password.', 'Authentication failed.');
          alert('error');
        else
          console.log(err);
      }
    );
  }


  GetVideobyCategory(productName, categoryType, PageNo, recordPerPage) {
    this.service.VideoyCategory(this.searchText, categoryType, PageNo, recordPerPage).subscribe(
      (res: any) => {

        if (res[0].infoCenterList.length > 0) {
          this.service.infoCenterList = [];
          for (let index = 0; index < res[0].infoCenterList.length; index++) {
            let desc = res[0].infoCenterList[index].description
            if (desc != null || desc != undefined || desc != "") {
              desc = res[0].infoCenterList[index].description.charAt(0).toUpperCase() + res[0].infoCenterList[index].description.slice(1);
            }
            this.service.infoCenterList.push({
              id: res[0].infoCenterList[index].id,
              title: res[0].infoCenterList[index].title,
              description: desc,
              type: res[0].infoCenterList[index].type,
              uploadVideo: res[0].infoCenterList[index].uploadVideo,
              createdBy: res[0].infoCenterList[index].createdBy,
              reviewVideo: res[0].infoCenterList[index].reviewVideo,
              createdDate: res[0].infoCenterList[index].createdDate,
              companyName: res[0].infoCenterList[index].companyName
            })
          }
        }

      },
      err => {
        if (err.status == 400)

          //this.toastr.error('Incorrect username or password.', 'Authentication failed.');
          alert('error');
        else
          console.log(err);
      }
    );
  }
  RedirectUrlTOViewer(Id, vendorId) {

    localStorage.setItem('documentId', Id);
    localStorage.setItem('VendorId', vendorId);
    this._router.navigateByUrl('/document-pdf');
  }
  countAll = 0;
  CountInfoCenterList(searchtext, type, category) {
    this.service.countinfocenter(searchtext, type, category).subscribe(
      (res: any) => {

        this.service.countAll = res[0].totalCount[0].totalCount;
        this.setPage(1);
      },
      err => {
        if (err.status == 400)

          //this.toastr.error('Incorrect username or password.', 'Authentication failed.');
          alert('error');
        else
          console.log(err);
      }
    );
  }

  // Custom Paging


  setPage(pageNumber: number) {
    debugger;

    this.service.pager = this.pagerService.getPager(this.service.countAll, pageNumber);

    let searchType = localStorage.getItem('SearchType');
    let categoryType = localStorage.getItem('CategoryType');
    if (searchType === "All") {
      this.service.AllPageNo = Number(this.service.AllPageNo) + 1;
      if (categoryType === 'BestPractice' || categoryType === 'LessionLearnd' || categoryType === 'News' || categoryType === 'Tutorial' || categoryType === 'ToolBoxTalk' || categoryType === 'Innovation' || categoryType === 'ReacherchDeveloment' || categoryType === 'Exhibition' || categoryType === 'ProductPersentation') {
        this.GetAllSearchbyCategory(this.searchText, categoryType, pageNumber, this.RecordperPage);
        this.gotoTop();
      }
      else {
        this.GetSearchList(this.searchText, pageNumber, this.RecordperPage);
        this.gotoTop();
      }
    }
    else if (searchType === "Journal") {
      this.service.JournalPageNo = Number(this.service.JournalPageNo) + 1;
      if (categoryType === 'BestPractice' || categoryType === 'LessionLearnd' || categoryType === 'News' || categoryType === 'Tutorial' || categoryType === 'ToolBoxTalk' || categoryType === 'Innovation' || categoryType === 'ReacherchDeveloment' || categoryType === 'Exhibition' || categoryType === 'ProductPersentation') {
        this.GetJournalbyCategory(this.searchText, categoryType, pageNumber, this.RecordperPage);
        this.gotoTop();
      }
      else {
        this.GetJournalSearchList(this.searchText, pageNumber, this.RecordperPage);
        this.gotoTop();

      }
    }
    else if (searchType === "document") {
      this.service.documentPageNo = Number(this.service.documentPageNo) + 1;
      if (categoryType === 'BestPractice' || categoryType === 'LessionLearnd' || categoryType === 'News' || categoryType === 'Tutorial' || categoryType === 'ToolBoxTalk' || categoryType === 'Innovation' || categoryType === 'ReacherchDeveloment' || categoryType === 'Exhibition' || categoryType === 'ProductPersentation') {
        this.GetDocumentbyCategory(this.searchText, categoryType, pageNumber, this.RecordperPage);
        this.gotoTop();
      }
      else {
        this.GetdocumentearchList(this.searchText, pageNumber, this.RecordperPage);
        this.gotoTop();
      }
    }
    else if (searchType === "video") {

      this.service.videoPageNo = Number(this.service.videoPageNo) + 1;
      if (categoryType === 'BestPractice' || categoryType === 'LessionLearnd' || categoryType === 'News' || categoryType === 'Tutorial' || categoryType === 'ToolBoxTalk' || categoryType === 'Innovation' || categoryType === 'ReacherchDeveloment' || categoryType === 'Exhibition' || categoryType === 'ProductPersentation') {
        this.GetVideobyCategory(this.searchText, categoryType, pageNumber, this.RecordperPage)
        this.gotoTop();
      }
      else {
        this.GetvideoearchList(this.searchText, pageNumber, this.RecordperPage);
        this.gotoTop();
      }
    }

  }
  gotoTop() {
    window.scroll({
      top: 0,
      left: 0,
      //behavior: 'smooth' 
    });
  }
}
