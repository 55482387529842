import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from 'rxjs';
import { AppSettings } from '../../../shared/AppSettings'




@Injectable({
    providedIn: 'root'
  })
  
  export class changepasswordservice {
  
    
    constructor(private http: HttpClient) { }



    changepassword(formData) {
     
        return this.http.post(AppSettings.API_ENDPOINT + '/Account/ChangePssword', formData);
      }

  }