import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from '../../shared/common.service';


@Component({
  selector: 'app-account-selection-vendor-rgistration',
  templateUrl: './account-selection-vendor-rgistration.component.html',
  styleUrls: ['./account-selection-vendor-rgistration.component.css']
})
export class AccountSelectionVendorRgistrationComponent implements OnInit {

  constructor(private formBuilder: FormBuilder, private _activatroute: ActivatedRoute, private service: CommonService, private _router: Router) { }
  vendorId = "";
  loginForm: FormGroup;
  ngOnInit() {
    debugger;

    this.vendorId = this._activatroute.snapshot.params['VedorId'];

    // this.loginForm = this.formBuilder.group({

    //   password: ['', Validators.required],
    //   angainPassword: ['', Validators.required],
    //   memberCode: ['', Validators.required],

    // });

    this.loginForm = this.formBuilder.group({
      password: ['', Validators.required],
      angainPassword: ['', Validators.required]
    },

      {
        validator: MustMatch('password', 'angainPassword')
      });
  }
  message = "Password Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters.";
  onSubmit() {
    debugger;

    var body = {

      Id: this.vendorId,
      Password: this.loginForm.value.password,
      Role: 'Vendor'
    };

    this.service.AddVendorPassword(body).subscribe(
      (res: any) => {
        debugger;
        if (res.status) {
          let userId = res.userId;
          localStorage.setItem('token', res.token);
          localStorage.setItem('userId', res.userId);
          localStorage.setItem('UserMappingId', res.userMappingId);
          localStorage.setItem("role", 'Vendor');
          localStorage.setItem("companyName",res.compnayName);
          alert("Success");
          this._router.navigateByUrl('vendor/dashboard');
        }
        else {
          this.message = res.message;
        }
      },
      err => {
        debugger
        if (err.status == 400)

          //this.toastr.error('Incorrect username or password.', 'Authentication failed.');
          alert('error');
        else
          console.log(err);
      }
    );
  }
}



export function MustMatch(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    if (matchingControl.errors && !matchingControl.errors.mustMatch) {
      // return if another validator has already found an error on the matchingControl
      return;
    }

    // set error on matchingControl if validation fails
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ mustMatch: true });
    } else {
      matchingControl.setErrors(null);
    }
  }
}
