import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AppSettings } from '../../../shared/AppSettings'
import { Observable } from 'rxjs';
import { Messagemodle } from '../../inner/message/messagemodle';

@Injectable({
  providedIn: 'root'
})

export class UserService {

  AuthorList = []; name: string; menuType: string;
  validUser = false; username: string;
  constructor(private http: HttpClient) { }
  // readonly BaseURI = 'http://localhost:64320/api';  

  login(formData) {

    return this.http.post(AppSettings.API_ENDPOINT + '/Account/Login', formData);
  }

  colorFn(colorName, categoryName, PageNo, RecordsPerPage) {
    return this.http.get(AppSettings.API_ENDPOINT + '/HD_Wallpaper/hd-wallpaper-color-image?colorName=' + colorName + "&CategoryName=" + categoryName + "&PageNo=" + PageNo + "&RecordsperPage=" + RecordsPerPage);
  }
  GetAuthorById(id: number): Observable<any> {

    return this.http.get<any>(AppSettings.API_ENDPOINT + '/Author/GetAuthor?id=' + id);
  }
  GetuserById(id:number) : Observable<any> {
     
    return this.http.get<any>(AppSettings.API_ENDPOINT + '/UserProfile/GetUser?id='+ id);
  }
  Edit(formData) {
      
    return this.http.post(AppSettings.API_ENDPOINT + '/UserProfile/Edit', formData);
  }
  GetAllCountry() : Observable<any> {
     
    return this.http.get<any>(AppSettings.API_ENDPOINT + '/UserProfile/countryList');
  }
  ActivateReedem(formData) {

    return this.http.post(AppSettings.API_ENDPOINT + '/Author/update-reedem', formData);
  }


  Getallcontent() {
    return this.http.get(AppSettings.API_ENDPOINT + '/Content/GetContent');
  }


  forgotpassword(formData) {
    return this.http.post(AppSettings.API_ENDPOINT + '/Account/ForgotPassword', formData);
  }

  homedata() {
    return this.http.get(AppSettings.API_ENDPOINT + '/Home/Homedata');
  }

  menudata() {
    return this.http.get(AppSettings.API_ENDPOINT + '/Menu/menudata');
  }

  Authordata() {
    return this.http.get(AppSettings.API_ENDPOINT + '/Author/GetAuthorsList');
  }
  TodayQutoesdata() {
    return this.http.get(AppSettings.API_ENDPOINT + '/Quote/QuotesOfTheDay');
  }

  Topicdata() {
    return this.http.get(AppSettings.API_ENDPOINT + '/Topic/TopicList');
  }

  TopQuotedata(PageNo, RecordsPerPage) {
    return this.http.get(AppSettings.API_ENDPOINT + '/Quote/TopQuotes?PageNo=' + PageNo + "&RecordsperPage=" + RecordsPerPage);
  }

  PicturesData(pageNo, RecordsPerPage) {
    return this.http.get(AppSettings.API_ENDPOINT + '/Picture/Pictures?PageNo=' + pageNo + "&RecordsperPage=" + RecordsPerPage);
  }
  GetCategoryData() {
    return this.http.get(AppSettings.API_ENDPOINT + '/HD_Wallpaper/GetCategoryList');
  }
  GetColorData() {
    return this.http.get(AppSettings.API_ENDPOINT + '/HD_Wallpaper/GetColorList');
  }

  onUpload(formData: FormData) {

    return this.http.post(AppSettings.API_ENDPOINT + '/Author/Upload', formData, { reportProgress: true, observe: 'events' });

  }

  popularauthordata() {
    return this.http.get(AppSettings.API_ENDPOINT + '/Home/popularAuthor');
  }
  Inthenewadata() {
    return this.http.get(AppSettings.API_ENDPOINT + '/Home/InTheNews');
  }

  AuthorPicturesData() {
    return this.http.get(AppSettings.API_ENDPOINT + '/Author/AuthorsBirthdayImageList');
  }

  GetBlogList() {
    return this.http.get(AppSettings.API_ENDPOINT + '/blog/BlogList');
  }

  PostOnBlog(id: number): Observable<any> {

    return this.http.get<any>(AppSettings.API_ENDPOINT + '/Blog/blogpostsById?blogId=' + id);
  }

  AddPostblog(formData) {

    return this.http.post(AppSettings.API_ENDPOINT + '/blog/AddPost', formData);
  }

  AuthorListdata(value: any): Observable<any> {
    return this.http.get(AppSettings.API_ENDPOINT + '/Author/GetAuthorList?value=' + value);
  }
  searchListdata(value: any): Observable<any> {
    return this.http.get(AppSettings.API_ENDPOINT + '/Home/Homesearch?item=' + value);
  }

  TopicListdata(value: any, value1: any, PageNo: any, RecordsPerPage: any): Observable<any> {
    return this.http.get(AppSettings.API_ENDPOINT + '/Topic/TopicById?Id=' + value + '&Topic=' + value1 + '&PageNo=' + PageNo + '&perPage=' + RecordsPerPage);
  }
  AuthorsListdata(value: any, value1: any, PageNo: any, RecordsPerPage: any): Observable<any> {
    return this.http.get(AppSettings.API_ENDPOINT + '/Author/AuthorById?Id=' + value + '&Author=' + value1 + '&PageNo=' + PageNo + '&perPage=' + RecordsPerPage);
  }
  MenuesListdata(value: any, value1: any, PageNo: any, RecordsPerPage: any): Observable<any> {
    return this.http.get(AppSettings.API_ENDPOINT + '/Menu/MenuById?Id=' + value + '&Menu=' + value1 + '&PageNo=' + PageNo + '&PerPage=' + RecordsPerPage);
  }
  getAuhtorList(name) {
    this.AuthorListdata(name).subscribe((res) => {
      this.name = name;
      this.AuthorList = res[0].authorsLists;

    }

    )
  }
  getHdwalPaper(Id, CallBy) {
    return this.http.get(AppSettings.API_ENDPOINT + '/Home/hd-walpaper?Id=' + Id + '&callBy=' + CallBy);
  }
  AddHdwallpaper(formData) {
    return this.http.post(AppSettings.API_ENDPOINT + '/HD_Wallpaper/Add', formData);
  }

  bornAuthordata(bornDate) {
    return this.http.get(AppSettings.API_ENDPOINT + '/Author/born-author?dates=' + bornDate);
  }

  userlogin(formData) {

    return this.http.post(AppSettings.API_ENDPOINT + '/Account/Login', formData);
  }



  userfogotpassword(formData) {
    return this.http.post(AppSettings.API_ENDPOINT + '/Account/ForgotPassword', formData);
  }
  userregister(formData) {
    return this.http.post(AppSettings.API_ENDPOINT + '/Account/Register', formData);
  }
  GetCategory() {
    return this.http.get(AppSettings.API_ENDPOINT + '/HD_Wallpaper/get-categories');

  }
  GetSearchLists() {
    return this.http.get(AppSettings.API_ENDPOINT + '/HD_Wallpaper/GetSearchList');

  }
  getImageCategoryWise(categoryName, PageNo, RecordsPerPage) {
    return this.http.get(AppSettings.API_ENDPOINT + '/HD_Wallpaper/category-image?categoryName=' + categoryName + "&PageNo=" + PageNo + "&RecordsperPage=" + RecordsPerPage);

  }

  countUser() {
    return this.http.get(AppSettings.API_ENDPOINT + '/UserProfile/countuser');

  }
  countVendor() {
    return this.http.get(AppSettings.API_ENDPOINT + '/VendorUser/countpendingVendor');

  }
  countActiveVendor() {
    return this.http.get(AppSettings.API_ENDPOINT + '/VendorUser/countactiveVendor');

  }
  countBlog(role, vendorId) {
    return this.http.get(AppSettings.API_ENDPOINT + '/Product/productCount?role=' + role + "&vendorId=" + vendorId);

  }
  counTopic() {
    return this.http.get(AppSettings.API_ENDPOINT + '/Product/productFeature');

  }
  getQuoteTags(Id: any, type: any): Observable<any> {
    return this.http.get(AppSettings.API_ENDPOINT + '/Quote/get-quote-tags?quoteId=' + Id + "&types=" + type);
  }

  getQuoteTagswaise(quoteTage: any, type: any): Observable<any> {
    return this.http.get(AppSettings.API_ENDPOINT + '/Author/get-author-data-quote-wise?quoteName=' + quoteTage + "&Type=" + type);
  }
  AuthorsListdata1(value: any, value1: any): Observable<any> {
    return this.http.get(AppSettings.API_ENDPOINT + '/Author/AuthorById?Id=' + value + '&Author=' + value1);
  }
  getSingleQuoteData(): Observable<any> {
    return this.http.get(AppSettings.API_ENDPOINT + '/Home/get-single-quotedate');
  }
  getQuoteAllList() {
    return this.http.get(AppSettings.API_ENDPOINT + '/Topic/get-quote-all-list');
  }

  GetTagsData() {
    return this.http.get(AppSettings.API_ENDPOINT + '/Quote/GetTags');
  }


  getIdByName(value: any): Observable<any> {
    return this.http.get(AppSettings.API_ENDPOINT + '/Home/GetIdByName?item=' + value);
  }


  countStar(formData) {

    return this.http.post(AppSettings.API_ENDPOINT + '/HD_Wallpaper/count-star', formData);
  }
  GetImageList() {
    return this.http.get(AppSettings.API_ENDPOINT + '/HD_Wallpaper/get-image');
  }
  GetMessageAll(vendorId,role): Observable<Messagemodle[]> {

    return this.http.get<any[]>(AppSettings.API_ENDPOINT + '/ApplyVendor/request-list?Vendorid=' + vendorId + "&Role=" + role);
  }
  GetProductMessageAll(vendorId,role): Observable<Messagemodle[]> {

    return this.http.get<any[]>(AppSettings.API_ENDPOINT + '/ApplyVendor/request-list-question?Vendorid=' + vendorId + "&Role=" + role);
  }
  GetStateById(id: number): Observable<any> {

    return this.http.get<any>(AppSettings.API_ENDPOINT + '/Job/get-state-country?countryId=' + id);
  }
  GetCityById(id: number): Observable<any> {

    return this.http.get<any>(AppSettings.API_ENDPOINT + '/Job/get-city?stateId=' + id);
  }
}

