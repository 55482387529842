import { Component, OnInit, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { CommonService } from '../../shared/common.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { PagerService } from '../../shared/pager.service';
@Component({
  selector: 'app-search-all',
  templateUrl: './search-all.component.html',
  styleUrls: ['./search-all.component.css']
})
export class SearchAllComponent implements OnInit {

  constructor(public service: CommonService, private spinner: NgxSpinnerService, private _router: Router, public pagerService: PagerService) { }
  productName: string; notscrolly = true; notEmptyPost = true;
  productList = [];
  PageNo = 1;
  RecordperPage = 10;
  boundaryLinks = false;
  directionLinks = false;
  ngOnInit() {
    this.boundaryLinks = true;
    this.directionLinks = true;
    this.spinner.show();

    this.spaceclose = 'none';
    this.productName = localStorage.getItem('productName');
    if (this.productName === undefined || this.productName === "undefined") {
      this.productName = "";
      localStorage.setItem('productName', '');
    }
    this.GetAllCountSearchList(this.productName);
    this.GetAllSearchList(this.productName, this.PageNo, this.RecordperPage);
  }

  RedirectUrlTOViewer(Id, vendorId) {

    localStorage.setItem('documentId', Id);
    localStorage.setItem('VendorId', vendorId);
    this._router.navigateByUrl('/document-pdf');
  }
  GetAllSearchList(productName, PageNo, recordPerPage) {

    this.service.GetAllSearchAllSearchList(productName, PageNo, this.RecordperPage).subscribe(
      (res: any) => {
        debugger;
        this.productList = [];
        if (res[0].searchList.length > 0) {
          for (let index = 0; index < res[0].searchList.length; index++) {
            let desc = res[0].searchList[index].description;
            if (desc === null || desc === undefined || desc === "") {
              let desc = res[0].searchList[index].description;
            }
            else {
              desc = res[0].searchList[index].description.charAt(0).toUpperCase() + res[0].searchList[index].description.slice(1);
            }
            this.productList.push({
              id: res[0].searchList[index].rowid,
              title: res[0].searchList[index].title
              , description: desc,
              image: res[0].searchList[index].image
              , type: res[0].searchList[index].type,
              createdBy: res[0].searchList[index].createdBy,
              thubmbNail: res[0].searchList[index].thubmbNail + '.jpg',
              companyName: res[0].searchList[index].companyName,
              reviewVideo: res[0].searchList[index].reviewVideo,
              createdDate: res[0].searchList[index].createdDate,
            })
          }

          this.spinner.hide();
        }


        else {
          this.spinner.hide();
        }


      },
      err => {
        if (err.status == 400)

          //this.toastr.error('Incorrect username or password.', 'Authentication failed.');
          alert('error');
        else
          console.log(err);
      }
    );
  }
  countAll = 0;

  GetAllCountSearchList(productName) {
    this.service.CountAll(this.productName).subscribe(
      (res: any) => {

        this.countAll = res[0].totalCount[0].totalCount;
        this.setPage(1);


      },
      err => {
        if (err.status == 400)

          //this.toastr.error('Incorrect username or password.', 'Authentication failed.');
          alert('error');
        else
          console.log(err);
      }
    );
  }
  showspace = ''; spacepreview = ''; spaceclose = '';
  currentId = 0;
  spacereviewList = [];
  showPreview(id) {
    this.service.GetSpaceReviewByProductId(id).subscribe(
      (res: any) => {
        this.spacereviewList = res
        this.showspace = 'block';
        this.spacepreview = 'none';
        this.spaceclose = 'block';
        this.currentId = id;

      },
      err => {
        if (err.status == 400)

          //this.toastr.error('Incorrect username or password.', 'Authentication failed.');
          alert('error');
        else
          console.log(err);
      }
    );

  }
  closePreview(id) {
    this.showspace = 'none';
    this.spacepreview = 'block';
    this.spaceclose = 'none';
  }
  fnListingDetails(id, vendorId) {

    localStorage.setItem("ProductId", id);
    localStorage.setItem("VendorId", vendorId);
    this._router.navigate(['/product-description'])
  }
  RedirectDexctionPage(type, id) {
    if (type == 'Journal') {
      localStorage.setItem('journalId', id);
      this._router.navigateByUrl('/journal-description');
    }
    else {
      localStorage.setItem('VideoId', id);
      this._router.navigateByUrl('/video-description');
    }
  }
  fnJobDescription(id) {

    localStorage.setItem("JobId", id);
    this._router.navigate(['/job-description']);
  }
  showHid(id, vendorId) {

    localStorage.setItem("ProductId", id);
    localStorage.setItem("VendorId", vendorId);
    let role = localStorage.getItem("role");
    localStorage.setItem("url", "product-description");
    if (role == "Admin") {
      this._router.navigateByUrl('/user/login');
    }
    else if (role == "Vendor") {
      this._router.navigateByUrl('/user/login');
    }
    else if (role == "User") {
      this._router.navigateByUrl('/product-description');
    }
    else {
      this._router.navigateByUrl('/user/login');
    }

  }





  setPage(page: number) {

    this.service.pager = this.pagerService.getPager(this.countAll, page);

    this.GetAllSearchList(this.productName, page, this.RecordperPage);
    this.gotoTop();
  }
  gotoTop() {
    window.scroll({
      top: 0,
      left: 0,
      //behavior: 'smooth' 
    });
  }

}
