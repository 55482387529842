import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { changepasswordservice } from './changepassword.service';
@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})

export class ChangePasswordComponent implements OnInit {
  changepasswordForm: FormGroup;
  returnUrl: string;
  Email: string;
  constructor(private formBuilder: FormBuilder, private _router: Router, private service: changepasswordservice) { }

  ngOnInit() {
    debugger;
    this.Email = localStorage.getItem("Email");
    this.changepasswordForm = this.formBuilder.group({
      oldPassword: ['', Validators.required],
      newPassword: ['', Validators.required],
      confirmPassword: ['', Validators.required]

    },
      {
        validator: MustMatch('newPassword', 'confirmPassword')
      });

  }
  onSubmit() {
    debugger;
    var body = {

      OldPassword: this.changepasswordForm.value.oldPassword,
      NewPassword: this.changepasswordForm.value.newPassword,
      Email: this.Email

    };

    this.service.changepassword(body).subscribe(
      (res: any) => {
        if (res.status == true) {

          alert('your password has been change');
          this._router.navigateByUrl('admin/login');
        }
        else {
          alert("invalid user");
        }
      },
      err => {
        if (err.status == 400)

          //this.toastr.error('Incorrect username or password.', 'Authentication failed.');
          alert('error');
        else
          console.log(err);
      }
    );
  }


}
export function MustMatch(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    if (matchingControl.errors && !matchingControl.errors.mustMatch) {
      // return if another validator has already found an error on the matchingControl
      return;
    }

    // set error on matchingControl if validation fails
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ mustMatch: true });
    } else {
      matchingControl.setErrors(null);
    }
  }
}