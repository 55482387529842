import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MessageService } from '../../admin/inner/message/message.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-video-request-details',
  templateUrl: './video-request-details.component.html',
  styleUrls: ['./video-request-details.component.css']
})
export class VideoRequestDetailsComponent implements OnInit {
  id = 0; videoTitle = ""; videoDescription = ""; companyName=""; Status="";
  AddForm: FormGroup; diplayLogo = false; Name = ""; Email = ""; MobileNo =""; Address="";
  RequestEmail = ""; RequestDesc = "";
  constructor(private service: MessageService,
    private formBuilder: FormBuilder,
    private _router: Router,
    private _activatroute: ActivatedRoute, private spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.id = +this._activatroute.snapshot.params['id'];
    this.GetVideoList();
    this.AddForm = this.formBuilder.group({

      status: ['', Validators.required],
     
    });
  }
  GetVideoList() {
    this.service.GetVideoById(this.id).subscribe(
      (res: any) => {
        debugger;
        this.videoTitle=res.videoTitle;
        this.videoDescription=res.videoDescription;
        this.companyName=res.companyName;
        this.Status=res.status;
        this.Name=res.name;
        this.Email=res.email;
        this.MobileNo=res.mobileNo;
        this.Address =res.address; 
        this.RequestEmail = res.requestEmail
        this.RequestDesc = res.requestDescription
        this.AddForm.patchValue(res);
      },
      err => {

        if (err.status == 400)
          //this.toastr.error('Incorrect username or password.', 'Authentication failed.');
          alert('error');
        else
          console.log(err);

        //this.reset();
      }
    );
  }
  onSubmit()
  {
    
  }
  goback() {
    /// window.location.href="/author/list";
    this._router.navigateByUrl('/user/request');
  }
}
