import { NgModule } from '@angular/core';
import { AuthGuard } from './auth.guard';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './admin/inner/dashboard/dashboard.component';

import { HomeComponent } from './frontend/home/home.component';

import { P404Component } from './error/404.component';
import { P500Component } from './error/500.component';
import { LoginComponent } from './admin/auth/login/login.component';
import { ForgotpasswordComponent } from './admin/auth/forgotpassword/forgotpassword.component';

import { LandingComponent } from './admin/inner/landing/landing.component';
import { ChangePasswordComponent } from './admin/inner/change-password/change-password.component';




import { UserforgotpasswordComponent } from './frontend/userforgotpassword/userforgotpassword.component';

import { UserregisterComponent } from './frontend/userregister/userregister.component';
import { UserprofileComponent } from './frontend/userprofile/userprofile.component';
import { UserloginComponent } from './frontend/userlogin/userlogin.component';

import { from } from 'rxjs';
import { FrontendMasterComponent } from './frontend/frontend-master/frontend-master.component';
import { AccountselectionregistrationComponent } from './frontend/accountselectionregistration/accountselectionregistration.component';
import { FrontentHomeMasterComponent } from './frontend/frontent-home-master/frontent-home-master.component';
import { HomeListMasterComponent } from './frontend/home-list-master/home-list-master.component';
import { ProductListingComponent } from './frontend/product-listing/product-listing.component';
import { InfoCenterHomeMasterComponent } from './frontend/info-center-home-master/info-center-home-master.component';
import { HomeInfoCenterListComponent } from './frontend/home-info-center-list/home-info-center-list.component';
import { JournalListComponent } from './frontend/journal-list/journal-list.component';
import { VendorRegistrationComponent } from './frontend/vendor-registration/vendor-registration.component';
import { ThankyouComponent } from './frontend/thankyou/thankyou.component';
import { AccountselectionregComponent } from './frontend/accountselectionregnew/accountselectionreg.component';
import { PaymentPageComponent } from './frontend/payment-page/payment-page.component';
import { AccountSelectionVendorRgistrationComponent } from './frontend/account-selection-vendor-rgistration/account-selection-vendor-rgistration.component';
import { ThankyouUserregisterComponent } from './frontend/thankyou-userregister/thankyou-userregister.component';
import { SearchAllComponent } from './frontend/search-all/search-all.component';
import { SearchAllMasterComponent } from './frontend/search-all-master/search-all-master.component';
import { JobListComponent } from './frontend/job-list/job-list.component';
import { JobListMasterComponent } from './frontend/job-list-master/job-list-master.component';
import { JobDescriptionComponent } from './frontend/job-description/job-description.component';
import { ProductlistingdetailsComponent } from './frontend/productlistingdetails/productlistingdetails.component';
import { JournalDescriptionComponent } from './frontend/journal-description/journal-description.component';
import { VideoDescriptionComponent } from './frontend/video-description/video-description.component';
import { VideoCategoryComponent } from './frontend/video-category/video-category.component';
import { DocumentViewerComponent } from './frontend/document-viewer/document-viewer.component';
import { InfoCnterMasterDescriptionComponent } from './frontend/info-cnter-master-description/info-cnter-master-description.component';
import { TermsCondtionComponent } from './frontend/terms-condtion/terms-condtion.component';
import { PrivacyComponent } from './frontend/privacy/privacy.component';
import { SubscriptionThankyouComponent } from './frontend/subscription-thankyou/subscription-thankyou.component';
import { UserChangePasswordComponent } from './frontend/user-change-password/user-change-password.component';
import { UserRequestComponent } from './frontend/user-request/user-request.component';
import { OdcumentRequestDetailsComponent } from './frontend/odcument-request-details/odcument-request-details.component';
import { VideoRequestDetailsComponent } from './frontend/video-request-details/video-request-details.component';
import { ProductRequestDetailsComponent } from './frontend/product-request-details/product-request-details.component';
import { JobRequestDetailsComponent } from './job-request-details/job-request-details.component';
import { AboutUsComponent } from './frontend/about-us/about-us.component';
import { ImpressumComponent } from './frontend/impressum/impressum.component';
export const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: '',
    redirectTo: 'home',
    //component: DashboardComponent,
    pathMatch: 'full',

  },
  {
    path: 'admin',
    redirectTo: 'admin/dashboard',
    //component: DashboardComponent,
    pathMatch: 'full',

  },
  {
    path: '404',
    component: P404Component,
    data: {
      title: 'Page 404'
    }
  },
  {
    path: '500',
    component: P500Component,
    data: {
      title: 'Page 500'
    }
  },
  {
    path: 'admin/login',
    component: LoginComponent,
    data: {
      title: 'Login'
    }
  },
  {
    path: 'Payment',
    component: PaymentPageComponent,
    data: {
      title: 'Payment'
    }
  },

  {
    path: 'admin/forgotpassword',
    component: ForgotpasswordComponent,
    data: {
      title: 'ForgotPassword'
    }
  },
  {
    path: '',
    component: FrontentHomeMasterComponent,
    data: {
      title: 'Frontend'
    },


    children: [

      {
        path: 'home',

        component: HomeComponent
      },


    ]
  },
  {
    path: '',
    component: FrontendMasterComponent,
    data: {
      title: 'Frontend'
    },


    children: [

      {
        path: 'user/register',

        component: UserregisterComponent
      },

      {
        path: 'vendorregister',
        component: VendorRegistrationComponent,
      },
      {
        path: 'user/forgotpassword',
        component: UserforgotpasswordComponent,
      },
      {
        path: 'user/change-password',
        component: UserChangePasswordComponent,
      },

      {
        path: 'user/login',
        component: UserloginComponent,
      },

      {
        path: 'account-selection-rgistration/:userid',
        component: AccountselectionregistrationComponent,

      },
      {
        path: 'account-selection-vendor-rgistration/:VedorId',
        component: AccountSelectionVendorRgistrationComponent,

      },
      {
        path: 'Thankyou',
        component: ThankyouComponent,
      },
      {
        path: 'subscription-thank-you',
        component: SubscriptionThankyouComponent,
      },
      {
        path: 'thank-you-register',
        component: ThankyouUserregisterComponent,
      },
      {
        path: 'account-selection-registration/:id',
        component: AccountselectionregComponent
      },
      {
        path: 'userprofile',

        component: UserprofileComponent
      },
      {
        path: 'term-condtion',

        component: TermsCondtionComponent
      },
      {
        path: 'privacy',

        component: PrivacyComponent
      },
      {
        path: 'user/request',
        component: UserRequestComponent,
      },
      {
        path: 'user/request/document/:id',
        component: OdcumentRequestDetailsComponent,
      },
      {
        path: 'user/request/video/:id',
        component: VideoRequestDetailsComponent,
      },
      {
        path: 'user/request/Product/:id',
        component: ProductRequestDetailsComponent,
      },
      {
        path: 'user/request/job/:id',
        component: JobRequestDetailsComponent,
      },
      {
        path: 'aboutus',
        component: AboutUsComponent
      },
      {
        path: 'impressum',
        component: ImpressumComponent
      },
    ]
  },
  {
    path: '',
    component: HomeListMasterComponent,
    data: {
      title: 'Frontend'
    },


    children: [

      {
        path: 'productlist',

        component: ProductListingComponent
      },

      {
        path: 'product-description',

        component: ProductlistingdetailsComponent
      },


    ]
  },
  {
    path: '',
    component: SearchAllMasterComponent,
    data: {
      title: 'Frontend'
    },


    children: [



      {
        path: 'searchall',

        component: SearchAllComponent
      },


    ]
  },

  {
    path: '',
    component: JobListMasterComponent,
    data: {
      title: 'Frontend'
    },


    children: [

      {
        path: 'joblist',

        component: JobListComponent
      },

      {
        path: 'job-description',

        component: JobDescriptionComponent
      },

    ]
  },
  {
    path: '',
    component: InfoCenterHomeMasterComponent,
    data: {
      title: 'Frontend'
    },


    children: [

      {
        path: 'infocenter',

        component: HomeInfoCenterListComponent
      },
      {
        path: 'journallist',

        component: JournalListComponent
      },

    ]
  },
  {
    path: '',
    component: InfoCnterMasterDescriptionComponent,
    data: {
      title: 'Frontend'
    },


    children: [
      {
        path: 'journal-description',
        component: JournalDescriptionComponent
      },
      {
        path: 'video-description',
        component: VideoDescriptionComponent
      },
      {
        path: 'video-category',
        component: VideoCategoryComponent
      },
      {
        path: 'document-pdf',
        component: DocumentViewerComponent
      }
    ]
  },
  {
    path: '',
    component: LandingComponent,
    data: {
      // title: 'Admin'
    },
    children: [

      {
        path: 'admin/dashboard',
        loadChildren: () => import('./admin/inner/dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate: [AuthGuard],
        data: { role: 'Admin' }
      },
      {
        path: 'vendor/dashboard',
        loadChildren: () => import('./admin/inner/vendor-dashboard/vendor-dashboard.module').then(m => m.VendorDashboardModule),
        canActivate: [AuthGuard],
        // data: {role: 'Admin'}
      },
      {
        path: 'admin/user',
        loadChildren: () => import('./admin/inner/User/user.module').then(m => m.UserModule),
        canActivate: [AuthGuard],
        data: { role: 'Admin' }
      },

      {
        path: 'admin/vendor',
        loadChildren: () => import('./admin/inner/VendorUser/vendor-user.module').then(m => m.VendorUserModule),
        canActivate: [AuthGuard],
        data: { role: 'Admin' }
      },
      {
        path: 'admin/product',
        loadChildren: () => import('./admin/inner/Product/product.module').then(m => m.ProductModule),
        canActivate: [AuthGuard],
        data: { role: 'Admin' }
      },
      {
        path: 'admin/subscription',
        loadChildren: () => import('./admin/inner/subscription/subscription.module').then(m => m.SubscriptionModule),
        canActivate: [AuthGuard],
        data: { role: 'Admin' }
      },
      {
        path: 'admin/job',
        loadChildren: () => import('./admin/inner/job/job.module').then(m => m.JobModule),
        canActivate: [AuthGuard],
        data: { role: 'Admin' }
      },
      {
        path: 'admin/journal',
        loadChildren: () => import('./admin/inner/journal/journal.module').then(m => m.JournalModule),
        canActivate: [AuthGuard],
        data: { role: 'Admin' }
      },
      {
        path: 'admin/document',
        loadChildren: () => import('./admin/inner/document/document.module').then(m => m.DocumentModule),
        canActivate: [AuthGuard],
        data: { role: 'Admin' }
      },
      {
        path: 'admin/video',
        loadChildren: () => import('./admin/inner/video/video.module').then(m => m.VideoModule),
        canActivate: [AuthGuard],
        data: { role: 'Admin' }
      },
      {
        path: 'admin/content',
        loadChildren: () => import('./admin/inner/content/content.module').then(m => m.ContenteModule),
        canActivate: [AuthGuard],
        data: { role: 'Admin' }
      },
      {
        path: 'admin/request',
        loadChildren: () => import('./admin/inner/message/message.module').then(m => m.MessageModule),
        canActivate: [AuthGuard],
        data: { role: 'Admin' }
      },
      {
        path: 'admin/changepassword',
        component: ChangePasswordComponent,
        canActivate: [AuthGuard],
        data: { role: 'Admin' }
      },

    ]
  },
  { path: '**', component: P404Component, canActivate: [AuthGuard] },

];



@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
