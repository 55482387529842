import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../shared/common.service';
@Component({
  selector: 'app-subscription-thankyou',
  templateUrl: './subscription-thankyou.component.html',
  styleUrls: ['./subscription-thankyou.component.css']
})
export class SubscriptionThankyouComponent implements OnInit {

  constructor(private service: CommonService) { }

  ngOnInit() {
    var data=JSON.parse(localStorage.getItem('planData'));
          data.vendorId=Number(localStorage.getItem('VendorUserId'));;
          this.service.SenddMailOfVendorPayment(data).subscribe(
            (res1: any) => {
              debugger;
      
            }
          );
  }

}
