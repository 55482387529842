import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../shared/common.service';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.css']
})
export class AboutUsComponent implements OnInit {

  constructor(private service: CommonService,private formBuilder: FormBuilder,private _router : Router) { }
  AboutUs  = "";
  ngOnInit() {
    this.service.GetContent().subscribe(
      (res: any) => {

        this.AboutUs = res.aboutUs;

      },
      err => {

        if (err.status == 400)
          //this.toastr.error('Incorrect username or password.', 'Authentication failed.');
          alert('error');
        else
          console.log(err);

        //this.reset();
      }
    );
  }

}
