import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { deleteModal } from './deleteModal'
import { Observable } from 'rxjs';
import { AppSettings } from './AppSettings'


@Injectable({
    providedIn: 'root'
  })
  
  export class deleteservice {
  
    constructor(private http: HttpClient) { }
    
    Delete(deletedata:deleteModal) {
      debugger
      
      return this.http.get(AppSettings.API_ENDPOINT + '/'+ deletedata.type +'/Delete?id='+deletedata.id);
    }
    Deleterequest(deletedata:deleteModal) {
      debugger
      
      return this.http.get(AppSettings.API_ENDPOINT + '/'+ deletedata.type +'/Deleterequest?id='+deletedata.id);
    }
  
  }