import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../shared/common.service';
import { Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { PagerService } from '../../shared/pager.service';
@Component({
  selector: 'app-search-all-master',
  templateUrl: './search-all-master.component.html',
  styleUrls: ['./search-all-master.component.css']
})
export class SearchAllMasterComponent implements OnInit {
  productName: string;
  PageNo = 1;
  RecordperPage = 10;
  UserId = 0; role = ""; profileshowhide = false;
  name = "";
  ActiveClass = "";
  constructor(private service: CommonService, private _router: Router, private pagerService: PagerService) { }
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    // autoWidth:true,
    // items:15,
    navText: ['<', '>'],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 7
      }
    },
    nav: true
  }
  ngOnInit() {
    let checkCookies = localStorage.getItem('cookiesOk');
    if (checkCookies === "1") {
      this.cookieShowHide = false;
    }
    else {
      this.cookieShowHide = true;
    }
    localStorage.removeItem("url");
    this.UserId = +localStorage.getItem('userId');
    this.role = localStorage.getItem('role');
    this.productName = localStorage.getItem('productName');
    this.jobCategoryList();
    if (this.role === "User") {
      this.profileshowhide = true;
      this.GetUserDetails(this.UserId);
    }
    else {
      this.profileshowhide = false;
    }
    if (this.productName === undefined || this.productName === "undefined" || this.productName === "null") {
      this.productName = "";
      localStorage.setItem("productName", '');
    }
  }
  GetUserDetails(id) {
    this.service.GetuserById(id).subscribe(
      (res: any) => {
        this.name = res.firstName;
      },
      err => {

        if (err.status == 400)
          alert('error');
        else
          console.log(err);
      }
    );
  }
  GetProductList() {
    localStorage.removeItem("url");
    localStorage.setItem('ProductSearchType', '2');
    localStorage.setItem('productName', this.productName);
    this._router.navigate(['/productlist']);
  }
  GetInfoCenterList() {
    this.service.infoCenterList = [];
    localStorage.removeItem("url");
    localStorage.setItem('ActiveClass', 'All');
    localStorage.removeItem("url");
    localStorage.setItem('SearchType', 'All');
    localStorage.setItem('productName', this.productName);
    this._router.navigate(['/infocenter']);
  }
  GetJobList() {
    localStorage.removeItem("url");
    localStorage.setItem('productName', this.productName);
    this._router.navigate(['/joblist']);
  }
  rediresctContent(type) {
    if (type === "Privacy") {
      this._router.navigateByUrl('/privacy');
    }
    else if (type === "Terms") {
      this._router.navigateByUrl('/term-condtion');
    }
    else if (type === "aboutus") {
      this._router.navigateByUrl('/aboutus');
    }
    else if (type === "impressum") {
      this._router.navigateByUrl('/impressum');
    }
  }
  chnagePassword() {
    this._router.navigateByUrl('/user/change-password');
  }
  showreview: boolean;
  jobTitle = "";
  jobtitlemodel: string;
  openModalshowreview() {
    localStorage.removeItem("url");
    this.service.firstlanguage = 0
    this.service.secondlanguage = 0;
    this.service.thirdlanguage = 0;
    this.service.country = 0;
    this.service.state = 0;
    this.service.city = 0;
    this.jobTitle = this.productName;
    this.showreview = true;
  }
  closeModalshowreview() {
    this.showreview = false;
  }
  jobList = [];
  jobTypeList = [];
  languageList = [];
  countryList = [];
  cityList = [];
  jobCategoryList() {
    localStorage.removeItem("url");
    this.service.GeJobCategoryList().subscribe(
      (res: any) => {

        this.jobList = res.jobList;
        this.jobTypeList = res.jobTypeList;
        this.languageList = res.languageList;
        this.countryList = res.countryList;
        this.cityList = res.cityList;
      },
      err => {
        if (err.status == 400)

          //this.toastr.error('Incorrect username or password.', 'Authentication failed.');
          alert('error');
        else
          console.log(err);
      }
    );

  }
  compnayName = "";
  searchjob() {
    debugger;
    localStorage.removeItem("url");
    this.service.jobTitle = this.jobTitle;
    this.service.companyName = this.compnayName;
    localStorage.setItem('productName', this.jobTitle)
    this._router.navigateByUrl('/joblist');
  }
  GetjobList() {
    localStorage.removeItem("url");
    this.service.jobListMain = [];
    this.service.JobCategorySearch(this.service.jobTitle, this.service.jobType, this.service.companyName, this.service.country, this.service.state, this.service.city, this.service.firstlanguage, this.service.secondlanguage, this.service.thirdlanguage, this.PageNo, this.RecordperPage, this.service.industries).subscribe(
      (res: any) => {
        if (res[0].jobSearchModelsList.length > 0) {

          for (let index = 0; index < res[0].jobSearchModelsList.length; index++) {
            this.service.jobListMain.push({
              id: res[0].jobSearchModelsList[index].id,
              title: res[0].jobSearchModelsList[index].title,
              description: res[0].jobSearchModelsList[index].description,
              jobType: res[0].jobSearchModelsList[index].jobType,
              language: res[0].jobSearchModelsList[index].language,
              countryName: res[0].jobSearchModelsList[index].countryName,
              cityName: res[0].jobSearchModelsList[index].cityName,
              companyName: res[0].jobSearchModelsList[index].companyName,
              fullTime: res[0].jobSearchModelsList[index].fullTime,
              partTime: res[0].jobSearchModelsList[index].partTime,
              customerLocation: res[0].jobSearchModelsList[index].customerLocation,
              homeOfficeLocation: res[0].jobSearchModelsList[index].homeOfficeLocation,
              companyLocation: res[0].jobSearchModelsList[index].companyLocation,
              companyLogo: res[0].jobSearchModelsList[index].companyLogo
            });
          }
        }
        else {

        }
        this.showreview = false;
      },
      err => {
        if (err.status == 400)

          //this.toastr.error('Incorrect username or password.', 'Authentication failed.');
          alert('error');
        else
          console.log(err);
      }
    );
  }
  filterjobType(e) {
    this.service.jobType = e;
  }
  filterLanguage1(e) {
    this.service.firstlanguage = e;
  }
  filterLanguage2(e) {
    this.service.secondlanguage = e;
  }
  filterLanguage3(e) {
    this.service.thirdlanguage = e;
  }
  filterIndustries(e) {
    this.service.industries = e;
  }
  filterCountry(e) {
    this.service.country = e;
    this.jobStateList(e);
  }
  stateList = [];
  jobStateList(id) {
    this.service.GetStateById(id).subscribe(
      (res: any) => {
        this.stateList = res.stateList;
      },
      err => {
        if (err.status == 400)

          //this.toastr.error('Incorrect username or password.', 'Authentication failed.');
          alert('error');
        else
          console.log(err);
      }
    );

  }
  jobCityList(id) {
    this.service.GetCityById(id).subscribe(
      (res: any) => {
        this.service.state = id;
        this.cityList = res.cityList;
      },
      err => {
        if (err.status == 400)

          //this.toastr.error('Incorrect username or password.', 'Authentication failed.');
          alert('error');
        else
          console.log(err);
      }
    );

  }
  filterCity(e) {
    this.service.city = e;
  }
  GetSearchList() {
    debugger;
    localStorage.removeItem("url");
    localStorage.setItem('productName', this.productName);
    window.location.reload();
  }
  FnLogout() {
    localStorage.removeItem("url");
    localStorage.removeItem("userId");
    localStorage.removeItem("role");
    this.profileshowhide = false;
    this._router.navigateByUrl('/home');
  }
  fnProfile() {
    localStorage.removeItem("url");
    this._router.navigateByUrl('/userprofile');
  }
  UserRequest() {
    localStorage.removeItem("url");
    this._router.navigateByUrl('/user/request');
  }
  cookieShowHide = true;
  cookiesfn() {
    this.cookieShowHide = false;
    localStorage.setItem('cookiesOk', "1");
  }
  getvideoList() {
    this.service.videoPageNo = 1;
    this.service.infoCenterList = [];
    localStorage.removeItem("url");
    localStorage.removeItem('CategoryType');
    localStorage.setItem('ActiveClass', 'video');


    localStorage.setItem('SearchType', 'video');
    localStorage.setItem('InfoCentersearchText', this.productName);
    localStorage.setItem('productName', this.productName);
    this._router.navigateByUrl('/infocenter');
    // this.GetvideoearchList();
    // this.CountInfoCenterList(this.productName, 4, "");
  }
  GetvideoearchList() {
    this.service.infoCenterList = [];
    this.service.GetvideoearchList(this.productName, 1, this.RecordperPage).subscribe(
      (res: any) => {

        if (res[0].infoCenterList.length > 0) {
          for (let index = 0; index < res[0].infoCenterList.length; index++) {
            this.service.infoCenterList.push({
              id: res[0].infoCenterList[index].id,
              title: res[0].infoCenterList[index].title,
              description: res[0].infoCenterList[index].description,
              uploadVideo: res[0].infoCenterList[index].uploadVideo,
              type: res[0].infoCenterList[index].type,
              createdBy: res[0].infoCenterList[index].createdBy,
              reviewVideo: res[0].infoCenterList[index].reviewVideo,
              createdDate: res[0].infoCenterList[index].createdDate
            })
          }
        }
        else {

        }
      },
      err => {
        if (err.status == 400)

          //this.toastr.error('Incorrect username or password.', 'Authentication failed.');
          alert('error');
        else
          console.log(err);
      }
    );
  }
  CountInfoCenterList(searchtext, type, category) {
    this.service.countinfocenter(searchtext, type, category).subscribe(
      (res: any) => {

        this.service.countAll = res[0].totalCount[0].totalCount;
        this.setPage(1);
      },
      err => {
        if (err.status == 400)

          //this.toastr.error('Incorrect username or password.', 'Authentication failed.');
          alert('error');
        else
          console.log(err);
      }
    );
  }

  setPage(pageNumber: number) {

    this.service.pager = this.pagerService.getPager(this.service.countAll, pageNumber);



  }
  getJournalList() {
    this.service.JournalPageNo = 1;
    this.service.infoCenterList = [];
    localStorage.removeItem("url");
    localStorage.removeItem('CategoryType');
    localStorage.setItem('ActiveClass', 'journal');

    localStorage.setItem('SearchType', 'Journal');
    localStorage.setItem('InfoCentersearchText', this.productName);
    localStorage.setItem('productName', this.productName);
    this._router.navigateByUrl('/infocenter');
    // this.GetJournalSearchList();
    // this.CountInfoCenterList(this.productName, 2, "");
  }
  GetJournalSearchList() {
    this.service.infoCenterList = [];
    this.service.GeJournalearchList(this.productName, 1, this.RecordperPage).subscribe(
      (res: any) => {
        if (res[0].infoCenterList.length > 0) {
          for (let index = 0; index < res[0].infoCenterList.length; index++) {
            this.service.infoCenterList.push({
              id: res[0].infoCenterList[index].id,
              title: res[0].infoCenterList[index].title,
              description: res[0].infoCenterList[index].description,
              type: res[0].infoCenterList[index].type,
              uploadVideo: res[0].infoCenterList[index].uploadVideo,
              createdBy: res[0].infoCenterList[index].createdBy
            })
          }
        }
        else {

        }
      },
      err => {
        if (err.status == 400)

          //this.toastr.error('Incorrect username or password.', 'Authentication failed.');
          alert('error');
        else
          console.log(err);
      }
    );
  }
  getdocumentList() {
    debugger;
    this.service.documentPageNo = 1;
    this.service.infoCenterList = [];
    localStorage.removeItem("url");
    localStorage.removeItem('CategoryType');
    localStorage.setItem('ActiveClass', 'document');


    localStorage.setItem('SearchType', 'document');
    localStorage.setItem('InfoCentersearchText', this.productName);
    localStorage.setItem('productName', this.productName);
    this._router.navigateByUrl('/infocenter');
    // this.GetdocumentearchList();
    // this.CountInfoCenterList(this.productName, 3, "");
  }
  GetdocumentearchList() {
    this.service.infoCenterList = [];
    this.service.GetdocumentearchList(this.productName, 1, this.RecordperPage).subscribe(
      (res: any) => {
        if (res[0].infoCenterList.length > 0) {
          for (let index = 0; index < res[0].infoCenterList.length; index++) {
            this.service.infoCenterList.push({
              id: res[0].infoCenterList[index].id,
              title: res[0].infoCenterList[index].title,
              description: res[0].infoCenterList[index].description,
              type: res[0].infoCenterList[index].type,
              uploadVideo: res[0].infoCenterList[index].uploadVideo,
              createdBy: res[0].infoCenterList[index].createdBy
            })
          }
        }
        else {

        }
      },
      err => {
        if (err.status == 400)
          alert('error');
        else
          console.log(err);
      }
    );
  }
  GetInfoCenterList1() {

    this.service.infoCenterList = [];
    localStorage.removeItem("url");
    localStorage.removeItem('CategoryType');
    localStorage.setItem('ActiveClass', 'All');

    localStorage.setItem('SearchType', 'All');
    localStorage.setItem('InfoCentersearchText', this.productName);
    localStorage.setItem('productName', this.productName);
    this.service.AllPageNo = 1;
    this._router.navigateByUrl('/infocenter');
    // this.GetAllInfoCenterSearchList();
    // this.CountInfoCenterList(this.productName, 1, "");
  }
  GetAllInfoCenterSearchList() {
    this.service.infoCenterList = [];
    this.service.GeInfocenterSearchList(this.productName, this.service.AllPageNo, this.RecordperPage).subscribe(
      (res: any) => {
        if (res[0].infoCenterList.length > 0) {
          for (let index = 0; index < res[0].infoCenterList.length; index++) {

            this.service.infoCenterList.push({
              id: res[0].infoCenterList[index].id,
              title: res[0].infoCenterList[index].title,
              description: res[0].infoCenterList[index].description,
              type: res[0].infoCenterList[index].type,
              uploadVideo: res[0].infoCenterList[index].uploadVideo,
              createdBy: res[0].infoCenterList[index].createdBy,
              reviewVideo: res[0].infoCenterList[index].reviewVideo,
              createdDate: res[0].infoCenterList[index].createdDate
            })
          }
        }
        else {

        }
      },
      err => {
        if (err.status == 400)

          //this.toastr.error('Incorrect username or password.', 'Authentication failed.');
          alert('error');
        else
          console.log(err);
      }
    );
  }
  activeColor = "";
  GetCategory(categoryType) {

    this.activeColor = categoryType;
    this.service.infoCenterList = [];
    localStorage.setItem("activeCategoryColor", categoryType);
    localStorage.removeItem("url");
    localStorage.setItem('CategoryType', categoryType);
    let searchType = localStorage.getItem('SearchType');
    this.service.AllPageNo = 1;
    this.service.JournalPageNo = 1;
    this.service.documentPageNo = 1;
    this.service.videoPageNo = 1;
    if (searchType === "All") {
      if (categoryType === 'BestPractice' || categoryType === 'LessionLearnd' || categoryType === 'News' || categoryType === 'Tutorial' || categoryType === 'ToolBoxTalk' || categoryType === 'Innovation' || categoryType === 'ReacherchDeveloment' || categoryType === 'Exhibition' || categoryType === 'ProductPersentation') {
        // this.GetAllSearchbyCategory(this.productName, categoryType, 1, this.RecordperPage);
        // this.CountInfoCenterList(this.productName, 5, categoryType);
        this._router.navigateByUrl('/infocenter');
      }

    }
    else if (searchType === "Journal") {
      if (categoryType === 'BestPractice' || categoryType === 'LessionLearnd' || categoryType === 'News' || categoryType === 'Tutorial' || categoryType === 'ToolBoxTalk' || categoryType === 'Innovation' || categoryType === 'ReacherchDeveloment' || categoryType === 'Exhibition' || categoryType === 'ProductPersentation') {
        // this.GetJournalbyCategory(this.productName, categoryType,1, this.RecordperPage);
        // this.CountInfoCenterList(this.productName, 6, categoryType);
        this._router.navigateByUrl('/infocenter');
      }

    }
    else if (searchType === "document") {
      if (categoryType === 'BestPractice' || categoryType === 'LessionLearnd' || categoryType === 'News' || categoryType === 'Tutorial' || categoryType === 'ToolBoxTalk' || categoryType === 'Innovation' || categoryType === 'ReacherchDeveloment' || categoryType === 'Exhibition' || categoryType === 'ProductPersentation') {
        // this.GetDocumentbyCategory(this.productName, categoryType,1, this.RecordperPage);
        // this.CountInfoCenterList(this.productName, 7, categoryType);
        this._router.navigateByUrl('/infocenter');
      }

    }
    else if (searchType === "video") {
      if (categoryType === 'BestPractice' || categoryType === 'LessionLearnd' || categoryType === 'News' || categoryType === 'Tutorial' || categoryType === 'ToolBoxTalk' || categoryType === 'Innovation' || categoryType === 'ReacherchDeveloment' || categoryType === 'Exhibition' || categoryType === 'ProductPersentation') {
        // this.GetVideobyCategory(this.productName, categoryType, 1, this.RecordperPage)
        // this.CountInfoCenterList(this.productName, 8, categoryType);
        this._router.navigateByUrl('/infocenter');
      }

    }
  }
  GetAllSearchbyCategory(productName, categoryType, PageNo, recordPerPage) {
    this.service.infoCenterList = [];
    this.service.SearchByCategory(this.productName, categoryType, PageNo, this.RecordperPage).subscribe(
      (res: any) => {
        if (res[0].infoCenterList.length > 0) {
          for (let index = 0; index < res[0].infoCenterList.length; index++) {

            this.service.infoCenterList.push({
              id: res[0].infoCenterList[index].id,
              title: res[0].infoCenterList[index].title,
              description: res[0].infoCenterList[index].description,
              type: res[0].infoCenterList[index].type,
              uploadVideo: res[0].infoCenterList[index].uploadVideo,
              createdBy: res[0].infoCenterList[index].createdBy,
              reviewVideo: res[0].infoCenterList[index].reviewVideo,
              createdDate: res[0].infoCenterList[index].createdDate
            })
          }
        }
        else {

        }
      },
      err => {
        if (err.status == 400)

          //this.toastr.error('Incorrect username or password.', 'Authentication failed.');
          alert('error');
        else
          console.log(err);
      }
    );
  }
  GetJournalbyCategory(productName, categoryType, PageNo, recordPerPage) {
    this.service.infoCenterList = [];
    this.service.SearchJournalByCategory(this.productName, categoryType, PageNo, recordPerPage).subscribe(
      (res: any) => {
        if (res[0].infoCenterList.length > 0) {
          for (let index = 0; index < res[0].infoCenterList.length; index++) {
            this.service.infoCenterList.push({
              id: res[0].infoCenterList[index].id,
              title: res[0].infoCenterList[index].title,
              description: res[0].infoCenterList[index].description,
              type: res[0].infoCenterList[index].type,
              uploadVideo: res[0].infoCenterList[index].uploadVideo,
              createdByCreatedBy: res[0].infoCenterList[index].createdBy,
              reviewVideo: 0,
              createdDate: ""
            })
          }
        }
        else {

        }
      },
      err => {
        if (err.status == 400)

          //this.toastr.error('Incorrect username or password.', 'Authentication failed.');
          alert('error');
        else
          console.log(err);
      }
    );
  }
  GetDocumentbyCategory(productName, categoryType, PageNo, recordPerPage) {
    this.service.infoCenterList = [];
    this.service.DocumentByCategory(this.productName, categoryType, PageNo, recordPerPage).subscribe(
      (res: any) => {
        if (res[0].infoCenterList.length > 0) {
          for (let index = 0; index < res[0].infoCenterList.length; index++) {
            this.service.infoCenterList.push({
              id: res[0].infoCenterList[index].id,
              title: res[0].infoCenterList[index].title,
              description: res[0].infoCenterList[index].description,
              type: res[0].infoCenterList[index].type,
              uploadVideo: res[0].infoCenterList[index].uploadVideo,
              createdBy: res[0].infoCenterList[index].createdBy,
              reviewVideo: 0,
              createdDate: ""
            })
          }
        }
        else {

        }
      },
      err => {
        if (err.status == 400)

          //this.toastr.error('Incorrect username or password.', 'Authentication failed.');
          alert('error');
        else
          console.log(err);
      }
    );
  }
  GetVideobyCategory(productName, categoryType, PageNo, recordPerPage) {
    this.service.infoCenterList = [];
    this.service.VideoyCategory(this.productName, categoryType, PageNo, recordPerPage).subscribe(
      (res: any) => {

        if (res[0].infoCenterList.length > 0) {
          for (let index = 0; index < res[0].infoCenterList.length; index++) {


            this.service.infoCenterList.push({
              id: res[0].infoCenterList[index].id,
              title: res[0].infoCenterList[index].title,
              description: res[0].infoCenterList[index].description,
              type: res[0].infoCenterList[index].type,
              uploadVideo: res[0].infoCenterList[index].uploadVideo,
              createdBy: res[0].infoCenterList[index].createdBy,
              reviewVideo: res[0].infoCenterList[index].reviewVideo,
              createdDate: res[0].infoCenterList[index].createdDate
            })
          }
        }
        else {

        }
      },
      err => {
        if (err.status == 400)

          //this.toastr.error('Incorrect username or password.', 'Authentication failed.');
          alert('error');
        else
          console.log(err);
      }
    );
  }
  fnPrivacyPolicy()
  {
    this._router.navigateByUrl('/privacy');
  }
}
