import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material'
import { deleteservice } from '../delete.service';
import { Router,ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-deleterequest',
  templateUrl: './deleterequest.component.html',
  styleUrls: ['./deleterequest.component.css']
})
export class DeleterequestComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DeleterequestComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private service: deleteservice,
    private _router: Router

  ) { }

  ngOnInit() {
  }
  actionFunction() {
    //alert("You have logged out.");
    switch (this.data.type) {
      case "ApplyVendor":
        {
          this.service.Delete(this.data).subscribe(
            (res: any) => {
              this._router.navigateByUrl('admin/request/list');
              window.location.reload();
            },
            err => {

              if (err.status == 400)
                alert('error');
              else
                console.log(err);
            }
          );
          break;
        }
      case "Product":
        {
          this.service.Deleterequest(this.data).subscribe(
            (res: any) => {
              this._router.navigateByUrl('admin/request/list');
              window.location.reload();
            },
            err => {

              if (err.status == 400)
                alert('error');
              else
                console.log(err);
            }
          );
          break;
        }
      case "VendorVideo":
        {
          this.service.Deleterequest(this.data).subscribe(
            (res: any) => {
              this._router.navigateByUrl('admin/request/list');
              window.location.reload();
            },
            err => {

              if (err.status == 400)
                alert('error');
              else
                console.log(err);
            }
          );
          break;
        }
      case "Document":
        {
          this.service.Deleterequest(this.data).subscribe(
            (res: any) => {
              this._router.navigateByUrl('admin/request/list');
              window.location.reload();
            },
            err => {

              if (err.status == 400)
                alert('error');
              else
                console.log(err);
            }
          );
          break;
        }
      default:
        {
          break;
        }
    }

    this.closeModal();
  }

  // If the user clicks the cancel button a.k.a. the go back button, then\
  // just close the modal
  closeModal() {
    this.dialogRef.close();
  }
}
