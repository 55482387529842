import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MessageService } from '../../admin/inner/message/message.service';

@Component({
  selector: 'app-odcument-request-details',
  templateUrl: './odcument-request-details.component.html',
  styleUrls: ['./odcument-request-details.component.css']
})
export class OdcumentRequestDetailsComponent implements OnInit {
  id = 0; title = ""; Description = ""; companyName = ""; Status = ""; Name = ""; Email = ""; MobileNo = ""; Address = "";
  AddForm: FormGroup; diplayLogo = false; RequestEmail = ""; RequestDesc = "";
  constructor(private service: MessageService,
    private formBuilder: FormBuilder,
    private _router: Router,
    private _activatroute: ActivatedRoute) { }

  ngOnInit() {
    this.id = +this._activatroute.snapshot.params['id'];
    this.GetdocumentList();
    this.AddForm = this.formBuilder.group({

      status: ['', Validators.required],

    });
  }
  GetdocumentList() {
    this.service.GetDocumentById(this.id).subscribe(
      (res: any) => {
        debugger;
        this.title = res.title;
        this.Description = res.description;
         this.companyName=res.companyName;
        this.Name = res.name;
        this.Email = res.email;
        this.MobileNo = res.mobileNo;
        this.Address = res.address;
        this.Status = res.status;
        this.RequestEmail = res.requestEmail
        this.RequestDesc = res.requestDescription;
        
        this.AddForm.patchValue(res);
      },
      err => {

        if (err.status == 400)
          //this.toastr.error('Incorrect username or password.', 'Authentication failed.');
          alert('error');
        else
          console.log(err);

        //this.reset();
      }
    );
  }
  onSubmit()
  {}
  goback() {
    /// window.location.href="/author/list";
    this._router.navigateByUrl('/user/request');
  }
}
