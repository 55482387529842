import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonService } from '../../shared/common.service';

@Component({
  selector: 'app-user-change-password',
  templateUrl: './user-change-password.component.html',
  styleUrls: ['./user-change-password.component.css']
})
export class UserChangePasswordComponent implements OnInit {
  ForgotPasswordForm: FormGroup;
  returnUrl: string;
  Email: string;
  constructor(private formBuilder: FormBuilder, private _router: Router, private service: CommonService) { }

  ngOnInit() {
    this.Email = localStorage.getItem("Email");
    this.ForgotPasswordForm = this.formBuilder.group({
      oldPassword: ['', Validators.required],
      newPassword: ['', Validators.required],
      confirmPassword: ['', Validators.required]

    },
      {
        validator: MustMatch('newPassword', 'confirmPassword')
      });
  }
  onSubmit() {
    debugger;
    if(this.ForgotPasswordForm.value.oldPassword === "")
    {
      alert("Please enter old password");
      return false;
    }
    if(this.ForgotPasswordForm.value.newPassword === "")
    {
      alert("Please enter new password");
      return false;
    }
    if(this.ForgotPasswordForm.value.confirmPassword === "")
    {
      alert("Please enter confirm password");
      return false;
    }
    var body = {

      OldPassword: this.ForgotPasswordForm.value.oldPassword,
      NewPassword: this.ForgotPasswordForm.value.newPassword,
      Email: this.Email

    };

    this.service.changepassword(body).subscribe(
      (res: any) => {
        debugger;
        if (res.status == true) {

          alert(res.message);
          this._router.navigateByUrl('/home');
        }
        else {
          alert(res.message);
        }
      },
      err => {
        if (err.status == 400)

          //this.toastr.error('Incorrect username or password.', 'Authentication failed.');
          alert('error');
        else
          console.log(err);
      }
    );
  }

}
export function MustMatch(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    if (matchingControl.errors && !matchingControl.errors.mustMatch) {
      // return if another validator has already found an error on the matchingControl
      return;
    }

    // set error on matchingControl if validation fails
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ mustMatch: true });
    } else {
      matchingControl.setErrors(null);
    }
  }
}