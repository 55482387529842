import { Component, OnInit, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { CommonService } from '../../shared/common.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { PagerService } from '../../shared/pager.service';
@Component({
  selector: 'app-product-listing',
  templateUrl: './product-listing.component.html',
  styleUrls: ['./product-listing.component.css']
})
export class ProductListingComponent implements OnInit {

  constructor(public service: CommonService, private spinner: NgxSpinnerService, private _router: Router, private pagerService: PagerService) { }
  productName: string;
  productList = []; VendorId = 0; ProductSearchType = ""; showspace = ''; spacepreview = ''; spaceclose = '';
  currentId = 0;
  spacereviewList = [];
  PageNo = 1;
  RecordperPage = 10;
  ngOnInit() {
    debugger;
    // this.service.productList = [];
    this.spaceclose = 'none';
    this.productName = localStorage.getItem('productName');
    this.VendorId = + localStorage.getItem('VendorId');
    this.ProductSearchType = localStorage.getItem('ProductSearchType');
    if (this.productName === undefined || this.productName === "undefined" || this.productName === "null") {
      this.productName = "";
      localStorage.setItem('productName', '');
    }
    if (this.ProductSearchType === "1") {
      this.GetProductByVendorList(this.PageNo, this.RecordperPage);
      this.CountvendorProductList();
    }
    else {
      this.GetProductList(this.productName, this.PageNo, this.RecordperPage);
      this.CountProductList(this.productName);
    }
  }

  GetProductList(productName, PageNo, recordPerPage) {

    this.service.GetProductSearchList(this.productName, PageNo, this.RecordperPage).subscribe(
      (res: any) => {

        if (res[0].productList.length > 0) {
          this.service.productList = [];
          for (let index = 0; index < res[0].productList.length; index++) {
            let desc = res[0].productList[index].productDescription;
            if (desc === null || desc === undefined || desc === "") {
              desc = res[0].productList[index].productDescription;
            }
            else {
              desc = res[0].productList[index].productDescription.charAt(0).toUpperCase() + res[0].productList[index].productDescription.slice(1);
            }
            this.service.productList.push({
              id: res[0].productList[index].id,
              categoryName: res[0].productList[index].categoryName,
              companyName: res[0].productList[index].companyName,
              createdBy: res[0].productList[index].createdBy,
              createdDate: res[0].productList[index].createdDate
              , image: res[0].productList[index].image, isActive: res[0].productList[index].isActive
              , manufactruningWebSite: res[0].productList[index].manufactruningWebSite, modifiedBy: res[0].productList[index].modifiedBy
              , productAccessories: res[0].productList[index].productAccessories,
              productDescription: desc
              , productName: res[0].productList[index].productName,
              productSpecification: res[0].productList[index].productSpecification
            })
          }
        }
        else {
          this.spinner.hide();
        }
      },
      err => {
        if (err.status == 400)

          //this.toastr.error('Incorrect username or password.', 'Authentication failed.');
          alert('error');
        else
          console.log(err);
      }
    );
  }

  showPreview(id) {

    this.service.GetSpaceReviewByProductId(id).subscribe(
      (res: any) => {

        this.spacereviewList = res
        this.showspace = 'block';
        this.spacepreview = 'none';
        this.spaceclose = 'block';
        this.currentId = id;

      },
      err => {
        if (err.status == 400)

          //this.toastr.error('Incorrect username or password.', 'Authentication failed.');
          alert('error');
        else
          console.log(err);
      }
    );

  }
  closePreview(id) {
    this.showspace = 'none';
    this.spacepreview = 'block';
    this.spaceclose = 'none';
  }
  fnListingDetails(id, vendorId) {

    localStorage.setItem("ProductId", id);
    localStorage.setItem("VendorId", vendorId);

    this._router.navigate(['/product-description'])
  }
  GetProductByVendorList(PageNo, recordPerPage) {

    this.service.GetProductSearchListByVendor(this.VendorId, PageNo, this.RecordperPage).subscribe(
      (res: any) => {

        if (res[0].productList.length > 0) {
          this.service.productList = [];
          for (let index = 0; index < res[0].productList.length; index++) {
            let desc = res[0].productList[index].productDescription.charAt(0).toUpperCase() + res[0].productList[index].productDescription.slice(1);
            this.service.productList.push({
              id: res[0].productList[index].id,
              categoryName: res[0].productList[index].categoryName,
              companyName: res[0].productList[index].companyName,
              createdBy: res[0].productList[index].createdBy, createdDate: res[0].productList[index].createdDate
              , image: res[0].productList[index].image, isActive: res[0].productList[index].isActive
              , manufactruningWebSite: res[0].productList[index].manufactruningWebSite, modifiedBy: res[0].productList[index].modifiedBy
              , productAccessories: res[0].productList[index].productAccessories,
              productDescription: desc
              , productName: res[0].productList[index].productName,
              productSpecification: res[0].productList[index].productSpecification
            })
          }
        }
        else {
          this.spinner.hide();
        }
      },
      err => {
        if (err.status == 400)

          //this.toastr.error('Incorrect username or password.', 'Authentication failed.');
          alert('error');
        else
          console.log(err);
      }
    );
  }
  countAll = 0;
  CountProductList(productName) {
    this.service.CountProductAll(this.productName).subscribe(
      (res: any) => {
        this.service.countAll = res[0].totalCount[0].totalCount;
        this.setPage(1);
      },
      err => {
        if (err.status == 400)

          //this.toastr.error('Incorrect username or password.', 'Authentication failed.');
          alert('error');
        else
          console.log(err);
      }
    );
  }
  CountvendorProductList() {
    this.service.countProductByvendor(this.VendorId).subscribe(
      (res: any) => {
        this.service.countAll = res[0].totalCount[0].totalCount;
        this.setPage(1);
      },
      err => {
        if (err.status == 400)

          //this.toastr.error('Incorrect username or password.', 'Authentication failed.');
          alert('error');
        else
          console.log(err);
      }
    );

  }
  showHid(id, vendorId) {

    localStorage.setItem("ProductId", id);
    localStorage.setItem("VendorId", vendorId);
    let role = localStorage.getItem("role");
    localStorage.setItem("url", "product-description");
    if (role == "Admin") {
      this._router.navigateByUrl('/user/login');
    }
    else if (role == "Vendor") {
      this._router.navigateByUrl('/user/login');
    }
    else if (role == "User") {
      this._router.navigateByUrl('/product-description');
    }
    else {
      this._router.navigateByUrl('/user/login');
    }

  }

  // Custom Paging



  setPage(page: number) {

    this.service.pager = this.pagerService.getPager(this.service.countAll, page);
    if (this.ProductSearchType === "1") {
      this.GetProductByVendorList(page, this.RecordperPage);
      this.gotoTop();
    }
    else {
      this.GetProductList(this.productName, page, this.RecordperPage);
      this.gotoTop();
    }

  }
  gotoTop() {
    window.scroll({
      top: 0,
      left: 0,
      //behavior: 'smooth' 
    });
  }

}
