import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../shared/common.service';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-journal-list',
  templateUrl: './journal-list.component.html',
  styleUrls: ['./journal-list.component.css']
})
export class JournalListComponent implements OnInit {

  constructor(private service:CommonService, private spinner: NgxSpinnerService) { }
  searchText:string;
  infoCenterList=[];
  PageNo=1;
  RecordperPage=10;
  ngOnInit() {
    this.searchText=localStorage.getItem('InfoCentersearchText');
    if(this.searchText===undefined || this.searchText==="undefined")
    {
      this.searchText="";
      localStorage.setItem('InfoCentersearchText','');
    }
    this.GetSearchList(this.searchText,this.PageNo,this.RecordperPage);
  }
  onScroll() {
    this.spinner.show();
    this.PageNo=Number(this.PageNo)+1;
    this.GetSearchList(this.searchText,this.PageNo,this.RecordperPage);
    }
  GetSearchList(productName,PageNo,recordPerPage)
  {
  this.service.GeInfocenterSearchList(this.searchText,this.PageNo,this.RecordperPage).subscribe(
      (res: any) => {
        if(res[0].infoCenterList.length>0)
        {
        for (let index = 0; index < res[0].infoCenterList.length; index++) {
          this.infoCenterList.push({id: res[0].infoCenterList[index].id,title: res[0].infoCenterList[index].title,description: res[0].infoCenterList[index].description,})
        }
      }
      else{
        this.spinner.hide();
      }
      },
      err => {
        if (err.status == 400)
      
          //this.toastr.error('Incorrect username or password.', 'Authentication failed.');
  alert('error');
        else
          console.log(err);
      }
    );
  }
}
