import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MessageService } from '../admin/inner/message/message.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-job-request-details',
  templateUrl: './job-request-details.component.html',
  styleUrls: ['./job-request-details.component.css']
})
export class JobRequestDetailsComponent implements OnInit {
  id = 0; Title = ""; Description = ""; companyName = ""; Status = ""; cv = ""; coverLetter = "";
  ApplyOtherVendorDocumentModelList = []; Name = ""; Email = ""; MobileNo =""; Address="";
  RequestName = ""; RequestEmail ="";
  AddForm: FormGroup; diplayLogo = false;
  constructor(private service: MessageService,
    private formBuilder: FormBuilder,
    private _router: Router,
    private _activatroute: ActivatedRoute) { }

  ngOnInit() {
    this.id = +this._activatroute.snapshot.params['id'];
    this.GetJobList();
    this.AddForm = this.formBuilder.group({

      status: ['', Validators.required],

    });
  }
  GetJobList() {
    this.service.GetJobById(this.id).subscribe(
      (res: any) => {
        debugger;
        this.Title = res.title;
        this.Description = res.jobDescription;
        this.companyName = res.companyName;
        this.Status = res.status;
        this.cv = res.cv;
        this.coverLetter = res.coverLetter;
        this.Name=res.name;
        this.Email=res.email;
        this.MobileNo=res.mobileNo;
        this.Address =res.address; 
        this.ApplyOtherVendorDocumentModelList = res.applyOtherVendorDocumentModelList;
        this.RequestName = res.requestName;
        this.RequestEmail = res.requestEmail;
        this.AddForm.patchValue(res);
      },
      err => {

        if (err.status == 400)
          //this.toastr.error('Incorrect username or password.', 'Authentication failed.');
          alert('error');
        else
          console.log(err);

        //this.reset();
      }
    );
  }
  onSubmit()
  {}
  goback() {
    /// window.location.href="/author/list";
    this._router.navigateByUrl('/user/request');
  }
}
