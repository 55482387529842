import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from '../../shared/common.service';

@Component({
  selector: 'app-accountselectionregistration',
  templateUrl: './accountselectionregistration.component.html',
  styleUrls: ['./accountselectionregistration.component.css']
})
export class AccountselectionregistrationComponent implements OnInit {

  constructor(private formBuilder: FormBuilder, private _activatroute: ActivatedRoute, private service: CommonService, private _router: Router) { }
  loginForm: FormGroup;
  userId = "";
  ngOnInit() {
    this.userId = this._activatroute.snapshot.params['userid'];
    this.loginForm = this.formBuilder.group({

      password: ['', Validators.required],

      // memberCode: ['', Validators.required],
      angainPassword: ['', Validators.required]
    },

      {
        validator: MustMatch('password', 'angainPassword')
      });
  }
  message = "Password Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters.";
  onSubmit() {
    var body = {
      Id: this.userId,
      Password: this.loginForm.value.password,
      Code: 1235,
      Role: 'User'
    };

    this.service.AddPassword(body).subscribe(
      (res: any) => {

        if (res.status) {
          let userId = res.userId;
          localStorage.setItem('token', res.token);
          // localStorage.setItem('Email', res.email);
          localStorage.setItem('userId',userId);
          localStorage.setItem('UserMappingId', res.userMappingId);
          localStorage.setItem("role", 'User');
          // localStorage.setItem("userId", this.vendorId.toString());
          alert("Success");
          this._router.navigateByUrl('/home');
        }
        else {
          this.message = res.message;
        }

      },
      err => {

      
      }
    );
  }
}

export function MustMatch(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    if (matchingControl.errors && !matchingControl.errors.mustMatch) {
      // return if another validator has already found an error on the matchingControl
      return;
    }

    // set error on matchingControl if validation fails
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ mustMatch: true });
    } else {
      matchingControl.setErrors(null);
    }
  }
}
