import { Component, OnInit } from '@angular/core';
import { navItems } from '../inner-nav';
import { navVendorItems } from '../inner-nav';
import {Router} from '@angular/router';
@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.css']
})
export class LandingComponent implements OnInit {

  constructor(private _router : Router) { }
Role:string;
Admin=false;
userid:string;
  ngOnInit() {
    debugger;
    this.Role=localStorage.getItem("role");
    this.userid=localStorage.getItem("userId");
    if(this.Role=="Admin")
    {
      this.Admin=true;
    }
  }

  public sidebarMinimized = false;
  public navItems = navItems;
  public navVendorItems = navVendorItems;
  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }

  logout()
  {
    localStorage.removeItem('token');
    localStorage.removeItem("role");
    localStorage.removeItem("userId");
        this._router.navigateByUrl('/admin/login');
  }


  chnagepassword(){
    this._router.navigateByUrl('admin/changepassword');
  }
}
