import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../shared/common.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { PagerService } from '../../shared/pager.service';
@Component({
  selector: 'app-job-list',
  templateUrl: './job-list.component.html',
  styleUrls: ['./job-list.component.css']
})
export class JobListComponent implements OnInit {
  productName = "";
  PageNo = 1;
  RecordperPage = 10;
  jobListMain = [];


  constructor(public service: CommonService, private spinner: NgxSpinnerService, private _router: Router, private pagerService: PagerService) { }
  jobTitle = ""; jobType = ""; companyName = ""; firstlanguage = 0; secondlanguage =0; thirdlanguage = 0; country = 0; city = 0; state = 0; industries = "";;
  ngOnInit() {
    this.jobCategoryList();
    this.service.jobListMain = [];
    this.GetjobList(this.PageNo);
    this.jobType1 = "";
    this.JobCount();
  }

  GetjobList(page) {
    this.service.JobCategorySearch(this.service.jobTitle, this.service.jobType, this.service.companyName, this.service.country, this.service.state, this.service.city, this.service.firstlanguage, this.service.secondlanguage, this.service.thirdlanguage, page, this.RecordperPage, this.service.industries).subscribe(
      (res: any) => {
        if (res[0].jobSearchModelsList.length > 0) {
          this.service.jobListMain = [];
          for (let index = 0; index < res[0].jobSearchModelsList.length; index++) {
            let desc = res[0].jobSearchModelsList[index].description
            if (desc != null || desc != undefined || desc != "") {
              desc = res[0].jobSearchModelsList[index].description.charAt(0).toUpperCase() + res[0].jobSearchModelsList[index].description.slice(1);
            }
            this.service.jobListMain.push({
              id: res[0].jobSearchModelsList[index].id,
              title: res[0].jobSearchModelsList[index].title,
              description: desc,
              jobType: res[0].jobSearchModelsList[index].jobType,
              language: res[0].jobSearchModelsList[index].language,
              countryName: res[0].jobSearchModelsList[index].countryName,
              cityName: res[0].jobSearchModelsList[index].cityName,
              companyName: res[0].jobSearchModelsList[index].companyName,
              fullTime: res[0].jobSearchModelsList[index].fullTime,
              partTime: res[0].jobSearchModelsList[index].partTime,
              customerLocation: res[0].jobSearchModelsList[index].customerLocation,
              homeOfficeLocation: res[0].jobSearchModelsList[index].homeOfficeLocation,
              companyLocation: res[0].jobSearchModelsList[index].companyLocation,
              companyLogo: res[0].jobSearchModelsList[index].companyLogo,
              createdBy: res[0].jobSearchModelsList[index].createdBy
            });
          }
        }
        else {
          this.spinner.hide();
        }
      },
      err => {
        if (err.status == 400)

          //this.toastr.error('Incorrect username or password.', 'Authentication failed.');
          alert('error');
        else
          console.log(err);
      }
    );
  }

  jobList = [];
  jobTypeList = [];
  languageList = [];
  countryList = [];
  cityList = [];
  jobCategoryList() {
    this.service.GeJobCategoryList().subscribe(
      (res: any) => {

        this.jobList = res.jobList;
        this.jobTypeList = res.jobTypeList;
        this.languageList = res.languageList;
        this.countryList = res.countryList;
        // this.cityList = res.cityList;
      },
      err => {
        if (err.status == 400)

          //this.toastr.error('Incorrect username or password.', 'Authentication failed.');
          alert('error');
        else
          console.log(err);
      }
    );

  }
  showreview: boolean;
  jobType1 = "";
  openModalshowreview(type) {
    this.jobType = type;
    this.showreview = true;
  }
  closeModalshowreview() {
    this.showreview = false;
  }
  fullTime = false; FullTimeModel = false;
  FNfullTime(e) {

    if (e) {
      this.fullTime = true;
      this.FullTimeModel = true;
      this.PartTimeModel = false;
    }
    else {
      this.fullTime = false;
      this.FullTimeModel = false;
      this.PartTimeModel = false;
    }
  }
  partTime = false; PartTimeModel = false;
  fnPartTime(e) {

    if (e) {
      this.partTime = true;
      this.FullTimeModel = false;
      this.PartTimeModel = true;
    }
    else {
      this.partTime = false;
      this.FullTimeModel = false;
      this.PartTimeModel = false;
    }
  }
  company = false; CompanyModel = false; customerModel = false; homeOfficeModel = false;
  fnCompany(e) {

    if (e) {
      this.company = true;
      this.CompanyModel = true;
      this.customerModel = false;
      this.homeOfficeModel = false;
    }
    else {
      this.company = false;
      this.CompanyModel = false;
      this.customerModel = false;
      this.homeOfficeModel = false;
    }
  }
  customer = false;
  fnCustomer(e) {

    if (e) {
      this.customer = true;
      this.CompanyModel = false;
      this.customerModel = true;
      this.homeOfficeModel = false;
    }
    else {
      this.customer = false;
      this.CompanyModel = false;
      this.customerModel = false;
      this.homeOfficeModel = false;
    }
  }

  homeOffice = false;
  fnHomeOffice(e) {

    if (e) {
      this.homeOffice = true;
      this.CompanyModel = false;
      this.customerModel = false;
      this.homeOfficeModel = true;
    }
    else {
      this.homeOffice = false;
      this.CompanyModel = false;
      this.customerModel = true;
      this.homeOfficeModel = false;
    }
  }
  SelectJobList = [];
  jobfilter(pageNo) {
    debugger;
    this.service.jobListMain = [];
    this.PageNo = pageNo;
    var body = {

      JobTitle: this.jobTitle,
      JobType: this.jobType1,
      CompanyName: this.companyName,
      CountryNamme: this.country,
      stateName: this.state,
      CityName: this.city,
      Language1: this.firstlanguage,
      Language2: this.secondlanguage,
      Language3: this.thirdlanguage,
      Industries: this.industries,
      PageNo: pageNo,

      RecordPerPage: this.RecordperPage,
      fullTime: this.FullTimeModel,
      partTime: this.PartTimeModel,
      company: this.CompanyModel,
      customer: this.customerModel,
      homeOffice: this.homeOfficeModel,
    };


    this.jobCountfilter();
    this.service.JobCategorySearchSelection(body).subscribe(
      (res: any) => {


        if (res[0].jobSearchModelsList.length > 0) {
          for (let index = 0; index < res[0].jobSearchModelsList.length; index++) {
            let desc = res[0].jobSearchModelsList[index].description;
            if (desc != null || desc != undefined || desc != "") {
             desc = res[0].jobSearchModelsList[index].description.charAt(0).toUpperCase() + res[0].jobSearchModelsList[index].description.slice(1);
            }
            this.service.jobListMain.push({
              id: res[0].jobSearchModelsList[index].id,
              title: res[0].jobSearchModelsList[index].title,
              description: desc,
              jobType: res[0].jobSearchModelsList[index].jobType,
              language: res[0].jobSearchModelsList[index].language,
              countryName: res[0].jobSearchModelsList[index].countryName,
              cityName: res[0].jobSearchModelsList[index].cityName,
              companyName: res[0].jobSearchModelsList[index].companyName,
              fullTime: res[0].jobSearchModelsList[index].fullTime,
              partTime: res[0].jobSearchModelsList[index].partTime,
              customerLocation: res[0].jobSearchModelsList[index].customerLocation,
              homeOfficeLocation: res[0].jobSearchModelsList[index].homeOfficeLocation,
              companyLocation: res[0].jobSearchModelsList[index].companyLocation,
              companyLogo: res[0].jobSearchModelsList[index].companyLogo,
              createdBy: res[0].jobSearchModelsList[index].createdBy
            });
          }

        } else {
          this.service.jobListMain = [];
        }
        this.showreview = false;
      },
      err => {
        if (err.status == 400)

          //this.toastr.error('Incorrect username or password.', 'Authentication failed.');
          alert('error');
        else
          console.log(err);
      }
    );
  }
  fnJobDescription(id, vendorId) {

    localStorage.setItem("JobId", id);
    localStorage.setItem("VendorId", vendorId);
    this._router.navigate(['/job-description']);
  }
  countAll = 0;
  JobCount() {
    debugger;
    this.service.JobCount(this.service.jobTitle, this.service.jobType, this.service.companyName, this.service.country, this.service.state, this.service.city, this.service.firstlanguage, this.service.secondlanguage, this.service.thirdlanguage, this.PageNo, this.RecordperPage, this.service.industries).subscribe(

      (res: any) => {
        this.service.countAll = res[0].totalCount[0].totalCount;
        this.setPage(1);
      },
      err => {
        if (err.status == 400)
          alert('error');
        else
          console.log(err);
      }
    );
  }

  jobCountfilter() {

    var body = {

      JobTitle: this.jobTitle,
      JobType: this.jobType1,
      CompanyName: this.companyName,
      CountryNamme: this.country,
      stateName: this.state,
      CityName: this.city,
      Language1: this.firstlanguage,
      Language2: this.secondlanguage,
      Language3: this.thirdlanguage,
      Industries: this.industries,
      PageNo: this.PageNo,

      RecordPerPage: this.RecordperPage,
      fullTime: this.FullTimeModel,
      partTime: this.PartTimeModel,
      company: this.CompanyModel,
      customer: this.customerModel,
      homeOffice: this.homeOfficeModel,
    };


    this.service.JobCountCategorySearchSelection(body).subscribe(
      (res: any) => {
        this.service.countAll = res[0].totalCount[0].totalCount;
        this.service.pager = this.pagerService.getPager(this.service.countAll, 1);
      },
      err => {
        debugger
        if (err.status == 400)

          //this.toastr.error('Incorrect username or password.', 'Authentication failed.');
          alert('error');
        else
          console.log(err);
      }
    );
  }


  // Custom Paging


  setPage(page: number) {
    debugger;
    this.service.pager = this.pagerService.getPager(this.service.countAll, page);
    if (this.jobType === "FilterType") {
      this.jobfilter(page);
      this.gotoTop();
    }
    else {
      this.GetjobList(page);
      this.gotoTop();
    }

  }
  gotoTop() {
    window.scroll({
      top: 0,
      left: 0,
      //behavior: 'smooth' 
    });
  }

  stateList = [];
  jobStateList(id) {
    this.service.GetStateById(id).subscribe(
      (res: any) => {
        this.stateList = res.stateList;
      },
      err => {
        if (err.status == 400)

          //this.toastr.error('Incorrect username or password.', 'Authentication failed.');
          alert('error');
        else
          console.log(err);
      }
    );

  }
  jobCityList(id) {
    this.service.GetCityById(id).subscribe(
      (res: any) => {
        this.service.state = id;
        this.cityList = res.cityList;
      },
      err => {
        if (err.status == 400)

          //this.toastr.error('Incorrect username or password.', 'Authentication failed.');
          alert('error');
        else
          console.log(err);
      }
    );

  }


}
