import { Component, OnInit, Inject } from '@angular/core';
import { CommonService } from '../../shared/common.service';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { UUID } from 'angular2-uuid';
import { HttpEventType } from '@angular/common/http';
import { Router } from '@angular/router';
import { Location, DOCUMENT } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-job-description',
  templateUrl: './job-description.component.html',
  styleUrls: ['./job-description.component.css']
})
export class JobDescriptionComponent implements OnInit {
  selectedFile: File = null;
  selectedCVFile: File = null;
  path: string;
  AddForm: FormGroup;
  userEmail = "";
  constructor(private service: CommonService, private formBuilder: FormBuilder, private _router: Router, @Inject(DOCUMENT) private document: Document, location: Location, private spinner: NgxSpinnerService) {

    this.baseUrl = this.document.location.origin;
    if (location.path() != "") {
      this.route1 = location.path();
    } else {
      this.route1 = "Home";
    }
  }
  JobId = 0; aboutCompany = ""; jobDescription = ""; requirments = ""; WorkAddress = ""; companyName = ""; jobTitle = "";
  FullTime = ""; PartTime = ""; companyLogo = ""; issuedOn = ""; FileName: string; vendorId = 0; CompanyVideo: string;
  videodeacriptionList = []; baseUrl: string; route1: string;
  showApplyButton = false;
  showURLType = false;
  ngOnInit() {
    debugger;
    this.showDesc = true;
    this.showAfterApply = false;
    this.userEmail = localStorage.getItem('Email');
    this.JobId = Number(localStorage.getItem("JobId"));
    this.vendorId = +localStorage.getItem('VendorId');
    this.UserId = Number(localStorage.getItem('userId'));
    let role = localStorage.getItem("role");
    let url = localStorage.getItem("url");
    this.showURLType = false;
    let roleurltype = localStorage.getItem('roleurltype');
    if (role === "User") {
      this.showURLType = false;
      if (roleurltype === "2") {
        if (url !== null) {
          this.showDesc = false;
          this.showAfterApply = true;
        }
        else {
          this.showDesc = true;
          this.showAfterApply = false;
        }
      }
      else {
        this.showApplyButton = true;
        if (url !== null) {
          this.showDesc = true;
          this.showURLType = true;
        }
        else {
          this.showDesc = true;
          this.showURLType = true;
        }
      }
    }
    else {
      this.showApplyButton = false;
    }
    this.GetJobById();
    this.AddForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      coverLetter: [''],
      curriculumVitae:[''],
      otherdocuments :['']
    });
  }
  Link = "";
  showUrlBtn = false;
  GetJobById() {
    this.videodeacriptionList = [];
    this.service.GetJobById(this.JobId).subscribe(
      (res: any) => {
        debugger;
        this.videodeacriptionList.push({ companyVideo: res.companyVideo })
        this.CompanyVideo = res.companyVideo;
        this.aboutCompany = res.aboutCompany;
        this.jobDescription = res.jobDescription;
        this.requirments = res.requirments;
        this.WorkAddress = res.workAddress;
        this.companyName = res.companyName;
        this.jobTitle = res.jobTitle;
        this.companyLogo = res.companyLogo;
        this.issuedOn = res.createdDate;
        this.vendorId = res.createdBy;
        this.Link = res.link;
        debugger
        if (this.Link == null || this.Link == "") {
          this.showUrlBtn = false;
        }
        else {
          this.showUrlBtn = true;
        }
        if (res.fullTime) {
          this.FullTime = "Full-Time"
        }
        if (res.partTime) {
          this.FullTime = "Part-Time";
        }
      },
      err => {

        if (err.status == 400)
          //this.toastr.error('Incorrect username or password.', 'Authentication failed.');
          alert('error');
        else
          console.log(err);

        //this.reset();
      }
    );
  }
  showreview: boolean; UserId = 0;
  showDesc: boolean;
  showAfterApply: boolean;
  openModalshowreview(type: number) {
    debugger;
    let url = this.route1;
    localStorage.setItem("url", url);
    let role = localStorage.getItem("role");
    localStorage.setItem('roleurltype', type.toString());
    if (type === 2) {
      if (role == "Admin") {
        this._router.navigateByUrl('/user/login');
      }
      else if (role == "Vendor") {
        this._router.navigateByUrl('/user/login');
      }
      else if (role == "User") {
        this.showDesc = false;
        this.showAfterApply = true;
        this.UserId = Number(localStorage.getItem('userId'));
      }
      else {
        this._router.navigateByUrl('/user/login');
      }
    }
    else {
      if (role == "Admin") {
        this._router.navigateByUrl('/user/login');
      }
      else if (role == "Vendor") {
        this._router.navigateByUrl('/user/login');
      }
      else if (role == "User") {
        this.UserId = Number(localStorage.getItem('userId'));
      }
      else {
        this._router.navigateByUrl('/user/login');
      }
    }

  }
  OpenVideo() {
    this.showreview = true;
  }
  closeModalshowreview() {
    this.showreview = false;
  }
  onSubmit() {
    debugger;
    this.spinner.show();

    if (this.CVName === "" || this.CVName === undefined) {
      alert("Please upload CV");
      this.spinner.hide();
      return false;
    }
    var body = {
      Email: this.AddForm.value.email,
      FirstName: this.AddForm.value.firstName,
      LastName: this.AddForm.value.lastName,
      CoverLetter: this.FileName,
      CV: this.CVName,
      VendorId: this.vendorId,
      TabelStatus: true,
      CreatedBy: this.UserId,
      JobId: this.JobId,
      ApplyOtherVendorDocumentModelList: this.documentList
    };

    this.service.Apply(body).subscribe(
      (res: any) => {
        setTimeout(() => {
          this.spinner.hide();
          this._router.navigateByUrl('/home');
        }, 1000);

        // alert("Notification is sent successfully");

      },
      err => {
        if (err.status == 400)

          //this.toastr.error('Incorrect username or password.', 'Authentication failed.');
          alert('error');
        else
          console.log(err);
      }
    );

  }
  CVName: String;
  curriculumVitaemodel:string;
  coverLetterModel:string;
  onFileSelected(event) {
    debugger;

    //  let fileLength  = +<File>event.target.files.length;
    //  if(fileLength > 1)
    //  {
    //    alert("Please select only single file");
    //    this.coverLetterModel ="";
    //    return false;

    //  }
    this.selectedFile = <File>event.target.files[0];
    this.FileName = this.selectedFile.name;
    let check = this.validateFile1(this.FileName);
    if (check) {
      this.uploadFile();
    }
    else {
      alert("File format: doc .docx .pdf .txt .rtf .odt .jpg .png");
      this.coverLetterModel ="";
      return false;
    }
  }
  uploadFile() {

    this.path = UUID.UUID() + '.jpg';
    const formData = new FormData();
    formData.append('image', this.selectedFile, this.selectedFile.name);
    formData.append('name', this.path);
    formData.append('folder', "CoverLetter");
    this.service.FileUploadVendor(formData).subscribe(event => {
      if (event.type === HttpEventType.UploadProgress) {
        console.log('Upload Progress:' + Math.round(event.loaded / event.total * 100) + '%')

      }

      else if (event.type === HttpEventType.Response) {

        console.log(event.body);


      }
    });
  }

  validateFile1(name: String) {
    var ext = name.substring(name.lastIndexOf('.') + 1);
    if (ext.toLowerCase() == 'doc' || ext.toLowerCase() == 'docx' || ext.toLowerCase() == 'pdf' || ext.toLowerCase() == 'txt' || ext.toLowerCase() == 'rtf' || ext.toLowerCase() == 'odt' || ext.toLowerCase() == 'jpg' || ext.toLowerCase() == 'png') {
      return true;
    }
    else {
      return false;
    }
  }

  UploadCVSelected(event) {
    // let fileLength  = +<File>event.target.files.length;
    // if(fileLength > 1)
    // {
    //   alert("Please select only single file");
    //   this.curriculumVitaemodel ="";
    //   return false;

    // }
    this.selectedCVFile = <File>event.target.files[0];
    this.CVName = this.selectedCVFile.name;
    let check = this.validateFile1(this.CVName);
    if (check) {
      this.uploadCVFile();
    }
    else {
      alert("File format: doc .docx .pdf .txt .rtf .odt .jpg .png");
      this.curriculumVitaemodel ="";
    }
  }
  uploadCVFile() {

    this.path = UUID.UUID() + '.jpg';
    const formData = new FormData();
    formData.append('image', this.selectedCVFile, this.selectedCVFile.name);
    formData.append('name', this.path);
    formData.append('folder', "CV");
    this.service.FileUploadVendor(formData).subscribe(event => {
      if (event.type === HttpEventType.UploadProgress) {
        console.log('Upload Progress:' + Math.round(event.loaded / event.total * 100) + '%')

      }

      else if (event.type === HttpEventType.Response) {

        console.log(event.body);


      }
    });
  }


  OthermyFiles = [];
  otherdocumentsModel:string; 
  onFileOtherDocumentSelected(event) {
    debugger;

    var files = event.target.files;
    for (let index = 0; index < event.target.files.length; index++) {
      if (!this.validateFile(files[index].name)) {
        alert('Please select only jpg and png product image');
        this.otherdocumentsModel="";
        return false;
      }
      this.OthermyFiles.push(<File>event.target.files[index])
    }
    this.uploadIOtherDocumentmage();
  }

  documentList = [];
  uploadIOtherDocumentmage() {
    for (var i = 0; i < this.OthermyFiles.length; i++) {
      this.path = UUID.UUID() + '.jpg';
      const formData = new FormData();

      formData.append('image', this.OthermyFiles[i], this.OthermyFiles[i].name);
      this.documentList.push({ ApplyVendorId: this.vendorId, Name: this.OthermyFiles[i].name });


      formData.append('name', this.path);
      formData.append('folder', "OtherDocument");
      this.service.FileUploadVendor(formData).subscribe(event => {
        if (event.type === HttpEventType.UploadProgress) {
          console.log('Upload Progress:' + Math.round(event.loaded / event.total * 100) + '%')
        }
        else if (event.type === HttpEventType.Response) {
          console.log(event.body);
        }
      });
    }
  }
  validateFile(name: String) {
    var ext = name.substring(name.lastIndexOf('.') + 1);
    if (ext.toLowerCase() == 'png' || ext.toLowerCase() == 'jpg') {
      return true;
    }
    else {
      return false;
    }
  }
  redirectUrl() {
    debugger;
    window.location.href = this.Link;
  }
}
