import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from '../../shared/common.service';

@Component({
  selector: 'app-frontend-master',
  templateUrl: './frontend-master.component.html',
  styleUrls: ['./frontend-master.component.css']
})
export class FrontendMasterComponent implements OnInit {

  constructor(public _router: Router, public service: CommonService) { }
  UserId = 0; role = ""; profileshowhide = false;
  name = "";
  ngOnInit() {
   
    let checkCookies =   localStorage.getItem('cookiesOk');
    if(checkCookies === "1")
    {
      this.cookieShowHide=false;
      this.service.pageDisable = true;  
    }
    else{
      this.cookieShowHide=true;
      this.service.pageDisable = false;
    }
    
    this.service.menuType = true
    this.UserId = +localStorage.getItem('userId');
    this.role = localStorage.getItem('role');
    if (this.role === "User") {
      this.profileshowhide = true;
      this.GetUserDetails(this.UserId);
    }
    else {
      this.profileshowhide = false;
    }
  }
  cookieShowHide =true;
  cookiesfn()
  {
    this.cookieShowHide = false;
    this.service.pageDisable =  true;
    localStorage.setItem('cookiesOk',"1");
  }
  GetUserDetails(id) {
    this.service.GetuserById(id).subscribe(
      (res: any) => {
       
        this.name = res.firstName;
      },
      err => {

        if (err.status == 400)
          alert('error');
        else
          console.log(err);
      }
    );
  }
  UserRegister() {
    this._router.navigate(['/user/register']);
  }
  Login() {
    this._router.navigate(['/admin/login']);
  }
  rediresctContent(type) {
    if (type === "Privacy") {
      this._router.navigateByUrl('/privacy');
    }
    else if(type === "Terms"){
      this._router.navigateByUrl('/term-condtion');
    }
    else if(type === "aboutus")
    {
      this._router.navigateByUrl('/aboutus');
    }
    else if(type === "impressum")
    {
      this._router.navigateByUrl('/impressum');
    }
  }
  FnLogout() {
    localStorage.removeItem("userId");
    localStorage.removeItem("role");
    this.profileshowhide = false;
    this._router.navigateByUrl('/home');
  }
  fnProfile() {
    this._router.navigateByUrl('/userprofile');
  }
  chnagePassword() {
    this._router.navigateByUrl('/user/change-password');
  }
  UserRequest() {
    localStorage.removeItem("url");
    this._router.navigateByUrl('/user/request');
  }
  fnPrivacyPolicy()
  {
    this._router.navigateByUrl('/privacy');
  }
}

