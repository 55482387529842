import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../shared/common.service';

@Component({
  selector: 'app-journal-description',
  templateUrl: './journal-description.component.html',
  styleUrls: ['./journal-description.component.css']
})
export class JournalDescriptionComponent implements OnInit {
    id=0;
  constructor(private service:CommonService) { }
  description= "";
  JournalProductName = "";
  websiteURL = "";
  ngOnInit() {
    this.id = +localStorage.getItem('journalId');
    this.GetJournalData();
  }
  GetJournalData()
  {
    this.service.GetJournalById(this.id).subscribe(
      (res: any) =>
      {
        debugger;
        this.description=res.content;
        this.JournalProductName = res.relatedProductName;
        this.websiteURL = res.websiteLink;
      },
      err => {
        
        if (err.status == 400)
        //this.toastr.error('Incorrect username or password.', 'Authentication failed.');
           alert('error');
        else
          console.log(err);

          //this.reset();
      }
    );

  }
}
