import { Component, OnInit, Inject } from '@angular/core';
import { CommonService } from '../../shared/common.service';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Location, DOCUMENT } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-productlistingdetails',
  templateUrl: './productlistingdetails.component.html',
  styleUrls: ['./productlistingdetails.component.css']
})
export class ProductlistingdetailsComponent implements OnInit {
  productId = 0;
  CompanyName = ""; ProductName = ""; ProductSpecification = ""; ProductDescription = "";
  ManufactruningWebSite = ""; Video = ""; ImageList = []; CompanyLogo = ""; AboutCompany = "";
  AddForm: FormGroup; productAccessories = ""; ProductAccesooriesList = []; route1: string;
  ShowAccount = false; showTextArea = false; vendorId = 0; baseUrl: string;
  constructor(private service: CommonService, private _router: Router, private formBuilder: FormBuilder, @Inject(DOCUMENT) private document: Document, location: Location, private spinner: NgxSpinnerService) {
    this.baseUrl = this.document.location.origin;
    if (location.path() != "") {
      this.route1 = location.path();
    } else {
      this.route1 = "Home";
    }
  }
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    autoWidth: true,
    items: 1,
    navText: ['<', '>'],
    // responsive: {
    //   0: {
    //     items: 1
    //   },
    //   400: {
    //     items: 2
    //   },
    //   740: {
    //     items: 3
    //   },
    //   940: {
    //     items: 4
    //   }
    // },
    nav: true
  }

  customOptions1: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    autoWidth: true,
    // items: 2,
    navText: ['<', '>'],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 4
      }
    },
    nav: true
  }
  Quatations = false; Question = false;
  userEmail = "";
  ngOnInit() {
    debugger;
    let role = localStorage.getItem("role");
    this.userId = Number(localStorage.getItem('userId'));
    this.ShowAccount = true;
    this.showTextArea = false;
    this.vendorId = +localStorage.getItem('VendorId');
    this.productId = Number(localStorage.getItem("ProductId"));
    if (role === "User") {
      let url = localStorage.getItem("url");
      this.userEmail = localStorage.getItem('Email');
      if (url !== null) {
        this.ShowAccount = false;
        this.showTextArea = true;
      }
      else {
        this.ShowAccount = true;
        this.showTextArea = false;
      }
    }
    this.GetProductdetails(this.productId);
    this.showPreview(this.productId);
    this.jobCategoryList();
    this.AddForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]],
      countryId: ['', Validators.required],
      description: ['', Validators.required],
      quotationOffer: ['', Validators.required],
      question: ['', Validators.required]

    });
  }
  CompanyVideo = []; LogoVideo = []; LogoFileName = "";
  GetProductdetails(Id) {
    this.LogoVideo = [];
    this.CompanyVideo = [];
    this.service.GetProductById(Id).subscribe(
      (res: any) => {
        debugger;
        this.CompanyName = res.companyName;
        this.ProductName = res.productName;
        this.ProductSpecification = res.productSpecification;
        this.ProductDescription = res.productDescription;
        this.ManufactruningWebSite = res.manufactruningWebSite;
        this.Video = res.video;
        this.CompanyVideo.push({ Video: res.video })
        this.ImageList = res.imageList;
        this.CompanyLogo = res.companyLogo;
        this.LogoVideo.push({ productvideo: res.productVideo })
        this.vendorId = res.createdBy;
        this.AboutCompany = res.aboutCompany;
        this.productAccessories = res.productAccessories;
        let splitted = this.productAccessories.split(",");
        for (let index = 0; index < splitted.length; index++) {
          var pid = +splitted[index];
          this.service.GetProductById(pid).subscribe(
            (res1: any) => {

              this.ProductAccesooriesList.push({
                id: res1.id, productDescription: res1.productDescription,
                aboutCompany: res1.aboutCompany, productName: res1.productName, imageList: res1.imageList, companyName: res1.companyName
              })

            },
            err => {

              if (err.status == 400)
                //this.toastr.error('Incorrect username or password.', 'Authentication failed.');
                alert('error');
              else
                console.log(err);

              //this.reset();
            }
          );
        }
      },
      err => {

        if (err.status == 400)
          //this.toastr.error('Incorrect username or password.', 'Authentication failed.');
          alert('error');
        else
          console.log(err);

        //this.reset();
      }
    );
  }
  spacereviewList = [];
  showPreview(id) {
    this.service.GetSpaceReviewByProductId(id).subscribe(
      (res: any) => {
        this.spacereviewList = res;
      },
      err => {
        if (err.status == 400)

          //this.toastr.error('Incorrect username or password.', 'Authentication failed.');
          alert('error');
        else
          console.log(err);
      }
    );

  }
  userId = 0;
  showHid() {

    let url = this.route1;
    let role = localStorage.getItem("role");
    localStorage.setItem("url", url);
    if (role == "Admin") {
      this._router.navigateByUrl('/user/login');
    }
    else if (role == "Vendor") {
      this._router.navigateByUrl('/user/login');
    }
    else if (role == "User") {
      this.ShowAccount = false;
      this.showTextArea = true;
      this.userId = Number(localStorage.getItem('userId'));
    }
    else {
      this._router.navigateByUrl('/user/login');
    }

  }
  jobList = [];
  jobTypeList = [];
  languageList = [];
  countryList = [];
  cityList = [];
  jobCategoryList() {
    this.service.GeJobCategoryList().subscribe(
      (res: any) => {

        this.jobList = res.jobList;
        this.jobTypeList = res.jobTypeList;
        this.languageList = res.languageList;
        this.countryList = res.countryList;
        this.cityList = res.cityList;
      },
      err => {
        if (err.status == 400)

          //this.toastr.error('Incorrect username or password.', 'Authentication failed.');
          alert('error');
        else
          console.log(err);
      }
    );

  }
  onSubmit() {
    debugger;
    this.spinner.show();

    // if (this.AddForm.value.email === "" || this.AddForm.value.email === undefined) {
    //   alert("Please enter email id");
    //   return false;
    // }
    if (this.AddForm.value.countryId === "" || this.AddForm.value.countryId === undefined) {
      this.spinner.hide();
      alert("Please select country");
      return false;
    }
    if (this.Question === false && this.QuotationOffer === false) {
      this.spinner.hide();
      alert("Please check Quotation Offer  or Question");
      return false;
    }
    if (this.AddForm.value.description === "" || this.AddForm.value.description === undefined) {
      this.spinner.hide();
      alert("Please enter decription");
      return false;
    }
    var body = {
      Email: this.AddForm.value.email,
      CountryId: this.AddForm.value.countryId,
      QuotationOffer: this.QuotationOffer,
      Quotation: this.Question,
      Description: this.AddForm.value.description,
      ProductId: this.productId,
      Vendorid: this.vendorId,
      CreatedBy: this.userId
    };

    this.service.AddProductRequest(body).subscribe(
      (res: any) => {

        setTimeout(() => {
          this.ShowAccount = true;
          this.showTextArea = false;
          this.spinner.hide();
        }, 1000);
        //alert("Notification is sent successfully");

      },
      err => {
        if (err.status == 400)

          //this.toastr.error('Incorrect username or password.', 'Authentication failed.');
          alert('error');
        else
          console.log(err);
      }
    );

  }
  QuotationOffer = false;

  fnQuotationOffer(e) {
    debugger;
    if (e) {
      this.QuotationOffer = true;
      this.Question = false;
    }
    else {
      this.QuotationOffer = false;
    }
  }

  fnBack() {
    this.ShowAccount = true;
    this.showTextArea = false;
  }
  fnQuotation(e) {
    debugger;
    if (e) {
      this.QuotationOffer = false;
      this.Question = true;
    }
    else {
      this.Question = false;
    }
  }
  showreview: boolean;
  OpenVideo() {
    this.showreview = true;
  }
  closeModalshowreview() {
    this.showreview = false;
  }

  productmodel: boolean;
  OpenVideoProduct() {
    this.productmodel = true;
  }
  CloseProductVideo() {
    this.productmodel = false;
  }
  redirectvendorProduct() {
    this.service.productList = [];
    localStorage.setItem('ProductSearchType', '1');
    this._router.navigate(['/productlist'])
  }
}
