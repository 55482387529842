import { Component, OnInit, Renderer2 } from '@angular/core';
import { Messagemodle } from '../../admin/inner/message/messagemodle';
import { deleteModal } from '../../shared/deleteModal';
import { MessageService } from '../../admin/inner/message/message.service';
import { Router } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { AppSettings } from '../../shared/AppSettings';
import { DeleterequestComponent } from '../../shared/deleterequest/deleterequest.component';

@Component({
  selector: 'app-user-request',
  templateUrl: './user-request.component.html',
  styleUrls: ['./user-request.component.css']
})
export class UserRequestComponent implements OnInit {
  role = "";
  userId = 0;
  public data1: Messagemodle[];
  public deleteModal: deleteModal;
  public data;
  constructor(private service: MessageService, private _router: Router, private renderer: Renderer2, public matDialog: MatDialog) { }
  dtOptions: DataTables.Settings = {};
  ngOnInit() {
    this.role = localStorage.getItem('role');
    this.userId = +localStorage.getItem('userId');
    this.service.GetAll(this.userId, this.role).subscribe(
      (res: Messagemodle[]) => {
        this.data1 = res;

      },
      err => {

        if (err.status == 400)

          //this.toastr.error('Incorrect username or password.', 'Authentication failed.');
          alert('error');
        else
          console.log(err);
      }
    );
    this.dtOptions =
    {
      pagingType: 'full_numbers',
      //pageLength : 5,
      processing: true,
      lengthMenu: [10, 25, 50, 100, 500, 1000],
      //scrollX: true,
      ajax:
      {
        url: AppSettings.API_ENDPOINT + '/ApplyVendor/request-list?Vendorid=' + this.userId + "&Role=" + this.role,
        type: 'GET'
      },
      columns: [
        {
          title: 'Item Id',
          data: 'id'
        },
        {
          title: 'Title',
          data: 'title'
        },
        {
          title: 'Type',
          data: 'type'
        },
        {
          title: 'Status',
          data: 'status'
        },

        {
          title: '',
          data: null,
          //defaultContent : "<button (click)='edit()' ></button>"
          render: function (data: any, type: any, full: any) {
            debugger;
            // console.log(data.name);
            // return `<button (click)='edit({{data.name}})'>click me</button>`
            return `<button class="waves-effect btn" ><i record-video-add-id="` + data.rowid + "-" + data.type + `" class="fa fa-eye ""></i></button>
           
            `;
          }
        }

      ]


    };
  }
  ngAfterViewInit(): void {
    this.renderer.listen('document', 'click', (event) => {
      if (event.target.hasAttribute("record-video-add-id")) {
        debugger;
        let type = event.target.getAttribute("record-video-add-id")
        let x = type.split("-");
        if (x[1] == "Video Request") {
          this._router.navigate(["/user/request/video/" + x[0]]);
        }
        if (x[1] == "Question") {
          this._router.navigate(["/user/request/Product/" + x[0]]);
        }
        if (x[1] == "Quotation Offer") {
          this._router.navigate(["/user/request/Product/" + x[0]]);
        }
        if (x[1] == "Document Request") {
          this._router.navigate(["/user/request/document/" + x[0]]);
        }
        if (x[1] == "Job Request") {
          this._router.navigate(["/user/request/job/" + x[0]]);
        }
      }
      if (event.target.hasAttribute("record-message-delete-id")) {
        debugger;
        let type = event.target.getAttribute("record-message-delete-id")
        let x = type.split("-");

        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.id = "modal-component";
        dialogConfig.height = "350px";
        dialogConfig.width = "600px";

        this.deleteModal = new deleteModal();
        this.deleteModal.id =  + x[0];
        this.deleteModal.message = "";
        if (x[1] == "Job") {
          this.deleteModal.type = "ApplyVendor";
        }
        if (x[1] == "Document") {
          this.deleteModal.type = "Document";
        }
        if (x[1] == "Product") {
          this.deleteModal.type = "Product";
        }
        if (x[1] == "Video") {
          this.deleteModal.type = "VendorVideo";
        }
        dialogConfig.data = this.deleteModal;
        const modalDialog = this.matDialog.open(DeleterequestComponent, dialogConfig);

      }


    });
  }
}
