import { INavData } from '@coreui/angular';

export const navItems: INavData[] = [
  {
    name: 'Dashboard',
    url: '/admin/dashboard',
    icon: 'icon-speedometer',
    
  },
  {
    name: 'Manage User',
    url: '/admin/user/list',
    icon: 'icon-user',
  },
  {
    name: 'Manage Vendor',
    url: '/admin/vendor/list',
    icon: 'icon-user',
  },
  {
    name: 'Manage Job',
    url: '/admin/job/list',
    icon: 'icon-user',
  },
  {
    name: 'Manage Journal',
    url: '/admin/journal/list',
    icon: 'icon-user',
  },
  {
    name: 'Manage Document',
    url: '/admin/document/list',
    icon: 'icon-user',
  },
  {
    name: 'Manage Video',
    url: '/admin/video/list',
    icon: 'icon-user',
  },
  {
    name: 'Manage Product',
    url: '/admin/product/list',
    icon: 'icon-user',
  },
  {
    name: 'Subscription',
    url: '/admin/subscription/list',
    icon: 'icon-user',
  },
  {
    name: 'Request',
    url: '/admin/request/list',
    icon: 'icon-user',
  },
  {
    name: 'Content',
    url: '/content',
    icon: 'icon-cursor',
    children: [
      {
        name: 'About Us',
        url: '/admin/content/aboutus',
        icon: 'icon-cursor'
      },
      {
        name: 'Impressum',
        url: '/admin/content/impressum',
        icon: 'icon-cursor'
      },
      {
        name: 'Privacy',
        url: '/admin/content/privacy',
        icon: 'icon-cursor'
      },

      {
        name: 'Terms and Conditions',
        url: '/admin/content/termsandcondition',
        icon: 'icon-cursor'
      }
    ]
  },
];



export const navVendorItems: INavData[] = [
  {
    name: 'Dashboard',
    url: '/vendor/dashboard',
    icon: 'icon-speedometer',
    
  },
  {
    name: 'Manage Profile',
    url: '/admin/vendor/add/id',
    icon: 'icon-user',
  },
  {
    name: 'Manage Job',
    url: '/admin/job/list',
    icon: 'icon-user',
  },
  {
    name: 'Manage Journal',
    url: '/admin/journal/list',
    icon: 'icon-user',
  },
  {
    name: 'Manage Document',
    url: '/admin/document/list',
    icon: 'icon-user',
  },
  {
    name: 'Manage Video',
    url: '/admin/video/list',
    icon: 'icon-user',
  },
  {
    name: 'Manage Product',
    url: '/admin/product/list',
    icon: 'icon-user',
  },
  {
    name: 'Request',
    url: '/admin/request/list',
    icon: 'icon-user',
  },
  {
    name: 'Subscription',
    url: '/admin/subscription/report',
    icon: 'icon-user',
  },
   {
    name: 'Message',
    url: '/admin/request/message-list',
    icon: 'icon-user',
  },
];
