import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, PatternValidator } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpEventType } from '@angular/common/http';
//import { EventEmitter } from 'events';
import { resetFakeAsyncZone } from '@angular/core/testing';
import { UUID } from 'angular2-uuid';

import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { UserService } from '../../admin/auth/auth/auth.service';


@Component({
  selector: 'app-userprofile',
  templateUrl: './userprofile.component.html',
  styleUrls: ['./userprofile.component.css']

})
export class UserprofileComponent implements OnInit {
  datePickerConfig: Partial<BsDatepickerConfig>;

  AddForm: FormGroup;
  selectedFile: File = null;
  uploadResult: any;
  path: string;
  isImageUploaded: boolean = false; repoint = 0;
  imageURL: string = "";
  userId: string;
  date: Date;
  countryList = [];
  stateList = [];
  cityList = [];
  id = 0;
  constructor(
    private service: UserService,
    private formBuilder: FormBuilder,
    private _router: Router,
    private _activatroute: ActivatedRoute,

  ) {
    this.datePickerConfig = Object.assign({}, {
      containerClass: 'theme-red',
      minDate: new Date(1970,1,1),
      maxDate: new Date(2030, 11, 31),
       dateInputFormat: 'DD-MM-YYYY'

    });
  
  }

  ngOnInit() {
    this.CountryList();
    this.id = +localStorage.getItem('userId');
    this.AddForm = this.formBuilder.group({
      firstName: ['', [Validators.required, Validators.maxLength(20),

      Validators.pattern('^[a-zA-Z ]*$')]],

      lastName: ['', [Validators.required, Validators.maxLength(20),
      Validators.pattern('^[a-zA-Z ]*$')]],
      gender: ['', Validators.required],
      dob: ['', Validators.required],
      professionalCurrentStatus: ['', Validators.required],
      professionalJobTitle: ['', Validators.required],
      professionalInChargeStaff: ['', Validators.required],
      graduateInstitutionName: ['', Validators.required],
      graduateCourseOfStudy: ['', Validators.required],
      graduateCountryId: ['', Validators.required],
      contactCountryId: ['', Validators.required],
      contactStateId: ['', Validators.required],
      contactCityId: ['', Validators.required],
      contactStreet: ['', Validators.required],
      contactPostalCode: ['', Validators.required],
      contactMobileNo: ['', Validators.required],
      companyName: ['', Validators.required],
      companyTypeOfIndustry: ['', Validators.required],
      companyCountryId: ['', Validators.required],
      companyStateId: ['', Validators.required],
      companyCityId: ['', Validators.required],
      companyStreet: ['', Validators.required],
      companyPostalcode: ['', Validators.required],
      companyMobileNumber: ['', Validators.required],
      companyEmail: ['', Validators.required],
      companyRepeatedEmail: ['', Validators.required],
      contactEmail: ['', Validators.required],
    });
    this.service.GetuserById(this.id).subscribe(
      (res: any) => {
        debugger;
     
        this.contactState(res.contactCountryId);
        this.contactCity(res.contactStateId);
        this.changeState(res.companyCountryId);
        this.changeCity(res.companyStateId);
        this.AddForm.patchValue(res);

      },
      err => {

        if (err.status == 400)
          //this.toastr.error('Incorrect username or password.', 'Authentication failed.');
          alert('error');
        else
          console.log(err);

        //this.reset();
      }
    );
  }

  onFileSelected(event) {

    this.selectedFile = <File>event.target.files[0];
  }
  onSubmit() {
debugger;
    if (this.AddForm.value.gender === "") {
      alert("Please check gender");
      return false;
    }
    if (this.AddForm.value.firstName === "") {
      alert("Please enter first name");
      return false;
    }
    if (this.AddForm.value.lastName === "") {
      alert("Please enter last name");
      return false;
    }
    if (this.AddForm.value.dob === "") {
      alert("Please select dob");
      return false;
    }
    if (this.AddForm.value.contactMobileNo === "") {
      alert("Please enter mobile no.");
      return false;
    }
    var body = {

      Id: this.id,
      Gender: this.AddForm.value.gender,
      FirstName: this.AddForm.value.firstName,
      LastName: this.AddForm.value.lastName,
      DOB: new Date(this.AddForm.value.dob).toDateString(),
      ProfessionalCurrentStatus: this.AddForm.value.professionalCurrentStatus,
      ProfessionalJobTitle: this.AddForm.value.professionalJobTitle,

      ProfessionalInChargeStaff: this.AddForm.value.professionalInChargeStaff,
      GraduateInstitutionName: this.AddForm.value.graduateInstitutionName,
      GraduateCourseOfStudy: this.AddForm.value.graduateCourseOfStudy,
      GraduateCountryId: this.AddForm.value.graduateCountryId,
      ContactCountryId: this.AddForm.value.contactCountryId,
      ContactStateId: this.AddForm.value.contactStateId,
      ContactCityId: this.AddForm.value.contactCityId,
      ContactStreet: this.AddForm.value.contactStreet,
      ContactPostalCode: this.AddForm.value.contactPostalCode,
      ContactMobileNo: this.AddForm.value.contactMobileNo,
      CompanyName: this.AddForm.value.companyName,
      CompanyTypeOfIndustry: this.AddForm.value.companyTypeOfIndustry,
      CompanyCountryId: this.AddForm.value.companyCountryId,
      CompanyStateId: this.AddForm.value.companyStateId,
      CompanyCityId: this.AddForm.value.companyCityId,
      CompanyStreet: this.AddForm.value.companyStreet,
      CompanyPostalcode: this.AddForm.value.companyPostalcode,
      CompanyMobileNumber: this.AddForm.value.companyMobileNumber,
      CompanyEmail: this.AddForm.value.companyEmail,
      CompanyRepeatedEmail: this.AddForm.value.companyRepeatedEmail,
    };


    if (this.id == 0) {

    }
    else {

      this.service.Edit(body).subscribe(
        (res: any) => {



          // localStorage.setItem('token', res.token);
          //window.location.href="/author/list";
          this._router.navigateByUrl('/home');
          alert("User is updated successfully");

        },
        err => {

          if (err.status == 400)

            //this.toastr.error('Incorrect username or password.', 'Authentication failed.');
            alert('error');
          else
            console.log(err);
        }
      );
    }




  }


  goback() {
    this._router.navigateByUrl('/home');
  }

  CountryList() {
    this.service.GetAllCountry().subscribe(
      (res: any) => {

        this.countryList = res[0].cList;
        this.stateList = res[0].sList;
        this.cityList = res[0].cityList;
        let contactCountryId = Number(localStorage.getItem("contactCountryId"));
        let contactState = this.stateList.filter(x => x.countryId === contactCountryId);
        this.filterContactState = contactState;

        let contactStateId = Number(localStorage.getItem("contactStateId"));
        let contactCity = this.cityList.filter(x => x.stateId === contactStateId);
        this.filterContactCity = contactCity;


        let companyCountryId = Number(localStorage.getItem("companyCountryId"));
        let companyState = this.stateList.filter(x => x.countryId === companyCountryId);
        this.filterState = companyState;
        let companyStateId = Number(localStorage.getItem("companyStateId"));
        let companyCity = this.cityList.filter(x => x.stateId === companyStateId);
        this.cityFilter = companyCity

      },
      err => {

        if (err.status == 400)
          //this.toastr.error('Incorrect username or password.', 'Authentication failed.');
          alert('error');
        else
          console.log(err);

        //this.reset();
      }
    );
  }
  filterContactState = []
  filterContactCity = [];
  contactState(Id) {
    this.service.GetStateById(Id).subscribe(
      (res: any) => {
        
        this.filterContactState= res.stateList;
      },
      err => {
        if (err.status == 400)

          //this.toastr.error('Incorrect username or password.', 'Authentication failed.');
          alert('error');
        else
          console.log(err);
      }
    );
  }
  contactCity(Id) {
    this.service.GetCityById(Id).subscribe(
      (res: any) => {
        
        this.filterContactCity = res.cityList;
      },
      err => {
        if (err.status == 400)

          //this.toastr.error('Incorrect username or password.', 'Authentication failed.');
          alert('error');
        else
          console.log(err);
      }
    );
  }
  filterState = [];
  cityFilter = [];
  changeState(Id) {

    this.service.GetStateById(Id).subscribe(
      (res: any) => {
        
        this.filterState= res.stateList;
      },
      err => {
        if (err.status == 400)

          //this.toastr.error('Incorrect username or password.', 'Authentication failed.');
          alert('error');
        else
          console.log(err);
      }
    );

  }
  changeCity(Id) {

    this.service.GetCityById(Id).subscribe(
      (res: any) => {
        
        this.cityFilter = res.cityList;
      },
      err => {
        if (err.status == 400)

          //this.toastr.error('Incorrect username or password.', 'Authentication failed.');
          alert('error');
        else
          console.log(err);
      }
    ); 

  }
}


