import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-thankyou',
  templateUrl: './thankyou.component.html',
  styleUrls: ['./thankyou.component.css']
})
export class ThankyouComponent implements OnInit {

  constructor() { }
VendoruserId=0;
url="";
  ngOnInit() {
    debugger;
     this.VendoruserId = Number(localStorage.getItem("VendorUserId"));
     this.url="http://dotnet4.peaceofmind.in/Epiccas_11633/account-selection-registration";
  }

}
