import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../shared/common.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-payment-page',
  templateUrl: './payment-page.component.html',
  styleUrls: ['./payment-page.component.css']
})
export class PaymentPageComponent implements OnInit {
  AddForm: FormGroup;
  constructor(private service: CommonService, private formBuilder: FormBuilder, private _router: Router, private spinner: NgxSpinnerService) { }
  LastPaymentPrice = 0; encrypetedId = "";
  vendorId = 0; currentMonth = 0; currentYear = 0; termscondtion = "";
  msg = ''; currentDate: Date;
  showmsg = false;
  ngOnInit() {
    debugger
    this.encrypetedId = localStorage.getItem('encriptvendorId');
    this.service.GetContent().subscribe(
      (res: any) => {

        this.termscondtion = res.termsandCondition;

      },
      err => {

        if (err.status == 400)
          //this.toastr.error('Incorrect username or password.', 'Authentication failed.');
          alert('error');
        else
          console.log(err);

        //this.reset();
      }
    );
    this.currentDate = new Date();
    let month = this.currentDate.getMonth() + 1;
    let year = this.currentDate.getFullYear();
    this.currentMonth = month;
    this.currentYear = year;
    this.LastPaymentPrice = Number(localStorage.getItem('TotalPrice'));
    this.vendorId = Number(localStorage.getItem('VendorUserId'));
    this.AddForm = this.formBuilder.group({
      cardNumber: ['', Validators.required],

      expMonth: ['', Validators.required],
      expYear: ['', Validators.required],
      cardHolderName: ['', Validators.required],
      cVV: ['', Validators.required],
      customerID: ['', Validators.required],
      termscondtions: [false, Validators.required],
    });
  }
  onSubmit() {  
    this.spinner.show();
    var body = {
      CardNumber: this.AddForm.value.cardNumber,
      ExpMonth: this.AddForm.value.expMonth,
      ExpYear: this.AddForm.value.expYear,
      CardHolderName: this.AddForm.value.cardHolderName,
      CVV: this.AddForm.value.cVV,
      VendorId: this.vendorId,
      Price: this.LastPaymentPrice
    };

    if (!this.AddForm.value.termscondtions) {
      this.msg = "Please check Terms & Conditions";
      this.showmsg = true;
      return false;
    }

    this.service.PaymentApi(body).subscribe(
      (res: any) => {
       
        if (!res.status) {
          this.msg = res.message;
          this.showmsg = true;
          this.spinner.hide();
          return false;
        }
        else {
          this._router.navigateByUrl('/subscription-thank-you');
          var vendorId = {
            Id: this.vendorId,
          };
          this.service.SenddMailOfVendorPassword(vendorId).subscribe(
            (res1: any) => {
              this.spinner.hide();

            }
          );
          var data=JSON.parse(localStorage.getItem('planData'));
          data.vendorId=vendorId;
          this.service.SenddMailOfVendorPayment(data).subscribe(
            (res1: any) => {
              this.spinner.hide();
      
            }
          );
        }
      }
    );
  }
  fnBack() {
   
    this._router.navigateByUrl('/account-selection-registration/' + this.encrypetedId);
  }
  cardYear = 0; yearModel = ""; monthModel = "";
  fnYearCheck(e) {
   

    this.cardYear = +e;
    if (this.cardYear <= this.currentYear) {
      alert("Month and year should less then current date");
      //this.AddForm.
      this.yearModel = null;
      this.monthModel = null;
    }
  }
  cardMonth = 0;
  fnMonthCheck(e) {
   

    this.cardMonth = +e;
  }
  showtermsandCondtion: boolean;
  openModalTerms() {
    this.showtermsandCondtion = true;
  }

  closeModalTerms() {
    this.showtermsandCondtion = false;

  }
}
