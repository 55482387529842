import { Component, OnInit, Inject } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { CommonService } from '../../shared/common.service';
import { Location, DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-video-description',
  templateUrl: './video-description.component.html',
  styleUrls: ['./video-description.component.css']
})
export class VideoDescriptionComponent implements OnInit {
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    // autoWidth:true,
   // items: 20,
    navText: ['<', '>'],

    nav: true,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 2,
      },
      700: {
        items: 3,
      },
      900: {
        items: 3,
      },
      1200: {
        items: 4
      }
    }
  }
  id = 0;
  AddForm: FormGroup;

  constructor(public service: CommonService, private _router: Router, @Inject(DOCUMENT) private document: Document, location: Location,
   private formBuilder: FormBuilder, private spinner: NgxSpinnerService) {
    this.baseUrl = this.document.location.origin;
    if (location.path() != "") {
      this.route1 = location.path();
    } else {
      this.route1 = "Home";
    }
  }
  VideoTitle = ""; video = ""; smilarVideo = []; createdDate = ""; VideoDescription = "";
  companyName = ""; reviewVideo = 0;
  companyLogo = ""; VendorId = 0; baseUrl: string; route1: string;
  VideoDscription = [];
  userEmail = "";
  ngOnInit() {
    this.userEmail = localStorage.getItem('Email');
    this.ShowAccount = true;
    let role = localStorage.getItem("role");
    this.id = +localStorage.getItem('VideoId');
    this.VendorId = + localStorage.getItem('VendorId');
    this.userId = Number(localStorage.getItem('userId'));
    this.jobCategoryList();
    this.GetVideo();
    this.ReviewVideo();
    this.AddForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]],
      countryId: ['', Validators.required],
      description: ['', Validators.required],
      quotationOffer: ['', Validators.required],
      question: ['', Validators.required]

    });
  }
  countryList = [];
  cityList = [];
  jobCategoryList() {
    this.service.GeJobCategoryList().subscribe(
      (res: any) => {



        this.countryList = res.countryList;
        this.cityList = res.cityList;
      },
      err => {
        if (err.status == 400)

          //this.toastr.error('Incorrect username or password.', 'Authentication failed.');
          alert('error');
        else
          console.log(err);
      }
    );

  }
  GetVideo() {
    this.service.GetVideoById(this.id).subscribe(
      (res: any) => {
        
        this.VideoDscription = [];
        localStorage.setItem('VideoTitle', res.videoTitle)
        this.service.VideoTitle = res.videoTitle;
        this.VideoDscription.push({ uploadVideo: res.uploadVideo })
        this.video = res.uploadVideo;
        this.createdDate = res.createdDate;
        this.VideoDescription = res.description;
        this.service.companyName = res.companyName;
        this.service.companyLogo = res.companyLogo;
        this.reviewVideo = res.reviewVideo;
        this.VendorId = res.createdBy;
        localStorage.setItem('VendorId', this.VendorId.toString())
        this.getSimilarVideo();
      },
      err => {

        if (err.status == 400)
          //this.toastr.error('Incorrect username or password.', 'Authentication failed.');
          alert('error');
        else
          console.log(err);

        //this.reset();
      }
    );

  }
  ReviewVideo() {
    this.service.ReviewVideo(this.id).subscribe(
      (res: any) => {
        

      },
      err => {

        if (err.status == 400)
          //this.toastr.error('Incorrect username or password.', 'Authentication failed.');
          alert('error');
        else
          console.log(err);

        //this.reset();
      }
    );

  }
  getSimilarVideo() {
    debugger;
    this.service.similarVideo(this.service.VideoTitle, 1, 20).subscribe(
      (res: any) => {
        

        if (res[0].infoCenterList.length > 0) {
          for (let index = 0; index < res[0].infoCenterList.length; index++) {
            if (this.service.VideoTitle != res[0].infoCenterList[index].title) {
              
              this.smilarVideo.push({
                id: res[0].infoCenterList[index].id,
                title: res[0].infoCenterList[index].title,
                description: res[0].infoCenterList[index].description,
                type: res[0].infoCenterList[index].type,
                uploadVideo: res[0].infoCenterList[index].uploadVideo + ".jpg",
                companyName: res[0].infoCenterList[index].companyName,
                createdDate: res[0].infoCenterList[index].createdDate,
                reviewVideo: res[0].infoCenterList[index].reviewVideo
              })
            }
          }
        }

      },
      err => {
        if (err.status == 400)

          //this.toastr.error('Incorrect username or password.', 'Authentication failed.');
          alert('error');
        else
          console.log(err);
      }
    );
  }
  RedirectCategory() {
    this._router.navigateByUrl('/video-category');
  }
  userId = 0;
  ShowAccount = false;
  showTextArea = false;
  SendRequest() {

    let url = this.route1;
    let role = localStorage.getItem("role");
    localStorage.setItem("url", url);
    if (role == "Admin") {
      this._router.navigateByUrl('/user/login');
    }
    else if (role == "Vendor") {
      this._router.navigateByUrl('/user/login');
    }
    else if (role == "User") {
      this.ShowAccount = false;
      this.showTextArea = true;
      this.userId = Number(localStorage.getItem('userId'));
    }
    else {
      this._router.navigateByUrl('/user/login');
    }
  }
  similerfn(id) {
    this.id = id;
    this.GetVideo();
  }
  onSubmit() {
    debugger;
    this.spinner.show();
    this.userId = Number(localStorage.getItem('userId'));
    
    if (this.AddForm.value.countryId === "" || this.AddForm.value.countryId === undefined) {
      this.spinner.hide();
      alert("Please select country");      
      return false;
    }
    if (this.Question === false && this.QuotationOffer === false) {
      this.spinner.hide();
      alert("Please check Quotation Offer  or Question");
      return false;
    }
    if (this.AddForm.value.description === "" || this.AddForm.value.description === undefined) {
      this.spinner.hide();
      alert("Please enter decription");
      return false;
    }
    var body = {
      VideoTitle: this.service.VideoTitle,
      VideoId: this.id,
      VideoDescription: this.VideoDescription,
      UserId: this.userId,
      CreatedBy: this.userId,
      VendorId: this.VendorId,
      Email: this.AddForm.value.email,
      CountryId: this.AddForm.value.countryId,
      QuotationOffer: this.QuotationOffer,
      Quotation: this.Question,
      Description: this.AddForm.value.description,
    };


    this.service.VideoSendRequest(body).subscribe(
      (res: any) => {        
        setTimeout(() => {
          // alert('Notification is sent successfully')
          this.ShowAccount = true;
          this.showTextArea = false;
          this.spinner.hide();
        }, 1000);
      },
      err => {
        if (err.status == 400)

          //this.toastr.error('Incorrect username or password.', 'Authentication failed.');
          alert('error');
        else
          console.log(err);
      }
    );
  }
  QuotationOffer = false;
  Question = false;
  fnQuotationOffer(e) {
    
    if (e) {
      this.QuotationOffer = true;
      this.Question = false;
    }
    else {
      this.QuotationOffer = false;
    }
  }
  fnQuotation(e) {
    
    if (e) {
      this.QuotationOffer = false;
      this.Question = true;
    }
    else {
      this.Question = false;
    }
  }
  fnBack() {
    this.ShowAccount = true;
    this.showTextArea = false;
  }
}
