import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from '../../shared/common.service';
enum CheckBoxType { APPLY_FOR_JOB, MODIFY_A_JOB, NONE };
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-accountselectionreg',
  templateUrl: './accountselectionreg.component.html',
  styleUrls: ['./accountselectionreg.component.css']
})
export class AccountselectionregComponent implements OnInit {
  AddForm: FormGroup;
  constructor(private _router: Router, private _activatroute: ActivatedRoute, private service: CommonService, private formBuilder: FormBuilder) { }
  vendorId = 0;
  termscondtion = "";
  msg = ''; currentDate: Date;
  showmsg = false;
  ngOnInit() {
    this.AddForm = this.formBuilder.group({
      termscondtions: [false, Validators.required]
    });
    debugger
    let encriptvendorId = this._activatroute.snapshot.params['id'];
    localStorage.setItem('encriptvendorId', encriptvendorId);
    this.service.GetContent().subscribe(
      (res: any) => {

        this.termscondtion = res.termsandCondition;

      },
      err => {

        if (err.status == 400)
          //this.toastr.error('Incorrect username or password.', 'Authentication failed.');
          alert('error');
        else
          console.log(err);

        //this.reset();
      }
    );
    this.service.Decript(encriptvendorId).subscribe(
      (res: any) => {

        if (!res.status) {
          alert(res.message);
        }
        else {
          this.vendorId = res.result;
          let id = localStorage.setItem('VendorUserId', this.vendorId.toString());

          this.fncheckSubscription();
        }
      },
      err => {
        if (err.status == 400)

          //this.toastr.error('Incorrect username or password.', 'Authentication failed.');
          alert('error');
        else
          console.log(err);
      }
    );

  }

  fncheckSubscription() {
    this.service.checksubscription(this.vendorId).subscribe(
      (res: any) => {

        if (res.status) {
          alert(res.message);
          //  this._router.navigateByUrl('/home');
        }
        else {

        }
      },
      err => {
        if (err.status == 400)

          //this.toastr.error('Incorrect username or password.', 'Authentication failed.');
          alert('error');
        else
          console.log(err);
      }
    );
  }










  onSubmit() {
    debugger
    if (!this.AddForm.value.termscondtions) {
      alert("Please check Terms & Conditions");
      this.showmsg = true;
      return false;
    }

    var body = {
      VendorId: this.vendorId,
      ProductCount: 20,
      ProductDuration: 12,
      JournalCount: 99999,
      JournalDuration: 12,
      DocumentCount: 99999,
      DocumentDuration: 12,
      VideoCount: 99999,
      VideoDuration: 12,
      JobCount: 20,
      JobDurations: 1,
      AccountPrice: 0,
      SubscriptionId: 0,
      HumanResourceId: 0,
      HumanJobCount: 0,
      HumanJobDuration: 0,
      HumanVidioCount: 0,
      HummanVideoDuration: 0,
      HumanPrice: 0,
      TotalPrice: 0,
      ContingencyProductCount: 0,
      ContingencyId: 0
    };


    let planData = {
      accountSelection: {
        SmallEnterprise: 'Standard Setup',
        Max10prodcutupload: 20,
        JournalCreation: 99999,
        DocumentUploads: 99999,
        VideoUploads: 99999,
        JobPostings5: 20,
        ContactForm: '',
        EURPrice: 0,
        month: 12
      }


    }
    localStorage.setItem('planData', JSON.stringify(planData));
    this.service.AddVendrSubscription(body).subscribe(
      (res: any) => {
        this._router.navigateByUrl('/subscription-thank-you');
        var vendorId = {
          Id: this.vendorId,
        };
        this.service.SenddMailOfVendorPassword(vendorId).subscribe(
          (res1: any) => {


          }
        );
      }
    );
  }
  showtermsandCondtion: boolean;
  openModalTerms() {
    this.showtermsandCondtion = true;
  }

  closeModalTerms() {
    this.showtermsandCondtion = false;

  }
}
