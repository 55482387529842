import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Messagemodle } from './messagemodle';
import { AppSettings } from '../../../shared/AppSettings';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  constructor(private http: HttpClient) { }
  GetAll(vendorId,Role) : Observable<Messagemodle[]> {

    return this.http.get<any[]>(AppSettings.API_ENDPOINT + '/ApplyVendor/request-list?Vendorid=' + vendorId + "&Role=" + Role);
  }
  GetVideoById(id:number) : Observable<any> {
     
    return this.http.get<any>(AppSettings.API_ENDPOINT + '/VendorVideo/get-video-request?id='+ id);
  }
  UpdateVideoSTatus(formData) {
     
    return this.http.post(AppSettings.API_ENDPOINT + '/VendorVideo/update-status', formData);
  }
  GetProductById(id:number) : Observable<any> {
     
    return this.http.get<any>(AppSettings.API_ENDPOINT + '/Product/get-product-request?id='+ id);
  }
  UpdateProductSTatus(formData) {
     
    return this.http.post(AppSettings.API_ENDPOINT + '/Product/update-status', formData);
  }
  GetDocumentById(id:number) : Observable<any> {
     
    return this.http.get<any>(AppSettings.API_ENDPOINT + '/Document/get-document-request?id='+ id);
  }
  UpdateDocumentSTatus(formData) {
     
    return this.http.post(AppSettings.API_ENDPOINT + '/Document/update-status', formData);
  }
  GetJobById(id:number) : Observable<any> {
     
    return this.http.get<any>(AppSettings.API_ENDPOINT + '/ApplyVendor/get-job-request?id='+ id);
  }
  UpdateJobSTatus(formData) {
     
    return this.http.post(AppSettings.API_ENDPOINT + '/ApplyVendor/update-status', formData);
  }
  GetAllRequestMessage(vendorId,Role) : Observable<Messagemodle[]> {

    return this.http.get<any[]>(AppSettings.API_ENDPOINT + '/ApplyVendor/request-list-question?Vendorid=' + vendorId + "&Role=" + Role);
  }
}
