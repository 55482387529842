import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from './../../auth/auth/auth.service';
import { LocationStrategy } from '@angular/common';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  returnUrl: string;
  fb: FormBuilder;



  constructor(private service: UserService, private formBuilder: FormBuilder, private _router: Router
    , private location: LocationStrategy
  ) {


    history.pushState(null, null, window.location.href);
    this.location.onPopState(() => {
      history.pushState(null, null, window.location.href);
    });
  }


  message: string; invalidlogin = false;
  MsgShow = false;
  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required]

    });
    //this._router.navigate(['/auth']);
    console.log('login hit');
  }





  login(): void {


    this._router.navigate(['/admin/dashboard']);
  }


  fogotpassword(): void {
    this._router.navigate(['admin/forgotpassword']);

  }


  onSubmit() {
    var body = {

      Email: this.loginForm.value.email,
      Password: this.loginForm.value.password
    };

    this.service.login(body).subscribe(
      (res: any) => {
        debugger;

        if (res.status == true) {
          if (res.role == "Admin" || res.role == "Vendor") {
            localStorage.setItem('token', res.token);
            localStorage.setItem('Email', res.email);
            localStorage.setItem('userId', res.userId);
            localStorage.setItem('UserMappingId', res.userMappingId);
            localStorage.setItem("role", res.role);
            localStorage.setItem("companyName", res.compnayName);
            if (res.role == "Admin") {
              this._router.navigateByUrl('admin/dashboard');
            }
            else {
              this._router.navigateByUrl('vendor/dashboard');
            }
          }
          else {
            // alert("User Id or password is invalid");
            this.MsgShow = true;
            this.invalidlogin = true;
            this.message = "User Id or password is invalid";
            this.service.validUser = false;
          }
        }
        else {

          this.MsgShow = true;
          this.invalidlogin = true;
          this.message = res.message;
          this.service.validUser = false;
          if(res.message === "Your Subscriptions is expired")
          {
            alert("Your Subscriptions is expired. Please select account.");
            this._router.navigateByUrl(res.url);
          }
        }
      },
      err => {

        if (err.status == 400)

          //this.toastr.error('Incorrect username or password.', 'Authentication failed.');
          alert('error');
        else
          console.log(err);
      }
    );


  }



}
