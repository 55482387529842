import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from '../../shared/common.service';
import { UUID } from 'angular2-uuid';
import { HttpEventType } from '@angular/common/http';
import { IfStmt } from '@angular/compiler';

@Component({
  selector: 'app-vendor-registration',
  templateUrl: './vendor-registration.component.html',
  styleUrls: ['./vendor-registration.component.css']
})
export class VendorRegistrationComponent implements OnInit {

  AddForm: FormGroup;

  selectedFile: File = null;
  uploadResult: any; UserName: string;
  path: string;
  isImageUploaded: boolean = false;
  imageURL: string = "";
  id: number = 0; IsNews = false;
  adminUser = false; CountUser = false;
  AuthorList = [];
  FileName: string;
  isApprove = false;
  constructor(private formBuilder: FormBuilder,
    private _router: Router,
    private _activatroute: ActivatedRoute, private service: CommonService) { }

  ngOnInit() {
    const reg = '(\b(https?|ftp|file)://)?[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]';
    this.CountryList();
    this.AddForm = this.formBuilder.group({
      firstName: ['', [Validators.required, Validators.maxLength(20),

      Validators.pattern('^[a-zA-Z ]*$')]],

      lastName: ['', [Validators.required, Validators.maxLength(20),
      Validators.pattern('^[a-zA-Z ]*$')]],
      gender: ['', Validators.required],
      jobTitle: ['', Validators.required],
      email: ['', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]],
      repeateEmail: ['', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]],

      tele: ['', Validators.required],
      countryCode: ['', Validators.required],
      photoOfIdCard: ['', Validators.required],
      companyName: ['', Validators.required],
      area: ['', Validators.required],
      countryId: ['', Validators.required],
      stateId: ['', Validators.required],
      cityId: ['', Validators.required],
      street: ['', Validators.required],
      postalCode: ['', Validators.required],
      companyWebSite: ['', [Validators.required, Validators.pattern(reg)]],
      additionalInformation: ['', Validators.required],
      password: ['', [
        Validators.required,
        Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}')
      ]],

    }, {
      validator: MustMatch('email', 'repeateEmail')


    });
  }
  onSubmit() {
    debugger;
    if (this.AddForm.value.firstName === "") {
      alert("Please enter first name");
      return false;
    }
    if (this.AddForm.value.lastName === "") {
      alert("Please enter last name");
      return false;
    }
    if (this.AddForm.value.jobTitle === "") {
      alert("Please enter job title");
      return false;
    }
    if (this.AddForm.value.gender === "" || this.AddForm.value.gender === "Please Select") {
      alert("Please check gender");
      return false;
    }

    if (this.AddForm.value.email === "") {
      alert("Please enter email id");
      return false;
    }
    if (this.AddForm.value.repeateEmail === "") {
      alert("Please enter repeat email id");
      return false;
    }

    if (this.AddForm.value.tele === "") {
      alert("Please enter mobile no.");
      return false;
    }
    if (this.AddForm.value.companyName === "") {
      alert("Please enter company name");
      return false;
    }
    if (this.AddForm.value.area === "") {
      alert("Please enter area");
      return false;
    }


    if (this.AddForm.value.countryId === "" || this.AddForm.value.countryId === "Please Select") {
      alert("Please select country");
      return false;
    }
    if (this.AddForm.value.stateId === "" || this.AddForm.value.stateId === "Please Select") {
      alert("Please select state");
      return false;
    }
    if (this.AddForm.value.cityId === "" || this.AddForm.value.cityId === "Please Select") {
      alert("Please select city");
      return false;
    }


    if (this.AddForm.value.street === "") {
      alert("Please enter street");
      return false;
    }
    if (this.AddForm.value.postalCode === "") {
      alert("Please enter postal code");
      return false;
    }
    if (this.AddForm.value.companyWebSite === "") {
      alert("Please enter company web site");
      return false;
    }
    if (this.FileName === "" || this.FileName === undefined) {
      alert("Please upload Trade License card");
      return false;
    }
    if (!this.contactEmailMessage) {
      var body = {

        Id: this.id,
        Gender: this.AddForm.value.gender,
        FirstName: this.AddForm.value.firstName,
        LastName: this.AddForm.value.lastName,

        JobTitle: this.AddForm.value.jobTitle,
        Email: this.AddForm.value.email,

        RepeateEmail: this.AddForm.value.repeateEmail,
        Tele: this.AddForm.value.countryCode + '' + this.AddForm.value.tele,
        PhotoOfIdCard: this.FileName,
        CompanyName: this.AddForm.value.companyName,
        Area: this.AddForm.value.area,
        CountryId: this.AddForm.value.countryId,
        StateId: this.AddForm.value.stateId,
        CityId: this.AddForm.value.cityId,
        Street: this.AddForm.value.street,
        PostalCode: this.AddForm.value.postalCode,
        CompanyWebSite: this.AddForm.value.companyWebSite,
        AdditionalInformation: this.AddForm.value.additionalInformation,
        Password: this.AddForm.value.password,
        Role: 'Vendor',
        VendorLogo: ""
      };

      this.service.AddVendorAccount(body).subscribe(
        (res: any) => {
          debugger

          localStorage.setItem('VendorUserId', res.id);
          var vendorId = {
            Id: res.id,
          };
          this.service.IsPendingMail(vendorId).subscribe(
            (res1: any) => {



            },
            err => {
              debugger
              if (err.status == 400)

               
                alert('error');
              else
                console.log(err);
            }
          );
          // alert("Vendor is added successfully ");
         this._router.navigateByUrl('/Thankyou');
        },
        err => {
          if (err.status == 400)

            //this.toastr.error('Incorrect username or password.', 'Authentication failed.');
            alert('error');
          else
            console.log(err);
        }
      );
    }
    else
    {
      alert("Please enter other email id");
    }

  }
  onFileSelected(event) {
    debugger;
    this.selectedFile = <File>event.target.files[0];
    this.FileName = this.selectedFile.name;
    this.uploadFile();
  }
  uploadFile() {
    debugger;
    this.path = UUID.UUID() + '.jpg';
    const formData = new FormData();
    formData.append('image', this.selectedFile, this.selectedFile.name);
    formData.append('name', this.path);
    formData.append('folder', "IdCard");
    this.service.AddVideo(formData).subscribe(event => {
      if (event.type === HttpEventType.UploadProgress) {
        console.log('Upload Progress:' + Math.round(event.loaded / event.total * 100) + '%')

      }

      else if (event.type === HttpEventType.Response) {

        console.log(event.body);


      }
    });
  }
  countryList = [];
  stateList = [];
  cityList = [];
  CountryList() {
    this.service.GetAllCountry().subscribe(
      (res: any) => {
        debugger
        this.countryList = res[0].cList;
        // this.stateList = res[0].sList;
        // this.cityList = res[0].cityList;
        // if (this.id != 0) {
        //   let countryId = localStorage.getItem("CountryId");
        //   let stateId = localStorage.getItem("stateId");



        //   var stateList = this.stateList.filter(x => x.countryId == countryId);
        //   this.filterState = stateList;

        //   var cityList = this.cityList.filter(x => x.stateId == stateId);
        //   this.cityFilter = cityList;


        // }

      },
      err => {

        if (err.status == 400)
          //this.toastr.error('Incorrect username or password.', 'Authentication failed.');
          alert('error');
        else
          console.log(err);

        //this.reset();
      }
    );
  }
  filterState = [];
  cityFilter = [];
  filterCountry(e) {
    
    this.jobStateList(e);
  }
  jobStateList(id) {
    this.service.GetStateById(id).subscribe(
      (res: any) => {
        debugger;
        this.stateList = res.stateList;
      },
      err => {
        if (err.status == 400)

          //this.toastr.error('Incorrect username or password.', 'Authentication failed.');
          alert('error');
        else
          console.log(err);
      }
    );

  }
  jobCityList(id) {
    
    this.service.GetCityById(id).subscribe(
      (res: any) => {
        debugger;
        this.cityList = res.cityList;
      },
      err => {
        if (err.status == 400)

          //this.toastr.error('Incorrect username or password.', 'Authentication failed.');
          alert('error');
        else
          console.log(err);
      }
    );

  }
  changeState(Id) {
    debugger;
    var stateList = this.stateList.filter(x => x.countryId == Id);
    this.filterState = stateList;

  }
  changeCity(Id) {
    debugger;
    var cityList = this.cityList.filter(x => x.stateId == Id);
    this.cityFilter = cityList;

  }

  

  contactEmailMessage = false;
  CheckvendorEmail(email) {
    debugger;
    this.service.CheckVendorEmail(email).subscribe(
      (res: any) => {

        debugger;
        if (res.email === null) {
          this.contactEmailMessage = false;
        }
        else {
          this.contactEmailMessage = true;

        }

      },
      err => {

        if (err.status == 400)
          //this.toastr.error('Incorrect username or password.', 'Authentication failed.');
          alert('error');
        else
          console.log(err);

        //this.reset();
      }
    );
  }

}


export function MustMatch(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    if (matchingControl.errors && !matchingControl.errors.mustMatch) {
      // return if another validator has already found an error on the matchingControl
      return;
    }

    // set error on matchingControl if validation fails
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ mustMatch: true });
    } else {
      matchingControl.setErrors(null);
    }
  }
}

