import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../shared/common.service';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-impressum',
  templateUrl: './impressum.component.html',
  styleUrls: ['./impressum.component.css']
})
export class ImpressumComponent implements OnInit {

  constructor(private service: CommonService,private formBuilder: FormBuilder,private _router : Router) { }
impressum= "";
  ngOnInit() {
    this.service.GetContent().subscribe(
      (res: any) => {

        this.impressum = res.contactUs;

      },
      err => {

        if (err.status == 400)
          //this.toastr.error('Incorrect username or password.', 'Authentication failed.');
          alert('error');
        else
          console.log(err);

        //this.reset();
      }
    );
  }

}
