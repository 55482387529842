
import { Component, OnInit, Inject, Pipe } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from '../../shared/common.service';
import { Location, DOCUMENT } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
@Pipe({ name: 'safe' })
@Component({
  selector: 'app-document-viewer',
  templateUrl: './document-viewer.component.html',
  styleUrls: ['./document-viewer.component.css']
})
export class DocumentViewerComponent implements OnInit {
  AddForm: FormGroup;
  constructor(private route: ActivatedRoute, private _router: Router, private service: CommonService, @Inject(DOCUMENT) private document: Document, location: Location, private sanitizer: DomSanitizer
    , private formBuilder: FormBuilder, private spinner: NgxSpinnerService) {

    this.baseUrl = this.document.location.origin;
    if (location.path() != "") {
      this.route1 = location.path();
    } else {
      this.route1 = "Home";
    }
  }
  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  PdfName = "";
  docuemntId = 0;
  description = ""; title = ""; userid = ""; fileName;
  documentList = []; vendorId = 0; documentName = "";
  baseUrl: string; route1: string;
  userEmail = "";
  ngOnInit() {
    debugger;
    this.jobCategoryList();
    this.ShowAccount = true;
    this.docuemntId = +localStorage.getItem('documentId');
    this.vendorId = +localStorage.getItem('VendorId');
    this.PdfName = this.route.snapshot.params['pdfName'];
    this.userEmail = localStorage.getItem('Email');
    this.AddForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]],
      countryId: ['', Validators.required],
      requestDescription: ['', Validators.required],
      quotationOffer: ['', Validators.required],
      question: ['', Validators.required]

    });

    this.GetDocumentDetails();
  }
  companyName = "";
  vendorName = "";
  vendorLogo = "";
  GetDocumentDetails() {
    this.documentList = [];
    this.service.GetByDocumentId(this.docuemntId).subscribe(
      (res: any) => {
        debugger;
        this.description = res.description;
        this.title = res.documentTitle;
        this.documentList.push({ fileName: res.uploadDocument })
        this.fileName = this.sanitizer.bypassSecurityTrustResourceUrl('assets/vendorimage/document/' + res.uploadDocument);
        this.documentName = res.uploadDocument;
        //res.uploadDocument;
        this.vendorId = res.createdBy;
        this.companyName = res.companyName;
        this.vendorName = res.vendorName;
        this.vendorLogo = res.vendorLogo;

      },
      err => {

        if (err.status == 400)
          //this.toastr.error('Incorrect username or password.', 'Authentication failed.');
          alert('error');
        else
          console.log(err);

        //this.reset();
      }
    )
  }
  userId = 0;
  ShowAccount = false;
  showTextArea = false;
  SendRequest() {
    debugger;
    let url = this.route1;
    let role = localStorage.getItem("role");
    localStorage.setItem("url", url);
    if (role == "Admin") {
      this._router.navigateByUrl('/user/login');
    }
    else if (role == "Vendor") {
      this._router.navigateByUrl('/user/login');
    }
    else if (role == "User") {
      this.ShowAccount = false;
      this.showTextArea = true;
      this.userId = Number(localStorage.getItem('userId'));

    }
    else {
      this._router.navigateByUrl('/user/login');
    }


  }
  countryList = [];
  cityList = [];
  jobCategoryList() {
    this.service.GeJobCategoryList().subscribe(
      (res: any) => {



        this.countryList = res.countryList;
        this.cityList = res.cityList;
      },
      err => {
        if (err.status == 400)

          //this.toastr.error('Incorrect username or password.', 'Authentication failed.');
          alert('error');
        else
          console.log(err);
      }
    );

  }
  onSubmit() {
    this.spinner.show();
    if (this.AddForm.value.countryId === "" || this.AddForm.value.countryId === undefined) {
      alert("Please select country");
      this.spinner.hide();
      return false;
    }
    if (this.Question === false && this.QuotationOffer === false) {
      alert("Please check Quotation Offer  or Question");
      this.spinner.hide();
      return false;
    }
    if (this.AddForm.value.requestDescription === "" || this.AddForm.value.requestDescription === undefined) {
      alert("Please enter decription");
      this.spinner.hide();
      return false;
    }
    var body = {
      Title: this.title,
      DocumentId: this.docuemntId,
      Description: this.description,
      DocumentName: this.documentName,
      UserId: this.userId,
      CreatedBy: this.userId,
      VendorId: this.vendorId,
      Email: this.AddForm.value.email,
      CountryId: this.AddForm.value.countryId,
      QuotationOffer: this.QuotationOffer,
      Quotation: this.Question,
      RequestDescription: this.AddForm.value.requestDescription,
    };
    this.service.documentRquest(body).subscribe(
      (res: any) => {
        debugger;
      //  alert('Notification is sent successfully')
      
        setTimeout(() => {
          this.ShowAccount = true;
          this.showTextArea = false;
          this.spinner.hide();
        }, 1000);
      },
      err => {
        if (err.status == 400)

          //this.toastr.error('Incorrect username or password.', 'Authentication failed.');
          alert('error');
        else
          console.log(err);
      }
    );
  }
  QuotationOffer = false;
  Question = false;
  fnQuotationOffer(e) {
    debugger;
    if (e) {
      this.QuotationOffer = true;
      this.Question = false;
    }
    else {
      this.QuotationOffer = false;
    }
  }
  fnQuotation(e) {
    debugger;
    if (e) {
      this.QuotationOffer = false;
      this.Question = true;
    }
    else {
      this.Question = false;
    }
  }
  fnBack() {
    this.ShowAccount = true;
    this.showTextArea = false;
  }
}
