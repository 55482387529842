import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from './../../auth/auth/auth.service';

@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.css']
})
export class ForgotpasswordComponent implements OnInit {
  ForgotPasswordForm: FormGroup;

  constructor(private service: UserService,private formBuilder: FormBuilder,private _router:Router) { }

  ngOnInit() {
    this.ForgotPasswordForm = this.formBuilder.group({
      email: ['',[ Validators.required, Validators.email]],
    
  });
  }

  backToLogin() : void
  {
    this._router.navigate(['/admin/login']);
  
  }

// onSubmit()
  //{
   // if (this.ForgotPasswordForm.invalid) {
  //  //  return;
  //}
 // alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.ForgotPasswordForm.value))
//}


onSubmit()
{

//alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.loginForm.value))
debugger
var body = {
  
      Email: this.ForgotPasswordForm.value.email,
     
    };

this.service.forgotpassword(body).subscribe(
      (res: any) => {
        
        debugger
        if(res.status==true){
        
       // localStorage.setItem('token', res.token);
        this._router.navigateByUrl('/admin/login');
        alert("--Your password has been reset and sent on your email address--");
        }
        else
        {
        alert("User not exist ");
        }
       
      },
      err => {
        debugger
        if (err.status == 400)
      
          //this.toastr.error('Incorrect username or password.', 'Authentication failed.');
	alert('error');
        else
          console.log(err);
      }
    );


}


}
